const UPLOAD_FILE_SELECTORS = {
  container: '.js-upload-file-container',
  description: '.js-upload-file-description',
  fileInput: '.js-upload-file-input',
  fileInputPreview: '.js-upload-file-preview',
  fileInputUploaded: '.js-upload-file-uploaded',
  fileInputDelete: '.js-upload-file-delete',
  fileNamePlaceholder: '.js-upload-file-name'
}

const UPLOAD_STATE = {
  error: 'is-error',
  hide: 'is-hide',
  success: 'is-success'
}

const UPLOAD_FILE_STATE_TEXTS = {
  default: 'Максимум 1 файл до 5 МБ, в&nbsp;формате: <br>PDF / DOC / DOCX',
  success: 'Файл успешно загружен',
  error: 'Вес загруженного файла превышает допустимый размер'
}

const UPLOAD_ALLOW_TYPES = {
  pdf: 'application/pdf',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  doc: 'application/msword'
}

/**
 * Загрузка файла
 */
const initUploadFile = () => {
  const fileUploadContainer = document.querySelector(UPLOAD_FILE_SELECTORS.container);

  if (!fileUploadContainer) { return false; }

  const fileDescription = fileUploadContainer.querySelector(UPLOAD_FILE_SELECTORS.description);
  const fileUploadedPattern = fileUploadContainer.querySelector(UPLOAD_FILE_SELECTORS.fileInputUploaded);
  const filePreviewPattern = fileUploadContainer.querySelector(UPLOAD_FILE_SELECTORS.fileInputPreview);
  const fileInput = fileUploadContainer.querySelector(UPLOAD_FILE_SELECTORS.fileInput);
  const fileNamePlaceholder = fileUploadContainer.querySelector(UPLOAD_FILE_SELECTORS.fileNamePlaceholder);

  const fileDelete = fileUploadContainer.querySelector(UPLOAD_FILE_SELECTORS.fileInputDelete);

  fileInput.addEventListener('change', (e) => {
    const files = e.target.files;
    const fileName = files[0].name;
    const fileSize = Math.round((files[0].size / 1024));
    const fileType = files[0].type;

    // Если формат файла недопустимый, выводим в консоль ошибку
    if ((fileType !== UPLOAD_ALLOW_TYPES.pdf) && (fileType !== UPLOAD_ALLOW_TYPES.docx) && (fileType !== UPLOAD_ALLOW_TYPES.doc)) {
      console.error('Недопустимый формат файла');
    } else {
      if (fileSize > 5120) {
        fileUploadContainer.classList.add(UPLOAD_STATE.error)
        fileDescription.innerHTML = UPLOAD_FILE_STATE_TEXTS.error;
        fileUploadedPattern.classList.remove(UPLOAD_STATE.hide);
      } else {
        fileUploadContainer.classList.add(UPLOAD_STATE.success);
        fileDescription.innerHTML = UPLOAD_FILE_STATE_TEXTS.success;
        fileUploadedPattern.classList.remove(UPLOAD_STATE.hide);
      }
  
      fileDelete.classList.remove(UPLOAD_STATE.hide);
      filePreviewPattern.classList.add(UPLOAD_STATE.hide);
      fileNamePlaceholder.textContent = fileName;
    }
  });

  fileDelete.addEventListener('click', () => {
    fileUploadContainer.classList.remove(UPLOAD_STATE.error, UPLOAD_STATE.success);
    fileDelete.classList.add(UPLOAD_STATE.hide);
    filePreviewPattern.classList.remove(UPLOAD_STATE.hide);
    fileUploadedPattern.classList.add(UPLOAD_STATE.hide);
    fileDescription.innerHTML = UPLOAD_FILE_STATE_TEXTS.default;
    fileInput.value = '';
  });
}

export default initUploadFile;
