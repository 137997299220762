<template>
    <button
        class="v-button"
        :class="classes"
        :disabled="disabled"
        :type="type"
    >
        <div
            v-if="$slots.icon"
            class="v-button__icon"
            :class="{'v-button__icon_right-margin': $slots.default}"
        >
            <slot name="icon"></slot>
        </div>
        <span
            v-if="$slots.default"
            class="v-button__label"
        >
            <slot></slot>
        </span>
    </button>
</template>

<script>
export default {
    name: 'v-button',

    props: {
        view: {
            type    : String,
            default : 'simple',
            validate: (val) => {
                return ['filled', 'outlined', 'simple'].includes(val);
            }
        },

        color: {
            type    : String,
            default : 'primary',
            validate: (val) => {
                return ['primary', 'secondary', 'error', 'ghost', 'plain'].includes(val);
            }
        },

        size: {
            type    : String,
            default : 'medium',
            validate: (val) => {
                return ['medium', 'small', ''].includes(val);
            }
        },

        type: {
            type    : String,
            default : 'button'
        },

        disabled: {
            type   : Boolean,
            default: false
        },

        isFullWidth: {
            type   : Boolean,
            default: false
        },

        isWithIcon: {
            type   : Boolean,
            default: false
        }
    },

    computed: {
        classes() {
            const classes = [`v-button_${this.size}`];

            if (this.view !== 'simple') {
                classes.push(`v-button_${this.view}`);
            }

            if (this.view === 'filled') {
                classes.push(`v-button_${this.color}`);
            }

            if (!this.$slots.icon) {
                classes.push('v-button_text');
            }

            if (!this.$slots.default) {
                classes.push('v-button_icon');
            }

            if (this.isFullWidth) {
                classes.push('v-button_is-full-width');
            }

            if (this.isWithIcon) {
                classes.push('v-button_is-with-icon');
            }

            return classes;
        }
    }
};
</script>
