import axios from 'axios';
import Observer from "common/scripts/observer";
const commonObserver = new Observer();

/**
 * @typedef {HTMLElement} ButtonElement
 * @typedef {Event} Event
 */

/**
 * Помечает кнопку как ожидающую действия.
 * @param {ButtonElement} btn - Кнопка, которую нужно пометить как ожидающую.
 */
function setButtonWaitStart(btn) {
    btn.setAttribute("data-disabled", "true");
    btn.style.opacity = 0.3;
}

/**
 * Снимает пометку ожидания с кнопки.
 * @param {ButtonElement} btn - Кнопка, с которой нужно снять пометку ожидания.
 */
function setButtonWaitFinish(btn) {
    btn.style.opacity = "";
    btn.removeAttribute("data-disabled");
}

/**
 * Проверяет, ожидает ли кнопка действия.
 * @param {ButtonElement} btn - Кнопка, которую нужно проверить.
 * @return {boolean} Возвращает true, если кнопка ожидает действия, иначе false.
 */
function isButtonWaiting(btn) {
    return btn.getAttribute("data-disabled") === "true";
}

/**
 * Обновляет счетчик избранных товаров.
 * @param {number} count - Новое количество избранных товаров.
 */
export function updateFavoriteCount(count) {
    const favoriteCountElements = Array.from(document.querySelectorAll('.j-favorite-count'));

    favoriteCountElements.forEach((element) => {
        element.textContent = count;
        const parsedCount = parseInt(count, 10);

        if (parsedCount > 0) {
            element.classList.add('is-active');
        } else {
            element.classList.remove('is-active');
        }
    });
}

/**
 * Выполняет HTTP-запрос для добавления/удаления товара из избранного.
 * @param {string} productID - Идентификатор продукта.
 * @param {string} action - Действие: "add" или "delete".
 * @return {Promise<Object>} Возвращает объект ответа от сервера.
 */
async function executeFavoriteAction(productID, action) {
    try {
        const response = await axios.post("/ajax/favoritesAction.php", { product: productID, action: action });
        return response.data;
    } catch (err) {
        console.error(`Ошибка при ${action === 'add' ? 'добавлении' : 'удалении'} избранного:`, err);
        commonObserver.publish('showAlert', 'Произошла ошибка, попробуйте чуть позже');
        throw err;
    }
}

/**
 * Добавляет товар в избранное.
 * @param {Event} event - Объект события.
 * @param {ButtonElement} addToFavoriteElement - Элемент, который нужно добавить в избранное.
 */
async function addToFavorites(event, addToFavoriteElement) {
    event.preventDefault();

    if (isButtonWaiting(addToFavoriteElement)) {
        return;
    }
    setButtonWaitStart(addToFavoriteElement);
    const productID = addToFavoriteElement.getAttribute("data-product");

    try {
        const response = await executeFavoriteAction(productID, "add");

        if (1 === response.state) {
            updateFavoriteCount(response.count);
            commonObserver.publish('updateFavorite', response);
            addToFavoriteElement.classList.replace('j-add-to-favorite', 'j-remove-from-favorite');
            addToFavoriteElement.classList.add('is-active');
        }
    } finally {
        setButtonWaitFinish(addToFavoriteElement);
    }
}

/**
 * Удаляет товар из избранного.
 * @param {Event} event - Объект события.
 * @param {ButtonElement} removeFromFavoriteElement - Элемент, который нужно удалить из избранного.
 */
async function removeFromFavorites(event, removeFromFavoriteElement) {
    event.preventDefault();

    if (isButtonWaiting(removeFromFavoriteElement)) {
        return;
    }

    setButtonWaitStart(removeFromFavoriteElement);
    const productID = removeFromFavoriteElement.getAttribute("data-product");

    try {
        const response = await executeFavoriteAction(productID, "delete");

        if (1 === response.state) {
            updateFavoriteCount(response.count);
            commonObserver.publish('updateFavorite', response, true);
            removeFromFavoriteElement.classList.replace('j-remove-from-favorite', 'j-add-to-favorite');
            removeFromFavoriteElement.classList.remove('is-active');
            document.querySelector(`.mini-basket__item[data-id="${productID}"]`).remove();

            // костыль для страницы "избранное"
            if (document.location.pathname === '/favorites/' || document.location.pathname === '/personal/favorites/') {
                // удаляем из избранного
                document.querySelector(`.catalog-card__favorite[data-product="${productID}"]`).closest('.js-catalog-card').remove()
                // если это был последний элемент - перезагружаем страницу
                if (!response.count) { location.reload() }
            }
        }
    } finally {
        setButtonWaitFinish(removeFromFavoriteElement);
    }
}

/**
 * Инициализирует функционал добавления и удаления товаров из избранного.
 */
export function initFavorites() {
    document.addEventListener("click", (event) => {
        const addToFavoriteElement = event.target.closest(".j-add-to-favorite");
        const removeFromFavoriteElement = event.target.closest(".j-remove-from-favorite");

        if (addToFavoriteElement) {
            addToFavorites(event, addToFavoriteElement);
        } else if (removeFromFavoriteElement) {
            removeFromFavorites(event, removeFromFavoriteElement);
        }
    });

    const addToFavoriteElements = Array.from(document.querySelectorAll(".j-add-to-favorite"));

    if (addToFavoriteElements.length > 0) {
        try {
            axios.post("/ajax/favoritesAction.php", {action: 'list'})
                .then((response) => {
                    const items = response.data.items;

                    if (!items || !Array.isArray(items)) return

                    const productIds = items.map(item => item.id);

                    addToFavoriteElements.forEach((element) => {
                        const productId = element.dataset.product;

                        if (productIds.includes(productId)) {
                            element.classList.replace('j-add-to-favorite', 'j-remove-from-favorite');
                            element.classList.add('is-active');
                        }
                    });

                    updateFavoriteCount(response.data.count);
                    commonObserver.publish('updateFavorite', response.data);
                });
        } catch (err) {
            console.error('Ошибка при получении избранного:', err);
        }

    }
}
