/**
 * Выбор геолокации
 * @param {Object} options - объект настроек
 * @param {string} options.element - список переключателей
 * @param {string} [options.cityPlsClass] - класс обёртки текущего значения геолокации
 * @param {string} [options.cityOptionClass] - класс элемента-переключателя
 * @param {string} [options.param] - название параметра, хранящего значение текущей локации
 */
class citySelecter {
    constructor(options) {
        this.init(options);
    }

    /**
     * Инициализация компонента
     * @param {Object} options - объект настроек
     */
    init(options) {
        const {
            element,
            cityPlsClass,
            cityOptionClass,
            param
        } = options;

        if(!element) {
            console.error('Необходим целевой элемент для переключения геолокации');
            return;
        }

        this.param = param;
        this.citySelect = element;
        this.options = Array.from(this.citySelect.querySelectorAll(cityOptionClass));
        this.placeholder = this.citySelect.querySelector(cityPlsClass);

        this.bindEvents();
        this.setCurrCity();
    }

    /**
     * Получение значения текущей геолокации
     */
    getCookie() {
        const cookies = document.cookie;
        const cookiesArr = cookies.split(';');
        const param = cookiesArr.find(item => item.trim().includes(this.param));

        return param ? Number(param.split('=')[1]) : Boolean(param);
    }

    /**
     * Запись в cookie значения текущей геолокации
     * @param {Number} value - значение геолокации
     */
    setCookie(value) {
        document.cookie = `${encodeURIComponent(this.param)}=${value}`;
        location.reload();
    }

    /**
     * Добавление слушателей событий для переключателей
     */
    bindEvents() {
        this.options.forEach(option => {
            option.addEventListener('click', this.selectCity.bind(this));
        });
    }

    /**
     * Переключение геолокации
     * @param {Event} event - событие
     */
    selectCity(event) {
        event.preventDefault();
        const { target } = event;
        const location = Number(target.dataset.location);

        this.setCookie(location);
    }

    /**
     * Установка значения геолокации
     */
    setCurrCity() {
        if(!this.getCookie()) return;

        const currCityName = this.options.find(item => Number(item.dataset.location) === this.getCookie());
        this.placeholder.textContent = currCityName.textContent;
    }
}

export default citySelecter;
