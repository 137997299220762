import axios from 'axios';
import AlertHandler from '../../components/alert/alert';
import handleData from './test-data';
import { REGIONS_DATA, getRegionId, getZoneId } from '../../common/scripts/modules/delivery-zone-handler';
import { handleAuth, getOrderParams } from '../../common/scripts/modules/auth-handler';

const alertHandler = new AlertHandler();
const ERRORS = {
  profileError: 'Произошла ошибка при получении данных о пользователе. Повторите попытку позже.',
  ordersError: 'Произошла ошибка при получении списка заказов. Повторите попытку позже.',
  inventoryError: 'Произошла ошибка при получении тар и оборудования. Повторите попытку позже.',
  contactsError: 'Произошла ошибка при получении получателей. Повторите попытку позже.',
  addressesError: 'Произошла ошибка при получении адресов. Повторите попытку позже.',
  addressDeleteError: 'Произошла ошибка при удалении адреса. Повторите попытку позже.',
  addressAddError: 'Произошла ошибка при добавлении адреса. Повторите попытку позже.',
  recipientDeleteError: 'Произошла ошибка при удалении получателя. Повторите попытку позже.',
  recipientAddError: 'Произошла ошибка при добавлении получателя. Повторите попытку позже.',
  notificationsError: 'Произошла ошибка при получении данных об активных уведомлениях. Повторите попытку позже.',
  mainAddressError: 'Не удалось установить основной адрес'
};
const SUCCESS = {
  addressAddSuccess: 'Адрес успешно добавлен.',
  addressDeleteSuccess: 'Адрес успешно удален.',
  addressUpdateSuccess: 'Список адресов успешно обновлён',
  recipientAddSuccess: 'Получатель успешно добавлен.',
  recipientDeleteSuccess: 'Получатель успешно удален.',
  recipientUpdateSuccess: 'Список получателей успешно обновлён',
  mainAddressSuccess: 'Основной адрес успешной изменён'
};

export const account = {
  namespaced: true,
  state() {
    return {
      profile: {},
      isLoggedIn: false,
      isPhoneExist: false,
      currAddressData: null,
      currRecipientData: null,
      userAddresses: [],
      userRecipients: [],
      userComment: '',
      successOrderData: [],
      personalOrders: [],
      personalInventory: [],
      notifications: {},
      phoneStatus: '',
      userUpdateStatus: null,
      isLoading: false,
      isLoaded: false
    }
  },
  getters: {
    profile: state => state.profile,
    successOrderData: state => state.successOrderData,
    personalOrders: state => state.personalOrders,
    notifications: state => state.notifications,
    phoneStatus: state => state.phoneStatus,
    userUpdateStatus: state => state.userUpdateStatus,
    isLoading: state => state.isLoading,
    isLoaded: state => state.isLoaded
  },
  actions: {
    async getLoggedIn({ commit }, payload) {
      try {
        const { isLoggedIn, isPhoneExist } = await handleAuth(payload.id);

        if(isLoggedIn) {
          commit('setLoggedIn', isLoggedIn);
          
          if(isPhoneExist) commit('setPhoneExist', isPhoneExist);
        }
      } catch({ message }) {
        console.error(message);
        alertHandler.showAlert(message);
      }
    },
    getUserProfile({ commit }) {
      const profileData = JSON.parse(document.querySelector('#account-personal').dataset?.accountPersonal);

      if (!profileData) {
        alertHandler.showAlert(ERRORS.profileError);
      } else {
        commit(
          'setProfile',
          Object.keys(profileData).reduce(
            (acc, key) => ({ ...acc, [key]: profileData[key] === null ? '' : profileData[key] }), {}
          )
        );
      }
    },
    getPersonalOrders({ commit }) {
      let totalPages = null;
      const accOrdersItem = document.querySelector('#account-orders');
      const ordersData = JSON.parse(accOrdersItem?.dataset?.items);

      if (!ordersData) {
        if (ordersData !== null) {
          alertHandler.showAlert(ERRORS.ordersError);
        }
      } else {
        totalPages = JSON.parse(accOrdersItem?.dataset?.totalPages);
        commit('setPersonalOrders', ordersData);
        commit('setIsLoaded', Boolean(totalPages) ? Number(totalPages) < 2 : true);
      }
    },
    getSuccessOrderData({ commit }) {
      const orderData = JSON.parse(document.querySelector('#order-completed').dataset?.item);

      if (!orderData) {
        if (orderData !== null) {
          alertHandler.showAlert(ERRORS.ordersError);
        }
      } else {
        commit('setSuccessOrderData', orderData);
      }
    },
    getPersonalInventory({ commit }) {
      const inventoryData = JSON.parse(document.querySelector('#account-equipment').dataset?.items);
      const result = Object.keys(inventoryData).map(key => ({ name: key, ...inventoryData[key] }));
      const formattedResult = result.map((elem) => ({
        name: elem.name,
        quantity: elem.QUANTITY,
        addresses: Object.values(elem.ADDRESSES).map((el) => ({ quantity: el.QUANTITY, address: el.ADDRESS }))
      }));

      if (!inventoryData) {
        alertHandler.showAlert(ERRORS.inventoryError);
      } else {
        commit('setPersonalInventory', formattedResult);
      }
    },

    getPersonalNotifications({ commit }) {
      const notificationsData = JSON.parse(document.querySelector('#account-notifications').dataset?.items);

      if (!notificationsData) {
        alertHandler.showAlert(ERRORS.profileError);
      } else {
        commit('setNotifications', notificationsData);
      }
    },

    async updateProfileNotifications({ commit }, payload) {
      const payloadValue = Object.values(payload)[0];
      const payloadKey = Object.keys(payload)[0];
      const fd = new FormData();

      if (typeof payloadValue === 'object') {
        fd.append(payloadKey, payloadValue?.ID);
      } else {
        fd.append(payloadKey, payloadValue === true ? '1' : '0');
      }

      try {
        await axios.post('/ajax/notification.php', fd).then(
          (response) => {
            const { status } = response.data;

            if (status !== 'ok') {
              alertHandler.showAlert('Произошла ошибка при обновлении уведомлений.');
            }
          }
        )
      } catch (error) {
        console.error(error);
        alertHandler.showAlert('Произошла ошибка при обновлении уведомлений.');
      }
    },
    async updateProfilePersonal({ commit }, payload) {
      commit('setIsLoading', true);

      const fd = new FormData();

      fd.append('sessid', document.querySelector('.footer').dataset.sessid);
      fd.append('lang', 's1');
      fd.append('ID', payload.id);
      fd.append('save', 'Сохранить')
      fd.append('NAME', payload.name === '' ? '' : payload.name);
      fd.append('LAST_NAME', payload.lastName === '' ? '' : payload.lastName);
      fd.append('SECOND_NAME', payload.secondName === '' ? '' : payload.secondName);
      fd.append('PERSONAL_BIRTHDAY', payload.birthday === null ? '' : payload.birthday);
      fd.append('PERSONAL_PHONE', payload.phone === '' ? '' : payload.phone);
      fd.append('EMAIL', payload.email === '' ? '' : payload.email);
      fd.append('LOGIN', payload.email === '' ? '' : payload.email);
      fd.append('UF_SUBSCRIBE_AGREE', payload.notifications === true ? '1' : '0');

      try {
        await axios.post('/personal/profile/', fd).then(
          async (response) => {
            if (response.data?.status === 'error') {
              alertHandler.showAlert(response.data?.message.replaceAll('<br />', ''));
            } else {
              alertHandler.showAlert(response.data?.message.replaceAll('<br />', ''));
            }

            commit('setUserUpdateStatus', response.data?.status);
            commit('setIsLoading', false);
          }
        )
      } catch (error) {
        console.error(error);
        alertHandler.showAlert('Произошла ошибка при изменении личных данных.')
      }
    },
    async updateProfilePersonalPassword({ commit }, payload) {
      const fd = new FormData();

      fd.append('backurl', '/personal/profile/');
      fd.append('USER_OLD_PASSWORD', payload.oldPassword);
      fd.append('USER_PASSWORD', payload.password);
      fd.append('USER_CONFIRM_PASSWORD', payload.confirmPassword);

      try {
        await axios.post('/ajax/userChangePassword.php', fd).then(
          (response) => {
            const { state } = response.data;

            if (state === 0) {
              alertHandler.showAlert(response.data.MESSAGE.replaceAll('<br>', ''));
            } else {
              alertHandler.showAlert('Пароль успешно изменен.');

              setTimeout(() => {
                window.location.reload();
              }, 1000);
            }
          }
        )
      } catch (error) {
        console.error(error);
        alertHandler.showAlert('Неверно введен старый пароль.');
      }
    },
    async updateProfilePersonalPhone({ commit }, payload) {
      let phoneStatus = null;

      try {
        await axios.get(`/crm/lk/user/phone/?phone=${payload.phone}`).then(
          (response) => {
            if (response.data?.data?.status === 'error') {
              alertHandler.showAlert(response.data?.data?.message);
              phoneStatus = response.data?.data?.status;
            }
          }
        )
      } catch (error) {
        console.error(error);
        alertHandler.showAlert('Произошла ошибка при проверке телефона. Попробуйте позже.')
      }

      return phoneStatus;
    },
    async deletePersonalProfile({ commit }, payload) {
      const fd = new FormData();

      payload.reasons.forEach((item) => {
        fd.append('reasons[]', item.title);
      });

      try {
        await axios.delete('/crm/user/account/', { data: fd }).then(
          (response) => {
            const { status } = response.data.data;

            if (status !== 'success') {
              alertHandler.showAlert('Произошла ошибка при удалении профиля.');
            }
          }
        )
      } catch (error) {
        console.log(error);
        alertHandler.showAlert('Произошла ошибка при удалении профиля.');
      }
    },
    async declineOrder({ commit, dispatch }, payload) {
      let declineStatus = null;
      const fd = new FormData();
      fd.append('action', 'cancel');
      fd.append('sessid', payload.sessid);
      fd.append('id', payload.id);

      try {
        await axios.post('/ajax/orders.php', fd).then(
          async (response) => {
            if (response.data.status === 'success') {
              await dispatch('getServerOrderList', payload);
              alertHandler.showAlert('Заказ успешно отменен');
              declineStatus = response.data.status;
            }
          }
        );
      } catch (error) {
        console.error(error);
        commonObserver.publish('showAlert', 'Произошла ошибка при отмене заказа.');
      }

      return declineStatus;
    },
    async loadMoreOrders({ commit, state }, payload) {
      const { sessid, currPage } = payload;
      const { personalOrders } = state;
      let nextPage = Number(currPage);

      commit('setIsLoading', true);
      try {
        //await handleData()
        await axios.get(`/ajax/ordersList.php?page=${currPage}`, {
          action: 'list',
          sessid
        }).then(({ data }) => {
          const { data: ordersData, success } = data;

          if(success) {
            nextPage = Number(currPage) + 1;
            const { page, totalPages, list } = ordersData.paginatedList;
            const isTotalPages = page === totalPages;

            commit('setIsLoading', isTotalPages);
            commit('setIsLoaded', isTotalPages);
            commit('setPersonalOrders', [...personalOrders, ...list]);
          } else {
            alertHandler.showAlert('Не удалось обновить список заказов');
          }
        });
      } catch (error) {
        console.error(error);
        alertHandler.showAlert('Произошла ошибка при получении заказов. Попробуйте позже');
      }

      return nextPage;
    },
    async getServerOrderList({ commit }, payload) {
      const fd = new FormData();
      fd.append('action', 'list');
      fd.append('sessid', payload.sessid);

      try {
        await axios.post('/ajax/orders.php', fd).then(
          (response) => {
            const data = JSON.parse(Object.values(response.data)[0]);

            commit('setPersonalOrders', data);
          }
        )
      } catch (error) {
        console.error(error);
        alertHandler.showAlert('Произошла ошибка при получении заказов. Попробуйте позже');
      }
    },

    /**
     * Выборка получателей авторизованного пользователя 
     */
    async fetchUserRecipients({ commit }) {
      commit('setIsLoading', true);

      try {
        const { data } = await axios({
          method: 'get',
          url: '/ajax/saleReceivers.php',
          data: {
            action: 'list'
          },
        });

        if (data.state === 1) {
          commit('setUserRecipients', [...data.items].map(({ ID, NAME, PHONE, USER_ID }) => ({ id: ID, name: NAME, phone: PHONE, user: USER_ID })));
        } else {
          alertHandler.showAlert(ERRORS.contactsError);
        }
      } catch (error) {
        console.error(error);
        alertHandler.showAlert(ERRORS.contactsError);
      } finally {
        commit('setIsLoading', false);
      }
    },

    /**
     * Обработка данных получателя авторизованного пользователя 
     */
    async handleUserRecipient({ commit, state }, payload) {
      const { action } = payload;
      const { userRecipients } = state;
      let response = {
        state: 0,
        ...payload
      };

      try {
        const { data } = await axios({
          method: 'post',
          url: '/ajax/saleReceivers.php',
          data: {...payload},
        });

        if (data.state === 1) {
          response = {
            ...response,
            ...data
          };

          switch(action) {
            case 'add':
              commit('setUserRecipients', [...userRecipients, {id: response.ID, name: response.NAME, phone: response.PHONE, user: response.USER_ID}]);
              alertHandler.showAlert(SUCCESS.recipientAddSuccess);
              break;
            case 'update':
              commit('setUserRecipients', [...userRecipients].map((item) => item.id === response.ID ? ({...item, name: response.NAME, phone: response.PHONE, user: response.USER_ID}) : item));
              alertHandler.showAlert(SUCCESS.recipientUpdateSuccess);
              break;
            case 'delete':
              commit('setUserRecipients', [...userRecipients].filter(({ id }) => id !== payload.id));
              alertHandler.showAlert(SUCCESS.recipientDeleteSuccess);
              break;
          }
        } else {
          alertHandler.showAlert(data.message);
        }
      } catch (error) {
        console.error(error);
        alertHandler.showAlert(ERRORS.recipientAddError);
      }

      return response;
    },

    /**
     * Установка данных текущего получателя
     */
    async updateCurrRecipient({ commit, state }, payload) {
      const { isInit, data } = payload;
      const { isLoggedIn, currRecipientData, userRecipients, profile } = state;
      const userRecipientData = data !== undefined ? data : null || currRecipientData;
      const customRecipientId = () => {
        const recipientId = userRecipients.length ? Math.max(...userRecipients.map(({id}) => Number(id))) + 1 : 1;

        return recipientId.toString();
      };

      if(!isInit) {
        commit('setCurrRecipientData', userRecipientData);
        return;
      }

      try {
        const { isSucceed, data: orderData } = await getOrderParams();

        if(isSucceed) {
          commit(
            'setCurrRecipientData',
            isLoggedIn
              ? [...userRecipients].find(({ name, phone }) => name === orderData.name && phone === orderData.phone) || {
                id: customRecipientId(),
                name: orderData.name,
                phone: orderData.phone,
                user: profile?.ID
              }
              : null
          );
        } else {
          commit('setCurrRecipientData', userRecipientData);
        }
      } catch(error) {
        console.log(error);
      }
    },

    /**
     * Обновление списка получателей НЕАВТОРИЗОВАННОГО пользователя 
     */
    updateUserRecipients({ commit }, payload) {
      commit('setUserRecipients', payload.arr);
      alertHandler.showAlert(SUCCESS.recipientUpdateSuccess);
    },

    /**
     * Выборка адресов авторизованного пользователя 
     */
    async fetchUserAddresses({ commit, state }) {
      const { profile } = state;

      commit('setIsLoading', true);

      try {
        const { data } = await axios({
          method: 'get',
          url: '/ajax/saleAddresses.php?action=list&ext=1',
        });

        if (data.state === 1) {
          commit(
            'setUserAddresses',
            [...data.items].map(
              ({
                ID,
                CODE,
                NAME,
                MAIN,
                ZONE_ID,
                PROPS
              }) => 
                ({
                  id: ID,
                  code: CODE,
                  isMain: MAIN === 'Y',
                  address: NAME,
                  caption: PROPS.ADDRESS,
                  zoneId: ZONE_ID || null,
                  ...(profile?.ID && { user: profile?.ID })
                })
            )
          );
        } else {
          alertHandler.showAlert(ERRORS.addressesError);
        }
      } catch (error) {
        console.error(error);
        alertHandler.showAlert(ERRORS.addressesError);
      } finally {
        commit('setIsLoading', false);
      }
    },

    /**
     * Сохранение данных адреса доставки авторизованного пользователя 
     */
    async createUserAddress({ commit, state }, payload) {
      const { profile, userAddresses } = state;
      const formData = new FormData();
      const {
        address: ADDRESS,
        home,
        apartment: APARTMENT,
        entrance: ENTRANCE,
        floor: FLOOR,
        iCode: INTERCOME_CODE,
        elevator: ELEVATOR,
      } = payload;
      const data = {
        ...(ADDRESS && { REGION: getRegionId(ADDRESS), ADDRESS }),
        ...(APARTMENT && { APARTMENT }),
        ...(ENTRANCE && { ENTRANCE }),
        ...(FLOOR && { FLOOR }),
        ...(INTERCOME_CODE && { INTERCOME_CODE }),
        ...(ELEVATOR && { ELEVATOR })
      };

      commit('setIsLoading', true);

      Object.values(data).forEach(
        (item, index) => {
          formData.append(Object.keys(data)[index], item)
        }
      );
      formData.append('private-home', home);
    
      try {
        const { zoneId, lat, lon } = await getZoneId(ADDRESS);

        if(!zoneId) {
          return;
        }

        formData.append('lat', lat);
        formData.append('lon', lon);

        const { data: addressData } = await axios.post('/ajax/addressAdd.php', formData);

        if(addressData.status === 'ok' && addressData.data) {
          const nameArr = addressData.data.NAME.split(', ');

          commit(
            'setUserAddresses',
            [
              ...userAddresses,
              {
                id: addressData.data.ID,
                code: addressData.data.CODE,
                isMain: addressData.data.MAIN === 'Y',
                address: Object.values(REGIONS_DATA).includes(nameArr[0])
                  ? addressData.data.NAME
                  : nameArr.filter((_, index) => index !== 0).join(', '),
                caption: ADDRESS,
                zoneId: zoneId || addressData.data.ZONE_ID,
                ...( profile?.ID && { user: profile?.ID } )
              }
            ]
          );
          alertHandler.showAlert(SUCCESS.addressAddSuccess);
        } else {
          alertHandler.showAlert(ERRORS.addressAddError);
        }
      } catch (error) {
        console.error(error);
        alertHandler.showAlert(ERRORS.addressAddError);
      } finally {
        commit('setIsLoading', false);
      }
    },

    /**
     * Обновление списка адресов доставки НЕАВТОРИЗОВАННОГО пользователя 
     */
    async updateUserAddresses({ commit, state }, payload) {
      const { arr, data } = payload;
      const { userAddresses } = state;

      commit('setIsLoading', true);
    
      try {
        const { zoneId } = await getZoneId(data.address);

        if(!zoneId) {
          return;
        }

        if(arr) {
          commit('setUserAddresses', arr);
        } else {
          commit(
            'setUserAddresses',
            [
              ...userAddresses,
              {
                id: userAddresses.length ? Math.max(...userAddresses.map(({id}) => Number(id))) + 1 : 1,
                code: null,
                isMain: false,
                address: data.address,
                caption: data.address,
                home: Boolean(data.home),
                apartment: data.apartment,
                entrance: data.entrance,
                floor: data.floor,
                iCode: data.iCode,
                elevator: Boolean(data.elevator),
                zoneId
              }
            ]
          )
        }

        alertHandler.showAlert(SUCCESS.addressUpdateSuccess);
      } catch (error) {
        console.error(error);
        alertHandler.showAlert(ERRORS.addressAddError);
      } finally {
        commit('setIsLoading', false);
      }
    },

    /**
     * Удаление данных адреса доставки авторизованного пользователя 
     */
    async deleteUserAddress({ commit, state }, payload) {
      const { userAddresses } = state;

      commit('setIsLoading', true);

      try {
        const { data } = await axios({
          method: 'post',
          url: `/ajax/saleAddresses.php?action=delete&id=${payload.id}`,
        });

        if(data.state === 1) {
          commit('setUserAddresses', [...userAddresses].filter(({ id }) => id !== data.id.toString()));
          alertHandler.showAlert(SUCCESS.addressDeleteSuccess);
        } else {
          alertHandler.showAlert(ERRORS.addressDeleteError);
        }
      } catch (error) {
        console.error(error);
        alertHandler.showAlert(ERRORS.addressDeleteError);
      } finally {
        commit('setIsLoading', false);
      };
    },

    /**
     * Установка основного адреса доставки авторизованного пользователя
     */
    async setMainAddress({ commit, state }, payload) {
      const { id } = payload;
      const { userAddresses } = state;
      const currMainAddress = [...userAddresses].find(({ isMain }) => isMain);

      commit('setIsLoading', true);

      if(currMainAddress && currMainAddress.id === id) {
        commit('setIsLoading', false);
        return;
      }

      try {
        const { data } = await axios.post(`/ajax/addressSetMain.php?id=${id}`);
        
        if(data.status === 'ok') {
          const arr = currMainAddress
            ? [...userAddresses].map((item) => item.id === currMainAddress.id ? ({ ...item, isMain: false }) : item)
            : [...userAddresses];

          commit('setUserAddresses', arr.map((item) => item.id === id ? ({ ...item, isMain: true }) : item));
          alertHandler.showAlert(SUCCESS.mainAddressSuccess);
        } else {
          alertHandler.showAlert(ERRORS.mainAddressError);
        }
      } catch(error) {
        console.error(error);
        alertHandler.showAlert(ERRORS.mainAddressError);
      } finally {
        commit('setIsLoading', false);
      }
    },

    /**
     * Установка текущего адреса доставки (по умолчанию - отмеченный основным)
     */
    async updateCurrAddress({ commit, state }, payload) {
      const { isInit, data } = payload;
      const { isLoggedIn, currAddressData, userAddresses, profile } = state;
      const userAddressData = data !== undefined ? data : [...userAddresses].find(({ isMain }) => isMain) || currAddressData;
      const customAddressId = () => {
        const addressId = userAddresses.length ? Math.max(...userAddresses.map(({id}) => Number(id))) + 1 : 1;

        return addressId.toString();
      };

      if(!isInit) {
        commit('setCurrAddressData', userAddressData || null);
        return;
      }

      try {
        const { isSucceed, data: orderData } = await getOrderParams();

        console.log({ isSucceed, orderData });

        if(isSucceed) {
          commit(
            'setCurrAddressData',
            isLoggedIn
              ? [...userAddresses].find(({ code }) => code === orderData.addressCode) || {
                  id: customAddressId(),
                  code: null,
                  isMain: false,
                  address: orderData.address,
                  user: profile?.ID,
                  zoneId: null
                }
              : null
          );
          commit('setUserComment', orderData.userComment || '');
        } else {
          commit('setCurrAddressData', userAddressData || null);
        }
      } catch(error) {
        console.log(error);
      }
    },
  },
  mutations: {
    setProfile(state, profile) {
      state.profile = profile;
    },
    setLoggedIn(state, isLoggedIn) {
      state.isLoggedIn = isLoggedIn;
    },
    setPhoneExist(state, isPhoneExist) {
      state.isPhoneExist = isPhoneExist;
    },
    setCurrAddressData(state, data) {
      state.currAddressData = data;
    },
    setCurrRecipientData(state, data) {
      state.currRecipientData = data;
    },
    setUserAddresses(state, arr) {
      state.userAddresses = arr;
    },
    setUserRecipients(state, arr) {
      state.userRecipients = arr;
    },
    setUserComment(state, str) {
      state.userComment = str;
    },
    setSuccessOrderData(state, successOrderData) {
      state.successOrderData = successOrderData;
    },
    setNotifications(state, notifications) {
      state.notifications = notifications
    },
    setPersonalOrders(state, personalOrders) {
      state.personalOrders = personalOrders;
    },
    setPersonalInventory(state, personalInventory) {
      state.personalInventory = personalInventory;
    },
    setPhoneStatus(state, phoneStatus) {
      state.phoneStatus = phoneStatus;
    },
    setUserUpdateStatus(state, userUpdateStatus) {
      state.userUpdateStatus = userUpdateStatus;
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setIsLoaded(state, isLoaded) {
      state.isLoaded = isLoaded;
    }
  }
};
