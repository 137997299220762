import axios from 'axios';

export const auth = {
    namespaced: true,
    state() {
        return {
            isLoggedIn: false,
            captchaImg: '',
            disableCaptchaImg: false
        };
    },
    getters: {
        captchaImg: (state) => {
            return state.captchaImg
        },
        stateOfCaptcha: (state) => {
            return state.disableCaptchaImg
        }
    },
    actions: {
        sendSms({ commit }, { phone, captcha }) {
            return axios.post('/crm/lk/user/sms/', { phone, captcha });
        },
        authBySms({ commit }, { phone, sms_code }) {
            return axios.post('/crm/lk/user/auth-by-phone/', { phone, sms_code });
        },
        checkSmsCode({ commit }, { phone, code }) {
            return axios.post('/crm/lk/user/smscode/', { phone, code });
        },
        authByEmail({ commit }, { email, password }) {
            const formData = new FormData();
            formData.append('USER_LOGIN', email);
            formData.append('USER_PASSWORD', password);
            formData.append('TYPE', 'AUTH');
            formData.append('AUTH_FORM', 'Y');

            return axios.post('/ajax/userLogin.php', formData);
        },
        resetPasswordMail({commit}, {email}) {
            const formData = new FormData();
            formData.append('AUTH_FORM', 'Y');
            formData.append('TYPE', 'SEND_PWD');
            formData.append('USER_LOGIN', email);
            formData.append('USER_LOGIN', email);
            return axios.post('/ajax/forgotPassword.php', formData);
        },
        async submitCaptcha({commit}, {captchaInput}) {
            const sessid = document.querySelector('footer').dataset.sessid;
            const captchaCode = document.querySelector('footer').dataset.captchaCode;
            const formData = new FormData();
            formData.append('sessid', sessid);
            formData.append('captcha_code', captchaCode);
            formData.append('captcha_word', captchaInput);
            return await axios.post('/ajax/captcha.php', formData);
        },

        updateCaptchaImg({commit}, {err = false, response}) {
            console.log(response, 'response')
            if (err) {
                console.log(response, 'response');
                commit('setCaptchaImg', `/bitrix/tools/captcha.php?captcha_code=${response.data.captcha}`);
                document.querySelector('footer').dataset.captchaCode = response.data.captcha;
            } else {
                const captchaCode = document.querySelector('footer').dataset.captchaCode;
                commit('setCaptchaImg', `/bitrix/tools/captcha.php?captcha_code=${captchaCode}`);
            }
        },

        async reloadCaptchaImg({commit}) {
            try {
                commit('disableCaptcha')
                const captchaCode = document.querySelector('footer').dataset.captchaCode;
                const sessid = document.querySelector('footer').dataset.sessid;

                const formData = new FormData();
                formData.append('sessid', sessid);
                formData.append('captcha_code', captchaCode);
                formData.append('captcha_word', '');
                await axios.post(`/ajax/captcha.php`, formData)
                    .then((response)=>{
                        commit('setCaptchaImg', `/bitrix/tools/captcha.php?captcha_code=${response.data.captcha}`);
                        document.querySelector('footer').dataset.captchaCode = response.data.captcha
                        commit('enableCaptcha')
                    })
            } catch (err) {
                console.error('Проблемы с капчей: ', err)
                commit('enableCaptcha')
            }
        },
    },
    mutations: {
        setCaptchaImg(state, img) {
            console.log(img, 'img')
            state.captchaImg = img
        },
        disableCaptcha(state) {
            state.disableCaptchaImg = true
        },
        enableCaptcha(state) {
            state.disableCaptchaImg = false
        }
    }
};
