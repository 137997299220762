import axios from 'axios';
// import Observer from '../../common/scripts/observer';
import AlertHandler from '../../components/alert/alert';
import getAvailableDates from './getAvailableDates';
import { getZoneId } from '../../common/scripts/modules/delivery-zone-handler';
import { getOrderParams } from '../../common/scripts/modules/auth-handler';
import { account } from '../account/store';

const alertHandler = new AlertHandler();
const ERRORS = {
  default: 'Произошла ошибка. Повторите попытку позже.',
  addresses: 'Произошла ошибка при получении адресов. Попробуйте обновить страницу.',
  contacts: 'Произошла ошибка при получении контактов. Попробуйте обновить страницу.',
  payments: 'Произошла ошибка при получении способов оплаты. Попробуйте обновить страницу.',
  dates: 'Произошла ошибка при получении доступных дат',
  dateNoAvailable: 'Нет доступных дат для данного адреса',
  addressInvalidVal: 'Адрес не был найден. Выберите из списка или добавьте новый.',
  checkout: 'Произошла ошибка при оформлении заказа'
}
const SUCCESS = {
  availableDates: 'Расписание доставки успешно обновлено'
}

export const order = {
  namespaced: true,
  state() {
    return {
      paymentVariants: [],
      deliveryDates: {},
      currentLocation: '',
      zoneId: '',
      zoneLocationError: true,
      isLoading: false,
      isOrderSucceed: false,
      checkoutData: null,
    };
  },
  getters: {
    paymentVariants: (state) => {
      return state.paymentVariants;
    },
    deliveryDates: (state) => {
      return state.deliveryDates;
    },
    zoneId: (state) => {
      return state.zoneId;
    },
    zoneLocationError: (state) => {
      return state.zoneLocationError;
    },
    isLoading: (state) => {
      return state.isLoading;
    }
  },
  actions: {
    setOrderData({ commit }) {
      const orderEl = document.querySelector('#order');

      if (!orderEl.dataset?.payments) {
        alertHandler.showAlert(ERRORS.payments);
        return;
      }

      const parsedPayments = JSON.parse(orderEl.dataset?.payments);

      if (parsedPayments) {
        commit('setPayments', parsedPayments);
      } else {
        console.error('Ошибка', parsedPayments);
        alertHandler.showAlert(ERRORS.payments);
      }
    },
    async getAvailableDeliveryDates({ commit }, payload) {
      const { address } = payload;
      const locationData = {
        '216': ['Москва', 'Московская область'],
        '269': ['Санкт-Петербург', 'Ленинградская область']
      };
      const currLocationData = Object.values(locationData).reduce(
          (acc, item, index) => ({
              ...acc,
              ...(item.filter((str) => address.includes(str)).length && { index, key: item.find((str) => address.includes(str)) })
          }), {}
      );
      const formData = new FormData();

      commit('setIsLoading', true);

      if(!address) {
        commit('setDeliveryDates', {});
        commit('setZoneLocationError', true);
        commit('setIsLoading', false);
        console.log({ address });

        if(address !== '') alertHandler.showAlert(ERRORS.addressInvalidVal);

        return;
      }
    
      try {
        const { zoneId } = await getZoneId(address);

        if(!zoneId) {
          commit('setZoneLocationError', true);
          return;
        }
      
        formData.append('location', Number(Object.keys(locationData)[currLocationData.index]));
        formData.append('zone', zoneId);

        const { data } = await axios.post('/ajax/checkOrderProductsCount.php', formData);

        if (data.state === 1 && data.zone?.SCHEDULE) {
          console.log('zone: ', data.zone);
          console.log('store: ', data.store);
          console.log('SCHEDULE: ', data.zone.SCHEDULE);
          const isArray = Array.isArray(data.zone.SCHEDULE);

          commit('setDeliveryDates', isArray ? {} : data.zone.SCHEDULE);
          commit('setZoneLocationError', isArray);
          alertHandler.showAlert(isArray ? ERRORS.dateNoAvailable : SUCCESS.availableDates);
        } else {
          commit('setDeliveryDates', {});
          commit('setZoneLocationError', true);
          alertHandler.showAlert(ERRORS.dateNoAvailable);
        }
      } catch (error) {
        console.error(error);
        commit('setDeliveryDates', {});
        commit('setZoneLocationError', true);
        alertHandler.showAlert(ERRORS.dates);
      } finally {
        commit('setIsLoading', false);
      }
    },
    async makeOrderFormData({ commit, state }, payload) {
      const fd = new FormData();
      const { zoneData } = payload;
      const getCurrZoneId = (zoneId) => {
        return new Promise((resolve) => {
          resolve({ zoneId });
        });
      };
      let data = {
        isSucceed: false,
        data: null
      };

      //commit('setIsLoading', true);
      console.log('makeOrderFormData: ', payload);

      try {
        // TODO: изменить передаваемый в zoneData.address адрес
        const { zoneId } = zoneData.zoneId ? await getCurrZoneId(zoneData.zoneId) : await getZoneId(zoneData.address);

        if(zoneId) {
          console.log('zoneData: ', { ...zoneData, zoneId });
  
          fd.append('sessid', payload.sessId);
          fd.append('soa-action', 'saveOrderAjax');
          fd.append('ORDER_PROP_57', 'WEB');
          fd.append('location_type', 'code');
          fd.append('ORDER_PROP_42', 0);
          fd.append('zone', zoneId);
          fd.append('save', 'Y');
          fd.append('BUYER_STORE', 246);
          fd.append('PERSON_TYPE', 1);
          fd.append('PERSON_TYPE_OLD', 1);
          fd.append('UNAUTH_USER', !payload.isLoggedIn);
          fd.append('ORDER_PROP_7[region]', payload.address.address ? payload.address.address.split(',')[0] : payload.address.title.split(',')[0]);
          fd.append('private-home', payload.address.home ? payload.address.home : '');
          fd.append('ORDER_PROP_7[apartment]', payload.address?.apartment ? payload.address.apartment : '');
          fd.append('ORDER_PROP_7[entrance]', payload.address?.entrance ? payload.address?.entrance : '');
          fd.append('ORDER_PROP_7[floor]', payload.address?.floor ? payload.address.floor : '');
          fd.append('ORDER_PROP_7[domofon]', payload.address?.iCode ? payload.address.iCode : '');
          fd.append('ORDER_PROP_1', payload.recipient.name);
          fd.append('ORDER_PROP_3', payload.recipient.phone);
          fd.append('ORDER_PROP_22', payload.orderDate.fullDate.replaceAll('-', '.'));
          fd.append('ORDER_PROP_28', payload.orderTime.time.replace('-', ';'));
          fd.append('ORDER_DESCRIPTION', payload.comment);
          fd.append('ORDER_PROP_62', payload.doCallback ? 'Y' : '');
          fd.append('PAY_SYSTEM_ID', payload.paymentVariant);
          fd.append('agreement', 'on');
    
          if (payload.code.length > 0) {
            fd.append('ORDER_PROP_20', payload.code);
          } else {
            fd.append('ORDER_PROP_7[address-yandex]', payload.address.address);
            fd.append('ORDER_PROP_7[address]', payload.address?.address ? payload.address?.address : payload.address?.title);
          }
          
          data = {
            isSucceed: true,
            data: fd
          };
        }
      } catch(error) {
        console.log(error);
      } finally {
        //commit('setIsLoading', false);
        return data;
      }
    },
    async makeOrder({ commit, dispatch }, payload) {
      commit('setIsLoading', true);
      
      const { isLoggedIn, recipient } = payload;

      try {
        const { isSucceed, data: orderParams } = await dispatch('makeOrderFormData', payload);
        console.table([...orderParams.entries()]);

        if(isSucceed) {
          const { data } = await axios.post('/personal/order/make/', orderParams);
  
          console.log('makeOrder: ', data);
  
          if(data.order?.ERROR) {
            commit('setIsLoading', false);
            alertHandler.showAlert(data.order?.ERROR?.CUSTOM ? data.order.ERROR.CUSTOM[0] : ERRORS.checkout);
          } else {
            if(!isLoggedIn) {
              console.log(recipient);
              const res = await dispatch(
                'account/handleUserRecipient',
                {
                  action: 'add',
                  name: recipient.name,
                  phone: recipient.phone
                },
                { root: true }
              );
              console.log({res});
            }

            const { data: { TOKEN } } = await axios.post('/ajax/getTokenTakeOrder.php');
            const {
              data: {
                PRICE,
                DATE_INSERT,
                USER_DESCRIPTION,
                STATUS_ID_DESCRIPTION,
                PAY_STATUS,
                PROP
              }
            } = await axios.post('/ajax/getOrderByID.php', { ORDER_ID: data.order.ID, TOKEN });

            const {
              CONTACT_PERSON,
              DELIVERY_DATE,
              DELIVERY_TIME,
              PHONE,
              ADDRESS
            } = Object.values(PROP).reduce((acc, { CODE, VALUE }) => ({ ...acc, [CODE]: VALUE[0] }), {})

            getOrderParams(true);
            commit('setOrderSucceed', true);
            commit(
              'setCheckoutData',
              {
                'order': {
                  'id': data.order.ID,
                  'date': DATE_INSERT,
                  'status': {
                    'NAME': STATUS_ID_DESCRIPTION
                  },
                  'price': PRICE,
                  'user_comment': USER_DESCRIPTION,
                  'properties': {
                    CONTACT_PERSON,
                    PHONE,
                    ADDRESS,
                    DELIVERY_DATE,
                    DELIVERY_TIME
                  },
                },
                'payment': {
                  'paid': PAY_STATUS,
                }
              }
            );
            alertHandler.showAlert(`Заказ #${data.order?.ID} успешно оформлен`);
          }
        }
      } catch (error) {
        console.error(error);
        commit('setIsLoading', false);
        alertHandler.showAlert('Произошла ошибка при оформлении заказа.');
      }
    },
  },
  mutations: {
    setPayments(state, payments) {
      state.paymentVariants = payments;
    },
    setDeliveryDates(state, deliveryDates) {
      state.deliveryDates = deliveryDates;
    },
    setZoneId(state, zoneId) {
      state.zoneId = zoneId;
    },
    setZoneLocationError(state, zoneLocationError) {
      state.zoneLocationError = zoneLocationError;
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setOrderSucceed(state, isSucceed) {
      state.isOrderSucceed = isSucceed;
    },
    setCheckoutData(state, data) {
      state.checkoutData = data;
    },
  }
}
