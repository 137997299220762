/**
 * Зависимости
 */
import Utils from './utils';

/**
 * Инициализация мобильного хедера с фиксацией и анимацией при прокрутке страницы
 * @param {Object} headerSelector - хедер для изменения стилей
 */
export const initMobileHeader = (headerSelector) => {
    const header = headerSelector;
    let isHeaderFixed = false;
    const scrollHeight = 120;

    const handleScroll = () => {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

        if (scrollTop > scrollHeight && !isHeaderFixed) {
            header.classList.add('fixed');

            setTimeout(() => {
                header.classList.add('animate');
            }, 200);

            isHeaderFixed = true;
        } else if (scrollTop <= scrollHeight && isHeaderFixed) {
            header.classList.remove('animate');

            setTimeout(() => {
                header.classList.remove('fixed');
            }, 200);

            isHeaderFixed = false;
        }
    };

    window.addEventListener('scroll', handleScroll);
};

/**
 * @param menuBtnSelector {String} - селектор кнопки вызова мобильного меню
 * Происходит его раскрытие и скрытие
 */
export const initMobileMenu = (menuBtnSelector) => {
    const menuBtn = menuBtnSelector;
    const mobileMenu = document.querySelector(".j-header-mobile-menu");
    const header = document.querySelector(".j-header");

    const additionalMenus = Array.from(mobileMenu.querySelectorAll(".j-additional-menu"));
    const additionalMenuBtnOpen = Array.from(mobileMenu.querySelectorAll(".j-additional-btn-open"));
    const additionalMenuBtnClose = Array.from(mobileMenu.querySelectorAll(".j-additional-btn-close"));

    const search = document.querySelector('.j-global-search-wrapper')
    const searchBtnArr = Array.from(document.querySelectorAll('.j-header-search'));

    if (!mobileMenu) return;

    const toggleClass = (element, className) => {
        element.classList.toggle(className);
    };

    const toggleMenu = () => {
        const searchIsOpen = search.classList.contains('is-active');
        const isOpen = menuBtn.classList.contains('is-open');
        toggleClass(menuBtn, "is-open");
        toggleClass(menuBtn, "button-with-icon_theme-outline");
        toggleClass(mobileMenu, "is-open");
        toggleClass(header, "menu-open");

        if (isOpen) {
            if (search.classList.contains('was-open')) {
                search.classList.add('is-active')
                searchBtnArr.forEach((btn)=>{
                    btn.classList.add('is-active')
                })
                search.classList.remove('was-open')
            }
            Utils.bodyStatic();
        } else {
            if (searchIsOpen) {
                search.classList.remove('is-active')
                search.classList.add('was-open')
                console.log(searchBtnArr)
                searchBtnArr.forEach((btn)=>{
                    console.log(btn)
                    btn.classList.remove('is-active')
                })
            }
            Utils.bodyFixed(mobileMenu);
        }
    };

    const showPanel = (target) => {
        additionalMenus.forEach((item) => {
            if(item.id === target) {
                item.classList.add('is-open');
            } else {
                item.classList.remove('is-open');
            }
        });
    }

    const toggleAdditional = (e) => {
        e.preventDefault();
        toggleClass(mobileMenu, "is-additional");
        const { target } = e.target.dataset;
        if(target) {
            showPanel(target);
        } else {
            additionalMenus.forEach((item) => {
                item.classList.remove('is-open');
            });
        }
    };

    menuBtn.addEventListener("click", toggleMenu);
    additionalMenuBtnOpen.forEach((item) => {
        item.addEventListener("click", toggleAdditional);
    });
    additionalMenuBtnClose.forEach((item) => {
        item.addEventListener("click", toggleAdditional);
    });
};

/**
 * Инициализация добавления-удаления класса для блока с инфой на главной
 * @param {Object} selector - блок с анимацпей
 */
export const initInfoBlock = (selector) => {

    let timeoutId;

    // добавляем класс is-active, если элемент виден на экране
    function setActiveClass() {
        const { top, bottom } = selector.getBoundingClientRect();

        if (top < window.innerHeight && bottom >= 0) {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                selector.classList.add('is-active');
            }, 300);
        } else {
            // очищаем задержку, если элемент не виден
            clearTimeout(timeoutId);
            selector.classList.remove('is-active');
        }
    }

    // вызываем функцию при загрузке страницы и при каждом скролле
    window.addEventListener('load', setActiveClass);

    // Используем дебаунс для обработчика события scroll
    const debouncedSetActiveClass = Utils.debounce(setActiveClass, 100);
    window.addEventListener('scroll', debouncedSetActiveClass);
};


/**
 * Инициализация кнопок сброса для инпутов
 * @param {Array} resetBtnArr - массив кнопок сброса
 */
export const initResetBtns = (resetBtnArr) => {
    // Функция обработки инпута и кнопки сброса
    function handleInputReset(input, resetBtn) {

        // Обработчик ввода
        input.addEventListener('input', function () {
            if (this.value.trim().length > 0) {
                resetBtn.classList.remove('hidden');
            } else {
                resetBtn.classList.add('hidden');
            }
        });

        // Обработчик клика по кнопке сброса
        resetBtn.addEventListener('click', function (e) {
            e.preventDefault();
            input.value = '';
            this.classList.add('hidden');
            input.focus();

            // Создаем новое событие
            const event = new Event('input', {
                'bubbles': true,
                'cancelable': true
            });

            // Отправляем событие
            input.dispatchEvent(event);
        });
    }

    // Обходим все кнопки сброса и связанные инпуты
    resetBtnArr.forEach((resetBtn) => {
        const input = resetBtn.closest('.search-box').querySelector('.search-box-input');
        if (input) {
            handleInputReset(input, resetBtn);
        }
    });
};


/**
 * Инициализация функции вызова поиска
 * @param {Array} searchBtnArr - массив кнопок поиска
 */
export const initSearch = (searchBtnArr) => {
    // Функция обработки клика на кнопку поиска
    function handleSearchButtonClick(searchBtn) {
        const searchWrapper = document.querySelector('.j-global-search-wrapper');

        searchBtn.addEventListener('click', function () {
            this.classList.toggle('is-active');
            searchWrapper.classList.toggle('is-active');
        });
    }

    // проходимся по всем кнопкам (у нас их 2)
    searchBtnArr.forEach((searchBtn) => {
        handleSearchButtonClick(searchBtn);
    });
};


/**
 * Кастомные табы для страницы Доставки (со старого сайта)
 */
export const initCustomTabs = () => {
    const tabContainer = Array.from(document.querySelectorAll(".js-custom-tabs"));
    if (tabContainer.length === 0) return;
    tabContainer.forEach(element => {
        const tabSources = Array.from(element.querySelectorAll("[data-source]"));
        const tabTargets = Array.from(element.querySelectorAll("[data-target]"));
        tabSources.forEach(tab => {
            tab.addEventListener("click", () => {
                const source = tab.dataset.source;
                const selector = '[data-target="' + source + '"]';
                const target = element.querySelector(selector);
                if (target) {
                    tabSources.forEach(tab => tab.classList.remove("active"));
                    tabTargets.forEach(tab => tab.classList.remove("active"));
                    tab.classList.add("active");
                    target.classList.add("active");
                }
            });
        });
    });
};
