/**
 * Панели карточек с подробной информацией
 * @param {Object} options - объект настроек
 * @param {string} options.section - селектор элемента-обертки
 * @param {string} [options.prodCardItemSel] - класс элементов карточек
 * @param {string} [options.prodCardTogglerSel] - класс элементов переключателей
 */

class cardPanel {
    constructor(options) {
        this.activeClass = 'is-active';
        this.init(options);
    }

    /**
     * Инициализация компонента
     * @param {Object} options - объект настроек
     */
    init(options) {
        const {
            section,
            prodCardItemSel,
            prodCardTogglerSel
        } = options;

        if (!section) {
            console.error('Необходим целевой элемент для инициализации');
            return;
        }

        this.prodCardsSection = section;
        this.prodCardItemSel = prodCardItemSel;
        this.prodCardItems = Array.from(this.prodCardsSection.querySelectorAll(prodCardItemSel));
        this.prodCardTogglers = Array.from(this.prodCardsSection.querySelectorAll(prodCardTogglerSel));

        this.bindEvents();
    }

    /**
     * Добавление слушателей событий
     */
    bindEvents() {
        this.prodCardTogglers.forEach((toggler) => {
            toggler.addEventListener('click', this.togglePanel.bind(this));
        });

        document.body.addEventListener('click', this.closePanel.bind(this));
    }

    /**
     * Проверка наличия класса is-active у активной карточки
     * @returns {boolean} true, если хотя бы одна карточка активна; иначе false
     */
    isPanelActive() {
        return Boolean(this.prodCardItems.filter(item => item.classList.contains(this.activeClass)).length);
    }

    /**
     * Переключение панелей
     * @param {Event} event - событие
     */
    togglePanel(event) {
        event.preventDefault();
        const { currentTarget } = event;
        const currProdCard = currentTarget.closest(this.prodCardItemSel);
        currProdCard.classList.toggle(this.activeClass);
        this.prodCardsSection.classList.toggle(this.activeClass, this.isPanelActive());
        this.prodCardItems.forEach((item) => {
            if(item !== currProdCard) {
                item.classList.remove(this.activeClass);
            }
        });
    }

    /**
     * Скрытие панелей
     * @param {Event} event - событие
     */
    closePanel(event) {
        const { target } = event;
        if(!target.closest(this.prodCardItemSel) && this.isPanelActive()) {
            this.prodCardsSection.classList.toggle(this.activeClass, !this.isPanelActive());
            this.prodCardItems.forEach((item) => {
                item.classList.remove(this.activeClass);
            });
        };
    }
}

export default cardPanel;
