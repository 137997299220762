/**
 * DEPENDENCIES
 */
import 'url-search-params-polyfill';
import {clearAllBodyScrollLocks, disableBodyScroll} from 'body-scroll-lock';

class Utils {
    /**
     * Метод полностью очищает весь html элемент.
     * @param {Object} element - DOM-элемент, который необходимо очистить.
     */
    static clearHtml(element) {
        element.innerHTML = '';
    }

    /**
     * Метод вставляет содержимое в блок.
     * @param {Object} element - элемент в который нужно вставить.
     * @param {Object/string} content - вставляемый контент.
     */
    static insetContent(element, content) {
        if (typeof content === 'string') {
            element.insertAdjacentHTML('beforeend', content);
        } else if (typeof content === 'object') {
            element.appendChild(content);
        }
    }

    /**
     * Метод полностью удаляет элемент из DOM-дерева.
     * @param {Object} element - элемент, который необходимо удалить.
     */
    static removeElement(element) {
        // node.remove() не работает в IE11
        element.parentNode.removeChild(element);
    }

    /**
     * Метод отправляет ajax запрос на сервер.
     * @param {Object} data - отправляемые данные.
     * @param {String} url - маршрут по которому нужно произвести запрос.
     * @param {Function} callback -  функция обратного вызова, которая при успехе вызовет success, а при ошибке error.
     * @param {String} method -  метод для отправки запроса. POST по умолчанию
     */
    static send(data, url, callback = function() {}, method = 'POST') {
        const xhr = new XMLHttpRequest();
        const statusSuccess = 200;

        xhr.open(method, url);

        if (!(data instanceof FormData)) {
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        }
        xhr.setRequestHeader('x-requested-with', 'XMLHttpRequest');

        xhr.send(data);

        xhr.onload = function XHR() {
            if (xhr.status === statusSuccess) {
                const req = JSON.parse(this.responseText);

                callback.success(req);
            } else {
                callback.error(xhr.status);
            }

            if (callback.complete) {
                callback.complete();
            }
        };
    }

    /**
     * Метод проверяет наличие интернета
     * @return {boolean} - При наличии результатом будет true, а при отсутсвии false.
     */
    static checkInternetConnection() {
        return navigator.onLine;
    }

    /**
     * Метод проверяет присутствует ли ключ в объекте
     * @param {Object} object - проверяем объект
     * @param {String} key - ключ, наличие которого проверяет в объекте
     * @return {boolean} - присутствует или нет ключ в объекте
     */
    static keyExist(object, key) {
        return Object.prototype.hasOwnProperty.call(object, key);
    }

    /**
     * Метод проверяет пустой объект или нет
     * @param {Object} object - объект проверяемый на пустоту
     * @return {boolean} - true если пустой и false если полный
     */
    static isEmptyObject(object) {
        const empty = 0;

        return Object.keys(object).length === empty;
    }

    /**
     * Проверяет переданные данные на строку
     * @param {String} string - данные на проверку
     * @return {boolean} - возращает true, если строка, и false наоборот
     */
    static isString(string) {
        return typeof string === 'string';
    }

    /**
     * Узнает index элемента в родительской элемент
     * Аналог jquery.index()
     * @param {Node} element - искомый элемент
     * @return {number} - порядковый номер (индекс) в родительском элементе
     */
    static getElementIndex(element) {
        return Array.from(element.parentNode.children).indexOf(element);
    }

    /**
     * Проверяет, поддерживает ли устройство touch-события
     * @return {boolean} - возращает true, если Touch-устройство, и false наоборот
     */
    static isTouch() {
        return Boolean(typeof window !== 'undefined' &&
            ('ontouchstart' in window ||
                (window.DocumentTouch &&
                    typeof document !== 'undefined' &&
                    document instanceof window.DocumentTouch))) ||
            Boolean(typeof navigator !== 'undefined' && (navigator.maxTouchPoints || navigator.msMaxTouchPoints));
    }

    /**
     * Фиксирует страницу для запрета прокрутки
     * @param {Node} element - кроме данного элемента у всех остальных сбросится скролл
     */
    static bodyFixed(element) {
        disableBodyScroll(element, {reserveScrollBarGap: true});
    }

    /**
     * Снимаем фиксирование страницы
     */
    static bodyStatic() {
        clearAllBodyScrollLocks();
    }

    /**
     * Выбирает окончание слова.
     * @param {number} n - количество
     * @param {array} words - масcив слов. Например показать еще ['квартиру', 'квартиры', 'квартир']
     * @return {string} - слово в правильном склонении.
     */
    static pluralWord(n, words) {
        /* eslint-disable  */
        const $i = n % 10 === 1 && n % 100 !== 11 ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2;
        /* eslint-enable*/

        return words[$i];
    }

    /**
     * Устанавиливает гет-параметры
     * @param {array} params - массив объектов (ключ/значение) для установки в гет
     */
    static setUrlParams(params) {
        const query = new URLSearchParams();

        params.forEach((item) => {
            query.append(item.param, item.value);
        });

        window.history.pushState(null, null, `?${query.toString()}`);
    }

    /**
     * Получает значение гет-параметра
     * @param {string} param - ключ для поиска в гет
     * @return {string} value - значение гет
     */
    static getUrlParams(param) {
        const get = window.location.search;
        const url = new URLSearchParams(get);

        return url.get(param);
    }

    /**
     * Возвращает булевое значение если текущий размер находится в интервале переданных брейкопинтов.
     * Например при 380 и переданных значениях (320 670) вернет true, во всех остальных случаях false.
     * @param {Number} min - минимальное значение ширины. Будет тру если больше или равно.
     * @param {Number} max - максимальное значение ширины. Будет тру если меньше.
     * @return {boolean} булевое значение если попадает в переданный интервал
     */
    static isBreakpoint(min, max) {
        return window.innerWidth >= min && window.innerWidth < max;
    }

    /**
     * Подключение скрипта и коллбэк, если необходимо
     * @param {string} url - адрес скрипта/API который будем подключать
     * @param {object} callback - колбэк, если нужен
     */
    static loadScript(url, callback) {
        const script = document.createElement('script');

        script.onload = () => {
            if (callback) {
                return callback();
            }

            return false;
        };

        script.src = url;
        document.getElementsByTagName('head')[0].appendChild(script);
    }

    /**
     * Утилита для тротла (throttle) функции.
     * @param {Function} callee - функция колбек.
     * @param {Number} timeout - задержка в миллисекундах.
     */
    static throttle(callee, timeout) {
        let timer = null;

        return function perform(...args) {
            if (timer) return;

            timer = setTimeout(() => {
                callee(...args);

                clearTimeout(timer);
                timer = null;
            }, timeout);
        }
    }

    /**
     * Утилита для дебаунса (debounce) функции.
     * @param {Function} func - функция, которую нужно задебаунсить.
     * @param {number} wait - время ожидания в миллисекундах.
     * @param {boolean} [immediate=false] - если true, функция будет вызвана сразу при первом вызове.
     * @returns {Function} - задебаунсенная функция.
     */
    static debounce(func, wait, immediate = false) {
        let timeout;

        return function debounced(...args) {
            const context = this;

            const later = function() {
                timeout = null;
                if (!immediate) {
                    func.apply(context, args);
                }
            };

            const callNow = immediate && !timeout;

            clearTimeout(timeout);
            timeout = setTimeout(later, wait);

            if (callNow) {
                func.apply(context, args);
            }
        };
    }
    // пример использования дебаунса
    // function logEvent(event) {
    //     console.log(`Событие: ${event} произошло в ${new Date().toISOString()}`);
    // }
    // const debouncedLogEvent = Utils.debounce(logEvent, 1000);
    // debouncedLogEvent()

    /**
     * Утилита скролла к якорю (по #)
     * @param {Number} destination - место нахначения
     * @param {Number} duration - длительность анимации
     * @param {String} easing - тип анимации (напр. - linear)
     * @param {Function} callback - функция коллбег
     * @returns 
     */
    static scrollIt(
        destination,
        duration = 200,
        easing = 'linear',
        callback
    ) {
        const easings = {
        linear(t) {
            return t;
        },
        easeInQuad(t) {
            return t * t;
        },
        easeOutQuad(t) {
            return t * (2 - t);
        },
        easeInOutQuad(t) {
            return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
        },
        easeInCubic(t) {
            return t * t * t;
        },
        easeInOutCubic(t) {
            return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
        },
        easeInQuart(t) {
            return t * t * t * t;
        },
        easeInQuint(t) {
            return t * t * t * t * t;
        }
        };
    
        // Определение координаты элемента по вертикали от начала документа
        function pageY(elem) {
        return elem.offsetParent
            ? elem.offsetTop + pageY(elem.offsetParent)
            : elem.offsetTop;
        }
    
        const start = window.pageYOffset;
        const startTime = 'now' in window.performance ? performance.now() : new Date().getTime();
    
        const documentHeight = Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight
        );
        const windowHeight = window.innerHeight
            || document.documentElement.clientHeight
            || document.getElementsByTagName('body')[0].clientHeight;
        const destinationOffset = typeof destination === 'number' ? destination : pageY(destination);
        const destinationOffsetToScroll = Math.round(
        documentHeight - destinationOffset < windowHeight
            ? documentHeight - windowHeight
            : destinationOffset
        );
    
        if ('requestAnimationFrame' in window === false) {
        window.scroll(0, destinationOffsetToScroll);
        if (callback) {
            callback();
        }
        return;
        }
    
        function scroll() {
        const now = 'now' in window.performance ? performance.now() : new Date().getTime();
        const time = Math.min(1, (now - startTime) / duration);
        const timeFunction = easings[easing](time);
        // эта функция скролит по чуть чуть
        window.scroll(
            0,
            Math.ceil(timeFunction * (destinationOffsetToScroll - start) + start)
        );
        // усоловие если время истекло, то поставь в конечную точку анимации
        if (duration <= now - startTime) {
            return;
        }
        // это условие проверяет условие кончилась ли аницация.
        // в данном случае проверяет дошли ли до точки назначения
        if (window.pageYOffset === destinationOffsetToScroll) {
            if (callback) {
            callback();
            }
            return;
        }
        // эта функция запускает следующую итерацию анимации
        requestAnimationFrame(scroll);
        }
    
        scroll();
    }

    /**
     * Утилита маски телефона, работает по типу input[type="tel"]
     */
    static phoneMask() {
        [].forEach.call(document.querySelectorAll('input[type="tel"]'), (input) => {
            let keyCode;
            function mask(event) {
                if (!(event.keyCode)) {
                    keyCode = event.keyCode;
                }
                const pos = this.selectionStart;
                if (pos < 3) event.preventDefault();
                const matrix = '+7 (___) ___ __ __';
                let i = 0;
                const def = matrix.replace(/\D/g, '');
                const val = this.value.replace(/\D/g, '');
                let newValue = matrix.replace(/[_\d]/g, (a) => (i < val.length ? val.charAt(i++) || def.charAt(i) : a));
                i = newValue.indexOf('_');
                if (i !== -1) {
                    // if (!(i < 5)) {
                    //     i = 3;
                    // }
                    newValue = newValue.slice(0, i);
                }
                let reg = matrix.substr(0, this.value.length).replace(/_+/g,
                    (a) => `\\d{1,${a.length}}`).replace(/[+()]/g, '\\$&');
                reg = new RegExp(`^${reg}$`);
                if (!reg.test(this.value) || this.value.length < 5 || (keyCode > 47 && keyCode < 58)) {
                    this.value = newValue;
                }
                if (event.type === 'blur' && this.value.length < 5) this.value = '';
            }
    
            input.addEventListener('input', mask, false);
            input.addEventListener('focus', mask, false);
            input.addEventListener('blur', mask, false);
            input.addEventListener('keydown', mask, false);
        });
    }

    /**
     * Функция для инициализации лоадера
     * @param {String} state - состояние (hide/show)
     */
    static initLoader(state) {
        const loader = document.querySelector('.js-loader');
        if (!loader) return;

        if (state === 'show') {
            loader.classList.add('is-show');
        } else {
            loader.classList.remove('is-show');
        }
    }
}

export default Utils;
