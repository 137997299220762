import axios from 'axios';
import Utils from '../utils';
import Observer from "common/scripts/observer";

const commonObserver = new Observer();

const VACANCY_SELECTORS = {
    form: '.js-vacancy-search',
    input: '.js-vacancy-search-input',
    reset: '.j-reset-input',
    vacancyChapter: '.js-vacancy-chapter',
    chapterCounter: '.js-vacancy-counter',
    vacancyCard: '.js-vacancy-card',
    accordionToggler: '.js-accordion-toggler',
    accordionContent: '.js-accordion-content',
    filterContainer: '.js-vacancy-filter',
    filterBtn: '.js-vacancy-filter-btn'
};

// классы для управления состояниями
const STATE_CLASSES = {
    active: 'is-active',
    show: 'is-show',
    filtered: 'is-filtered'
};

/**
 * @description API для получения данных о вакансиях
 * @param {string} query - текст поискового запроса
 * @param {string} category - id категории
 * @returns {Promise<object>} - Объект с идентификаторами
 */
async function fetchVacancies(query = '', category = '0') {
    try {
        const response = await axios.get('/crm/career/', {
            params: {
                q: query,
                c: category
            }
        });

        return response.data.data;
    } catch (error) {
        console.error('Ошибка при получении вакансий:', error);
    }
}

/**
 * @description Переключает видимость карточек вакансий на основе поискового запроса
 * @param {string} query - текст поискового запроса
 * @param {NodeList} vacancyCards - список HTML элементов, представляющих вакансии
 */
async function toggleVacanciesState(query, vacancyCards) {
    const activeCategoryId = document.querySelector(`${VACANCY_SELECTORS.filterBtn}.${STATE_CLASSES.active}`).dataset.tab;

    try {
        const data = await fetchVacancies(query, activeCategoryId);
        const { paginatedList } = data;

        if (!paginatedList?.list?.length) {
            commonObserver.publish('showAlert', 'По вашему запросу ничего не найдено');
        }

        vacancyCards.forEach((card) => {
            const cardId = Number(card.getAttribute('data-id'));
            const isCardInList = paginatedList.list.map(({ id }) => Number(id)).includes(cardId);

            isCardInList ? card.classList.remove(STATE_CLASSES.filtered) : card.classList.add(STATE_CLASSES.filtered);
        });
    } catch (error) {
        commonObserver.publish('showAlert', 'Произошла ошибка, попробуйте ещё раз');
        console.error('Ошибка при переключении видимости вакансий:', error);
    }

    updateAccordions();
}

/**
 * @description Подсчет доступных вакансий в каждом разделе (аккордеоне)
 * @param {Element} containerList - контейнер раздела
 * @param {Element} counterEl - элемент счетчика раздела
 */
function countSearchableVacancies(containerList, counterEl) {
    const vacancyCardsCount = containerList.querySelectorAll(VACANCY_SELECTORS.vacancyCard).length;
    const vacancyCardsFilteredCount = containerList.querySelectorAll(`${VACANCY_SELECTORS.vacancyCard}.${STATE_CLASSES.filtered}`).length;
    const totalValue = vacancyCardsCount - vacancyCardsFilteredCount;

    counterEl.textContent = `(${totalValue})`;
    containerList.style.display = totalValue === 0 ? 'none' : '';

    if (totalValue === 0) {
        containerList.parentElement.style.display = 'none';
    } else {
        containerList.parentElement.style.display = 'block';
    }
}

/**
 * @description Обновление состояний аккордеонов после поиска
 */
function updateAccordions() {
    const vacancyAccordions = document.querySelectorAll(VACANCY_SELECTORS.vacancyChapter);

    vacancyAccordions.forEach((accordion) => {
        const accordionToggler = accordion.querySelector(VACANCY_SELECTORS.accordionToggler);
        const accordionContent = accordion.querySelector(VACANCY_SELECTORS.accordionContent);
        const counter = accordion.querySelector(VACANCY_SELECTORS.chapterCounter);

        accordionToggler.classList.remove(STATE_CLASSES.active);
        accordionContent.classList.remove(STATE_CLASSES.show);
        accordionContent.style.height = '';
        countSearchableVacancies(accordionContent, counter);
    });
}

/**
 * @description Инициализация фильтров вакансий
 * @param {string} query - текст поискового запроса
 * @param {NodeList} vacancyCards - список HTML элементов, представляющих вакансии
 */
function initVacanciesFilters(query, vacancyCards) {
    const filterButtonsContainer = document.querySelector(VACANCY_SELECTORS.filterContainer);
    const filterBtns = filterButtonsContainer.querySelectorAll(VACANCY_SELECTORS.filterBtn);

    filterBtns.forEach(button => {
        button.addEventListener('click', (e) => {
            filterBtns.forEach((item) => item.classList.remove(STATE_CLASSES.active));
            e.currentTarget.classList.add(STATE_CLASSES.active);
            document.querySelector(VACANCY_SELECTORS.input).value = '';
            toggleVacanciesState(query, vacancyCards);
        });
    });
}

/**
 * @description Инициализация поиска по вакансиям и обработка ввода и отправки
 */
function initVacanciesSearch() {
    const form = document.querySelector(VACANCY_SELECTORS.form);
    if (!form) return;

    const input = form.querySelector(VACANCY_SELECTORS.input);
    const vacancyCards = document.querySelectorAll(VACANCY_SELECTORS.vacancyCard);

    form.addEventListener('submit', (e) => {
        e.preventDefault();
        toggleVacanciesState(input.value, vacancyCards);
    });

    form.addEventListener('keypress', function(e) {
        if (e.keyCode === 13) {
            e.preventDefault();
        }
    });

    input.addEventListener('input', Utils.debounce(() => {
        toggleVacanciesState(input.value, vacancyCards);
    }, 1000));

    initVacanciesFilters(input.value, vacancyCards);
}

export default initVacanciesSearch;
