import {createStore} from 'vuex';
import { cart } from '../pages/cart/store';
import { auth } from '../components/auth-form/store';
import { order } from '../pages/order/store';
import { account } from '../pages/account/store';

const store = createStore({
    modules: {
        cart,
        auth,
        order,
        account
    },
    state() {
        return {
            ww: 0
        };
    },

    getters: {
        ww: (state) => {
            return state.ww;
        }
    },

    actions: {
        updateWindowWidth({commit}, ww) {
            commit('updateWindowWidth', ww);
        }
    },

    mutations: {
        updateWindowWidth(state, payload) {
            state.ww = payload;
        }
    }
});

export default store;
