import axios from 'axios';
import Utils from "../utils";
import AlertHandler from '../../../components/alert/alert';

const alertHandler = new AlertHandler();

const FORM_SELECTORS = {
  form: '.js-form',
  formContent: '.js-form-content',
  formSuccess: '.js-form-content-success',
  sendBtn: '.js-form-send',
  input: '.js-input-field',
  checkbox: '.js-input-checkbox',
  errorField: '.js-input-field-error',
  fileContainer: '.js-upload-file-container',
  fileDescription: '.js-upload-file-description'
}

const FORM_CLASSNAMES = {
  subscribe: 'js-form-subscribe',
  vacancy: 'js-form-vacancy',
  contact: 'js-form-contact',
  feedback: 'js-form-feedback',
  question: 'js-qa-form',
  order: 'js-quick-order'
}

const FILE_UPLOAD_TEXT = {
  default: 'Максимум 1 файл до 5 МБ, в&nbsp;формате: <br>PDF / DOC / DOCX',
  error: 'Резюме обязательно'
}

const FORM_STATE = {
  error: 'is-error',
  hide: 'is-hide'
}

const FORM_ENPOINTS = {
  subscribe: 'subscribe', // ajax/subscribe.php
  vacancy: 'sendResume', // url для отправки формы вакансий
  contact: 'requestCall', // url для отправки формы вакансий
  feedback: '', // url для отправки формы заказа звонка
  question: 'sendQuestion', // url для отправки вопрос из раздела FAQ
  order: 'quickOrder' // url быстрого заказа
}

/**
 * Валидация электронной почты
 * @param email
 * @return {boolean}
 */
const validateEmail = (email) => {
  const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  return re.test(email);
}

/**
 * Валидация телефона по длине, так как используется маска телефона
 * 18 - необходимое количество символов в маске телефона
 * @param phone
 * @return {boolean}
 */
const validatePhone = (phone) => {
  const re = 18;
  return phone.length < re ? false : true;
}

/**
 * Валидация строки (напр. - имя фамилия)
 * @param string
 * @return {boolean}
 */
const validateString = (string) => {
  const re = /^[a-zа-яё\s]+$/iu;
  return re.test(string);
}

/**
 * Валидация поля компании, если не проходит, то добавляем класс ошибки
 * @param input
 * @return {boolean}
 */
const checkStringField = (input) => {
  if (!validateString(input.value)) {
    input.parentNode.parentNode.querySelector(FORM_SELECTORS.errorField).innerText = 'Поле не должно содержать цифры';
  } else if (input.value.length === '') {
    input.parentNode.parentNode.querySelector(FORM_SELECTORS.errorField).innerText = 'Поле не должно быть пустым';
  } else {
    input.parentNode.parentNode.querySelector(FORM_SELECTORS.errorField).innerText = 'Поле обязательно для заполнения';
  }

  if (!validateString(input.value) || input.value.length < 2 || input.value.length > 76) {
    input.parentNode.parentNode.classList.add(FORM_STATE.error);
  } else {
    input.parentNode.parentNode.classList.remove(FORM_STATE.error);
  }

  return validateString(input.value);
}

/**
 * Валидация поля электронной почты, если не проходит, то добавляем класс ошибки
 * @param input
 * @return {boolean}
 */
const checkEmailField = (input) => {
  if (input.value === '') {
    input.parentNode.parentNode.querySelector(FORM_SELECTORS.errorField).innerText = 'Поле обязательно для заполнения';
  } else {
    input.parentNode.parentNode.querySelector(FORM_SELECTORS.errorField).innerText = 'Неверно введен e-mail';
  }

  if (!validateEmail(input.value) || input.value === '') {
    input.parentNode.parentNode.classList.add(FORM_STATE.error);
  } else {
    input.parentNode.parentNode.classList.remove(FORM_STATE.error);
  }

  return validateEmail(input.value);
}

/**
 * Валидация поля телефона, если не проходит, то добавляем класс ошибки
 * 9, 8, 4, 3 - допустимые цифры после +7
 * @param input
 * @return {boolean}
 */
const checkPhoneField = (input) => {
  const phoneTypeSymbol = input.value.split('')[4];
  let error = true;

  if (input.value === '') {
    input.parentNode.parentNode.querySelector(FORM_SELECTORS.errorField).innerText = 'Поле обязательно для заполнения';
  } else {
    input.parentNode.parentNode.querySelector(FORM_SELECTORS.errorField).innerText = 'Неверно введен телефон';
  }

  if (!validatePhone(input.value) || input.value === '' || (phoneTypeSymbol != 9 && phoneTypeSymbol != 8 && phoneTypeSymbol != 4 && phoneTypeSymbol != 3)) {
    error = false;
    input.parentNode.parentNode.classList.add(FORM_STATE.error);
  } else {
    input.parentNode.parentNode.classList.remove(FORM_STATE.error);
  }

  return error;
}

// Максимальное количество символов - 200

/**
 * Валидация поля комментарий, если не проходит, то добавляем класс ошибки
 * @param input
 * @return {boolean}
 */
const checkCommentField = (textarea) => {
  let error = true;

  if (textarea.value.length > 200) {
    textarea.parentNode.parentNode.querySelector(FORM_SELECTORS.errorField).innerText = 'Максимальное количество символов превышено';
  } else {
    textarea.parentNode.parentNode.querySelector(FORM_SELECTORS.errorField).innerText = 'Поле не должно быть пустым';
  }

  if (textarea.classList.contains('js-input-type-comment-required')) {
    if (textarea.value.length > 200 || textarea.value.length === 0) {
      error = false;
      textarea.parentNode.parentNode.classList.add(FORM_STATE.error);
    } else {
      textarea.parentNode.parentNode.classList.remove(FORM_STATE.error);
    }
  } else {
    if (textarea.value.length > 500) {
      error = false;
      textarea.parentNode.parentNode.classList.add(FORM_STATE.error);
    } else {
      textarea.parentNode.parentNode.classList.remove(FORM_STATE.error);
    }
  }

  return error;
}

/**
 * Проверка, загружен ли файл
 * @param {HTMLElement} fileContainer - html блок с input[type="file"]
 */
const checkFileField = (file) => {
  const description = document.querySelector(FORM_SELECTORS.fileDescription);
  let error = true;

  if (file.value === '') {
    error = false;

    description.classList.add(FORM_STATE.error);
    description.innerHTML = FILE_UPLOAD_TEXT.error;
  } else {
    description.classList.remove(FORM_STATE.error);
    description.innerHTML = FILE_UPLOAD_TEXT.default;
  }

  return error;
}

/**
 * Валидация чекбоксов
 * @param input
 * @return {boolean}
 */
const validateCheckbox = (input) => {
  let error = true;

  if (input.checked) {
    input.classList.remove(FORM_STATE.error);
  } else {
    error = false;
    input.classList.add(FORM_STATE.error);
  }

  return error;
}

/**
 * Проверка полей формы при потере фокуса
 * @param {HTMLElement} form - нода формы
 * @param {HTMLElement} formSubmit - нода кнопки отправки формы
 */
const checkFieldOnOutFocus = (form, formSubmit) => {
  // Получаем все input/textarea элементы формы
  const inputs = form.querySelectorAll(`${FORM_SELECTORS.input} input`);
  const checkboxArr = Array.from(form.querySelectorAll(`${FORM_SELECTORS.checkbox}`));
  const textarea = form.querySelector(`${FORM_SELECTORS.input} textarea`);
  const file = form.querySelector(`${FORM_SELECTORS.fileContainer} input`);

  // включает/отключает кнопку отправки формы
  const checkAndToggleSubmit = (input, checkFunction) => {
    checkFunction(input)
        ? formSubmit.removeAttribute("disabled")
        : formSubmit.setAttribute("disabled", "disabled");
  };

  // Для каждого input элемента добавляем обработчик события "focusout"
  inputs.forEach((input) => {
    input.addEventListener("focusout", () => {
      // Проверяем и переключаем состояние кнопки отправки формы в зависимости от типа input поля
      if (input.classList.contains("js-input-type-phone")) {
        checkAndToggleSubmit(input, checkPhoneField);
      } else if (input.classList.contains("js-input-type-email")) {
        checkAndToggleSubmit(input, checkEmailField);
      } else if (input.classList.contains("js-input-type-default")) {
        checkAndToggleSubmit(input, checkStringField);
      }
    });
  });

  checkboxArr.forEach((input) => {
    input.addEventListener("focusout", () => {
      checkAndToggleSubmit(input, validateCheckbox);
    });
  });

  if (textarea) {
    textarea.addEventListener("focusout", () => {
      if (textarea.classList.contains("js-input-type-comment")) {
        checkAndToggleSubmit(textarea, checkCommentField);
      }
    });
  }

  if (file) {
    file.addEventListener("change", () => {
      checkAndToggleSubmit(file, checkFileField);
    })
  }
};

/**
 * Полная валидация формы (используется перед отправкой)
 * @param form - нода формы
 * @return {boolean}
 */
const validateForm = (form) => {
  const inputs = form.querySelectorAll(`${FORM_SELECTORS.input} input`);
  const checkboxArr = Array.from(form.querySelectorAll(`${FORM_SELECTORS.checkbox}`));
  const textarea = form.querySelector(`${FORM_SELECTORS.input} textarea`);
  const file = form.querySelector(`${FORM_SELECTORS.fileContainer} input`);

  const validFieldsStatus = [];
  let isValid = true;

  inputs.forEach((input) => {
    if (input.classList.contains('js-input-type-phone')) {
      checkPhoneField(input);
      validFieldsStatus.push(checkPhoneField(input));
    }
    if (input.classList.contains('js-input-type-email')) {
      checkEmailField(input);
      validFieldsStatus.push(checkEmailField(input));
    }
    if (input.classList.contains('js-input-type-default')) {
      checkStringField(input);
      validFieldsStatus.push(checkStringField(input));
    }
  });

  checkboxArr.forEach((input) => {
    validateCheckbox(input);
    validFieldsStatus.push(validateCheckbox(input));
  });

  if (textarea) {
    if (textarea.classList.contains('js-input-type-comment')) {
      checkCommentField(textarea);
      validFieldsStatus.push(checkCommentField(textarea));
    }
  }

  if (file) {
    checkFileField(file);
    validFieldsStatus.push(checkFileField(file));
  }

  if (validFieldsStatus.includes(false)) {
    isValid = false;
  }

  return isValid;
}

/**
 * Полная валидация формы (используется перед отправкой)
 * @param form - нода фломы
 * @return {object}
 */
const serializeForm = (form) => {
  return new FormData(form);
}

/**
 * Определение фрагмента эндпойнта отправляемой формы
 * @param {Element} form - нода формы
 */
const checkEndPoint = (form) => {
  // Если в форме установлен атрибут action, возвращаем его значение
  if (form.hasAttribute("action") && form.action.trim() !== "") {
    return form.action;
  }

  // Иначе, используем ранее определенные конечные точки
  const formClassNamesArr = Object.values(FORM_CLASSNAMES);
  const formCurrClass = Array.from(form.classList).find(item => formClassNamesArr.includes(item));
  return `/ajax/${FORM_ENPOINTS[Object.keys(FORM_CLASSNAMES)[formClassNamesArr.indexOf(formCurrClass)]]}.php`;
}

/**
 * Уведомление об ошибке
 * @param {Element} btn - html-элемент кнопки
 * @param {string} message - текст уведомления
 */
const showFormAlert = (btn, message) => {
  alertHandler.showAlert(message);
  btn.setAttribute('disabled', 'disabled');
}

/**
 * Валидация и отправка формы
 */
const sendForm = () => {
  if (!document.querySelectorAll(FORM_SELECTORS.form).length) { return false; }

  const forms = document.querySelectorAll(FORM_SELECTORS.form);

  forms.forEach((form) => {
    const formContent = form.parentNode.querySelector(FORM_SELECTORS.formContent);
    const formContentSuccess = form.parentNode.querySelector(FORM_SELECTORS.formSuccess);
    const formSubmit = form.querySelector(FORM_SELECTORS.sendBtn);
    formSubmit.setAttribute('data-badge', 'inline');
  
    form.addEventListener('submit', async (e) => {
      e.preventDefault();
      formSubmit.setAttribute('disabled', 'disabled');

      let token = null;
      const formData = serializeForm(form);
      const validate = validateForm(form);

      try {
        token = await grecaptcha.execute(window.grecaptcha_key, { action: 'submit' });
      } catch (error) {
        console.error(error);
        alertHandler.showAlert('Ошибка получения токена reCaptcha');
      }

      if(token !== null) {
        formData.append('token', token);
      }
  
      if (!validate) {
        console.error('Form is invalid!');
      } else {
        try {
          await axios({
            method: 'post',
            url: checkEndPoint(form),
            data: formData,
          }).then(({ data }) => {
            const { status, message } = data;
  
            // Контент после отправки формы
            if (['ok', 'success'].includes(status)) {
              formContent.classList.add(FORM_STATE.hide);
              formContentSuccess.classList.remove(FORM_STATE.hide);
              form.classList.add(FORM_STATE.hide);
              alertHandler.hideAlert();
            } else {
              showFormAlert(formSubmit, message);
            }
          });
        } catch (error) {
          console.error(error);
          alertHandler.showAlert(error.message);
        }
      }
    });
  
    Utils.phoneMask();
    checkFieldOnOutFocus(form, formSubmit);
  });
}

export default sendForm;
