import axios from 'axios';
import CatalogItem from "./catalog-item";
import Observer from 'common/scripts/observer';

const commonObserver = new Observer();

/**
 * Помечает кнопку как ожидающую действия.
 * @param {HTMLElement} btn - Кнопка, которую нужно пометить как ожидающую.
 */
export function waitStart(btn) {
    btn.setAttribute("data-disabled", "true");
    btn.style.opacity = 0.3;
}

/**
 * Снимает пометку ожидания с кнопки.
 * @param {HTMLElement} btn - Кнопка, с которой нужно снять пометку ожидания.
 */
export function waitFinish(btn) {
    btn.style.opacity = "";
    btn.removeAttribute("data-disabled");
}

/**
 * Проверяет, ожидает ли кнопка действия.
 * @param {HTMLElement} btn - Кнопка, которую нужно проверить.
 * @return {boolean} Возвращает true, если кнопка ожидает действия, иначе false.
 */
export function isWaiting(btn) {
    return btn.getAttribute("data-disabled") === "true";
}

/**
 * @description Обновляет число товаров в мини-корзине (метод мини-корзины, зависимость для vue-корзины)
 * @param {Object} data - данные о корзине
 */
export function updateBasketCount(data) {
    const cartElementCounter = document.querySelectorAll('.j-cart-count');
    const { products } = data.data;
    const productsCount = products.reduce((acc, product) => acc + product.quantity, 0);

    cartElementCounter.forEach((el) => {
        el.textContent = productsCount.toString();

        if (productsCount > 0) {
            if (!el.classList.contains('is-active')) {
                el.classList.add('is-active');
            }
        } else {
            el.classList.remove('is-active');
        }
    })
}

/**
 * Удаляет товар из мини-корзины (метод мини-корзины, зависимость для vue-корзины)
 * @param {Event} event - Объект события
 * @param {HTMLElement} deleteFromBasketElement - Элемент, который удаляем из корзины
 * @param {Object} rootInstance - объект смонтированного vue-компоента заказа
 */
function deleteFromMiniBasket(event, deleteFromBasketElement, rootInstance = null) {
    event.preventDefault();
    const btn = deleteFromBasketElement;

    if (isWaiting(btn)) return;
    waitStart(btn);

    const productID = btn.getAttribute('data-product');
    const payload = {
        basket: productID,
        action: 'delete'
    };

    try {
        axios.post('/ajax/basketActionApi.php', payload)
        .then((response) => {
            if(rootInstance) {
                // TODO: найти более правильное решение позже -
                // setProducts вызывает ещё один get-запрос для обновления данных
                // во vue-компоненте оформления заказа.
                // Т.о., на той стр. запросов в 2 р. больше, чем нужно, при удалении товаров из мини-корзины.
                rootInstance.setProducts();
            }

            updateBasketCount(response.data);
            // Обновление мини-корзины
            commonObserver.publish('updateCart', response.data.data);
        })
        .catch((error) => {
            console.error('Ошибка при удалении товара:', error);
            commonObserver.publish('showAlert', 'Произошла ошибка, попробуйте чуть позже');
        })
        .finally(() => {
            waitFinish(btn);
        })
    } catch (error) {
        console.error('Ошибка при удалении товара:', error);
    }
}

/**
 * Инициализирует функционал удаления товаров из корзины
 * @param {Object} rootInstance - объект смонтированного vue-компоента заказа
 */
export function initDeleteFromMiniBasket(rootInstance = null) {
    document.addEventListener('click', (event) => {
        const deleteFromBasketElement = event.target.closest('.j-remove-from-basket');

        if (deleteFromBasketElement) {
            deleteFromMiniBasket(event, deleteFromBasketElement, rootInstance);
        }
    });
}

/**
 * Инициализация продуктовых карточек
 * @param {Array} productCards  - массив с карточками
 */
export function initProductCards(productCards) {
    //console.log(productCards);
    productCards.forEach(card => {
        //initProductCardActions(card);
        let catalogItem = new CatalogItem(card);
    });
}

/**
 * Функция установки get параметра в url
 * @param {String} option - название url параметра
 * @param {Any} value - значение url параметра
 * @returns
 */
function setOptionToGetParam(option, value) {
    if (!window.URLSearchParams) return;

    const url = new URL(window.location);
    url.searchParams.set(option, value);

    // Обновление url'а
    window.history.pushState(null, '', url.toString());
    window.location.reload();
}

/**
 * Функция инициализации фильтра объема (л)
 */
export function initCatalogVolumeFilter() {
    const filter = document.querySelector('.js-catalog-volume-filter');
    if (!filter) return;

    filter.addEventListener('click', (e) => {
        const target = e.target.closest('[data-volume]');

        if (target) {
            setOptionToGetParam('volume', target.dataset.volume);
        }
    });
}

/**
 * Функция инициализации фильтра сортировки
 */
export function initCatalogSortFilter() {
    const filter = document.querySelector('.js-catalog-sort-filter');
    if (!filter) return;

    filter.addEventListener('click', (e) => {
        const target = e.target.closest('[data-sort]');

        if (target) {
            setOptionToGetParam('sort', target.dataset.sort);
        }
    });
}

/**
 * Функция инициализации фильтра специальных предложений
 */
export function initCatalogDiscountFilter() {
    const filter = document.querySelector('.js-catalog-discount-filter');
    if (!filter) return;

    filter.addEventListener('click', () => {
        setOptionToGetParam('special', 'true');
        filter.classList.toggle('is-active');
    });
}

/**
 * Обновляет иконки лайков на рекомендуемых товарах в корзине
 * @param {Array} arr - массив id товаров в корзине
 */
export function toggleFav(arr) {
    const {
        cardClassName,
        btnClassName,
        btnAddClassName,
        btnRemoveClassName,
        activeClassName
    } = {
        cardClassName: 'js-catalog-card',
        btnClassName: 'catalog-card__favorite',
        btnAddClassName: 'j-add-to-favorite',
        btnRemoveClassName: 'j-remove-from-favorite',
        activeClassName: 'is-active',
    }
    const cards = Array.from(document.querySelectorAll(`.${cardClassName}`));
    const idsArr = [...cards.map(item => item.querySelector(`.${btnClassName}`).dataset.product)];

    const btnClassList = (btn) => btn.classList;

    idsArr.forEach((item, index) => {
        const btn = cards[index].querySelector(`.${btnClassName}`);

        if(arr.includes(item)) {
            btnClassList(btn).remove(btnAddClassName);
            [btnRemoveClassName, activeClassName].map(className => btnClassList(btn).add(className));
        } else {
            btnClassList(btn).add(btnAddClassName);
            [btnRemoveClassName, activeClassName].map(className => btnClassList(btn).remove(className));
        }
    });
}
