<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M20.8333 22C18.4444 22 16.1159 21.4674 13.8478 20.4022C11.5789 19.3378 9.56963 17.9304 7.82 16.18C6.06963 14.4304 4.66222 12.4211 3.59778 10.1522C2.53259 7.88407 2 5.55556 2 3.16667C2 2.83333 2.11111 2.55556 2.33333 2.33333C2.55556 2.11111 2.83333 2 3.16667 2H7.66667C7.92593 2 8.15741 2.08333 8.36111 2.25C8.56482 2.41667 8.68519 2.62963 8.72222 2.88889L9.44444 6.77778C9.48148 7.03704 9.47704 7.27296 9.43111 7.48556C9.38444 7.69889 9.27778 7.88889 9.11111 8.05556L6.44444 10.7778C7.22222 12.1111 8.19444 13.3611 9.36111 14.5278C10.5278 15.6944 11.8148 16.7037 13.2222 17.5556L15.8333 14.9444C16 14.7778 16.2178 14.6526 16.4867 14.5689C16.7548 14.4859 17.0185 14.463 17.2778 14.5L21.1111 15.2778C21.3704 15.3333 21.5833 15.4581 21.75 15.6522C21.9167 15.847 22 16.0741 22 16.3333V20.8333C22 21.1667 21.8889 21.4444 21.6667 21.6667C21.4444 21.8889 21.1667 22 20.8333 22Z" fill="none"/>
  </svg>
</template>

<script>
export default {
  name: 'icon-phone'
};
</script>
