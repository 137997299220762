<template>
    <div class="v-input">
        <div
            class="v-input__wrapper"
            :class="{
                'v-input__wrapper_error': isError,
                'v-input__wrapper_focused': isFocusedInput,
                'v-input__wrapper_disabled': isDisabled,
                'v-input__wrapper_not-allowed-input': !isAllowedInput
            }"
            @click.stop="clickInput"
        >
          <div v-if="!isAllowedInput" class="v-input__overmask"></div>
            <span
                v-if="$slots['left-icon']"
                class="v-input__icon v-input__icon_left"
            >
                <slot name="left-icon"></slot>
            </span>
            <span
                v-if="isFocusedInput || modelValue"
                class="v-input__label"
            >
                {{ placeholder }}
            </span>
            <textarea
                v-if="textarea"
                :value="modelValue"
                :disabled="isDisabled"
                :placeholder="isFocusedInput ? '' : placeholder"
                :maxlength="maxLength"
                class="v-input__textarea"
                @focus="focus"
                @blur="blur"
                @input="input"
            />
            <input
                v-else
                :value="modelValue"
                :disabled="!isAllowedInput || isDisabled"
                :placeholder="isFocusedInput ? '' : placeholder"
                :maxlength="maxLength"
                :type="type"
                :id="additionalId"
                :autocomplete="autocomplete"
                v-imask="mask"
                class="v-input__input"
                @focus="focus"
                @blur="blur"
                @input="input"
            />
            <span
                v-if="$slots['right-icon']"
                class="v-input__icon v-input__icon_right"
            >
                <slot name="right-icon"></slot>
            </span>
            <button
                v-if="isShowPassButton"
                class="v-input-btn"
                type="button"
                @click="togglePassVisibility"
            >
                <icon-eye-close
                    v-if="!showToggleBtn"
                />
                <icon-eye v-else />
            </button>
        </div>
        <p
            v-if="hint"
            :class="['v-input__hint', { 'v-input__hint_error': isError }]"
        >
            {{ hint }}
        </p>
    </div>
</template>

<script>
import {IMaskDirective} from 'vue-imask';
import IconEyeClose from '../../assets/icons/icon-eye-close.vue';
import IconEye from '../../assets/icons/icon-eye.vue';

export default {
    name: 'v-input',
    components: {
        IconEye,
        IconEyeClose
    },
    directives: {
        imask: IMaskDirective
    },
    props: {
        modelValue: {
            type   : String,
            default: ''
        },
        placeholder: {
            type   : String,
            default: ''
        },
        textarea: {
            type   : Boolean,
            default: false
        },
        hint: {
            type   : String,
            default: ''
        },
        isAllowedInput: {
            type   : Boolean,
            default: true
        },
        isDisabled: {
            type   : Boolean,
            default: false
        },
        isError: {
            type   : Boolean,
            default: false
        },
        inputType: {
            type   : String,
            default: 'text',
            validate(value) {
                return ['text', 'tel', 'name', 'email', 'password', 'sms', 'date'].includes(value);
            }
        },
        type: {
            type   : String,
            default: ''
        },
        additionalId: {
            type   : String,
            default: ''
        },
        autocomplete: {
            type   : String,
            default: ''
        }
    },
    data() {
        return {
            showToggleBtn: false,
            isFocusedInput: false,
            hasError: false
        };
    },
    computed: {
        mask() {
            if (!this.isFocusedInput) {
                return '';
            }

            if (this.inputType === 'tel') {
                return {mask: '{+7} (000) 000-00-00'};
            }

            if (this.inputType === 'name') {
                return {mask: new RegExp(/^[а-яА-ЯёЁa-zA-Z\s]*$/)};
            }

            if (this.inputType === 'sms') {
                return {mask: '0000'};
            }

            if (this.inputType === 'date') {
                return {
                    mask: Date,
                    max: new Date(),
                };
            }

            return '';
        },
        maxLength() {
            if (this.inputType === 'tel') {
                return 18;
            } else if (this.inputType === 'name' || this.inputType === 'sname') {
              return 76;
            } else if (this.inputType === 'sms') {
                return 4;
            } else if (this.inputType === 'date') {
                return 10;
            } else if (this.inputType === 'comment') {
                return 800;
            }

            return null;
        },
        isShowPassButton() {
            return this.inputType === 'password' && this.modelValue.length > 0 ? true : false;
        }
    },
    methods: {
        // validateInput(event) {
        //   console.log('validateInput')
        //   const value = event.target.value;
        //
        //   const regex = /^[а-яА-ЯёЁa-zA-Z\s]*$/;
        //
        //   if (!regex.test(value) && (event.target.type === 'name' || event.target.type === 'sname')) {
        //     console.log('validateInput NAME')
        //
        //     const validValue = value.replace(/[^а-яА-ЯёЁa-zA-Z\s]/g, '');
        //
        //     event.target.value = validValue;
        //
        //     this.$emit('update:modelValue', validValue);
        //   } else if (event.target.type === 'date') {
        //     console.log('validateInput DATE')
        //
        //     // проверка даты рождения
        //     const enteredDate = new Date(value);
        //     const currentDate = new Date();
        //
        //     // проверка, что введенная дата меньше или равна текущей дате
        //     if (enteredDate > currentDate) {
        //       // Если дата больше текущей, очищаем поле ввода
        //       event.target.value = '';
        //
        //       this.$emit('update:modelValue', '');
        //     }
        //   }
        // },
        togglePassVisibility(el) {
            const passwordInput = el.target.closest('.v-input').querySelector('.v-input__input');

            if (passwordInput.type === 'password') {
                passwordInput.type = 'text';
            } else {
                passwordInput.type = 'password';
            }

            this.showToggleBtn = !this.showToggleBtn;
        },
        clickInput() {
            if (this.isAllowedInput || this.isDisabled) {
                return;
            }
            this.$emit('click');
        },
        input(event) {
            // this.validateInput(event);

            const value = event.target.value.trim();

            this.$emit('update:modelValue', value);
            this.isShowPassButton;
        },
        focus() {
            this.isFocusedInput = true;
        },
        blur() {
            this.$emit('blur');
            this.isFocusedInput = false;
        }
    }
};
</script>
