const SELECT_SELECTORS = {
  container: ".js-select-container",
  label: ".js-select-label",
  field: ".js-select-field",
  text: ".js-select-text",
  option: ".js-select-item",
};

const SELECT_STATE = {
  active: "is-active",
  empty: "not-empty",
};

/**
 * Удаление класса у всех элементов
 * @param elements {NodeList}
 * @param className {String}
 */
const removeClassFromAllElements = (elements, className) => {
  elements.forEach((el) => {
    el.classList.remove(className);
  });
};

/**
 * Функция инициализации селектов
 */
const initCustomSelect = () => {
  const selectContainers = document.querySelectorAll(SELECT_SELECTORS.container);
  if (!selectContainers.length) return;

  selectContainers.forEach((container) => {
    const selectItems = Array.from(container.querySelectorAll(SELECT_SELECTORS.option));
    const selectField = container.querySelector(SELECT_SELECTORS.field);
    const selectText = selectField.querySelector(SELECT_SELECTORS.text);
    const selectLabel = container.querySelector(SELECT_SELECTORS.label);

    selectField.classList.toggle(SELECT_STATE.empty, selectText.textContent.length > 0);

    selectField.addEventListener("click", () => {
      container.classList.toggle(SELECT_STATE.active);
    });

    selectItems.forEach((item) => {
      item.addEventListener("click", () => {
        removeClassFromAllElements(selectItems, SELECT_STATE.active);
        item.classList.add(SELECT_STATE.active);
        selectText.textContent = item.textContent;
        selectField.classList.add(SELECT_STATE.empty);
        container.classList.remove(SELECT_STATE.active);
        selectLabel.remove();
      });
    });
  });

  document.body.addEventListener("click", (event) => {
    if (!event.target.closest(SELECT_SELECTORS.container)) {
      removeClassFromAllElements(selectContainers, SELECT_STATE.active);
    }
  });
};

export default initCustomSelect;
