import VAuth from '../../../components/auth-modal/auth-modal.vue';
import {createApp} from 'vue';
import store from '../../../store/index';

const SELECTORS = {
  popupOpenBtn: '.j-vue-popup',
}

const initVuePopup = () => {
  const btns = document.querySelectorAll(SELECTORS.popupOpenBtn);

  if (!btns.length) return;

  btns.forEach((btn) => {
    btn.addEventListener('click', () => {
      if (!document.querySelector('#auth-modal')) {
        const popupContainer = document.createElement('div');
        popupContainer.id = 'auth-modal';
        document.body.appendChild(popupContainer);
    
        if (popupContainer) {
          const authModalApp = createApp(VAuth, {isShow: true});
          authModalApp.use(store);
          authModalApp.mount('#auth-modal');
        }
      }
    });
  });
}

export default initVuePopup;
