const SELECTORS = {
  container: ".js-text-content",
  buttonMore: ".js-more-text"
}

const STATE = {
  show: 'is-show',
  hide: 'is-hide',
  desktop: 1280,
  laptop: 1024,
  tablet: 768,
  height: {
    mobile: 168,
    tablet: 72,
    laptop: 96,
    desktop: 72
  }
}

const STATE_TEXTS = {
  textShow: 'Читать все',
  textHide: 'Скрыть'
}

/**
 * Функция определения устройства
 */
const detectDevice = () => {
  const windowWidth = window.innerWidth;

  if (windowWidth >= STATE.desktop) {
    return 'desktop';
  } else if (windowWidth >= STATE.laptop) {
    return 'laptop';
  } else if (windowWidth >= STATE.tablet) {
    return 'tablet';
  }

  return 'mobile';
}

/**
 * Функция установки максимальной высоты
 * @param {HTMLElement} elem - элемент
 * @param {Number} height - значение высоты
 */
const setHeightContent = (elem, height) => {
  elem.style.maxHeight = `${height}px`;
}

/**
 * Функция инициализации функционала показать/скрыть весь текст
 */
const initToggleMoreText = () => {
  const content = document.querySelector(SELECTORS.container);

  if (!content) return;

  const contentHeight = content.offsetHeight;
  const button = content.parentNode.querySelector(SELECTORS.buttonMore);

  if (contentHeight <= STATE.height[`${detectDevice()}`] || !button) return;

  content.classList.add(STATE.hide);
  setHeightContent(content, STATE.height[`${detectDevice()}`]);

  const handleClick = () => {
    const buttonText = button.querySelector('span');

    if (button.classList.contains(STATE.show)) {
      setHeightContent(content, STATE.height[`${detectDevice()}`]);
      content.classList.add(STATE.hide);
      button.classList.remove(STATE.show);
      buttonText.innerText = STATE_TEXTS.textShow;
    } else {
      setHeightContent(content, contentHeight);
      content.classList.remove(STATE.hide);
      button.classList.add(STATE.show);
      buttonText.innerText = STATE_TEXTS.textHide;
    }
  }

  button.addEventListener('click', handleClick);
}

export default initToggleMoreText;
