import Observer from '../../common/scripts/observer';

/**
 * Класс представляющий обработчик алертов.
 */
class AlertHandler {
    constructor() {
        this.alertWrapper = document.querySelector('.j-alert');
        this.alertMessage = document.querySelector('.j-alert-message');
        this.alertClose = document.querySelector('.j-alert-close');

        this.alertClose.addEventListener('click', this.hideAlert.bind(this));

        // Подписываемся 'showAlert'
        const observer = new Observer();
        observer.subscribe('showAlert', this.showAlert.bind(this));
    }

    /**
     * Отображает алерт с заданным сообщением.
     * @param {string} message - Сообщение, которое будет отображаться в алерте.
     */
    showAlert(message) {
        this.alertMessage.textContent = message;
        this.alertWrapper.classList.add('is-active');

        //таймер на 10 секунд
        setTimeout(() => {
            this.hideAlert();
        }, 10000);
    }

    /**
     * Скрывает алерт.
     */
    hideAlert() {
        this.alertWrapper.classList.remove('is-active');
    }
}

export default AlertHandler;
