import axios from 'axios';
import Utils from "common/scripts/utils";
import newsCard from '../../../components/card/card.twig';
import Observer from "common/scripts/observer";
const commonObserver = new Observer();

/**
 * @description Получение новостей с сервера
 * @param {string} filter - Фильтр для новостей
 * @param {string} sort - Параметр сортировки новостей
 * @param {string} query - Поисковый запрос с инпута
 * @param page {number} - страница
 * @return {Promise<Array>} Promise, с массивом новостей
 */
async function fetchNews(filter = 'all', sort = 'date', query = '', page = 1) {
    try {
        const response = await axios.get('/crm/news/', {
            params: {
                filter,
                sort,
                q: query,
                page,
            },
        });
        const { paginatedList } = response.data.data;

        if(!paginatedList.list.length) {
            commonObserver.publish('showAlert', 'По вашему запросу ничего не найдено');
        }
        return response.data.data;
    } catch (error) {
        commonObserver.publish('showAlert', 'Произошла ошибка, попробуйте еще раз');
        console.error('Ошибка при получении новостей:', error);
    }
}

/**
 * Отображение новостей на странице
 * @param {Array} news - массив новостей
 * @param {HTMLElement} container - контейнер, в который вставляются новости
 * @param clear {boolean} - нужно ли очищать или нет
 */
function displayNews(news, container, clear = true) {
    console.log(news)
    if (clear) {
        // Очищаем контейнер новостей
        Utils.clearHtml(container);
    }
    // Вставляем новые новости в контейнер
    news.forEach((item) => {
        Utils.insetContent(container, newsCard(item));
    });
}

export { fetchNews, displayNews };
