/**
 * Константы для селекторов попапа и состояний
 * @typedef {Object} PopupSelectors
 * @property {string} container - Селектор контейнера попапа
 * @property {string} popupLink - Селектор ссылки для открытия попапа
 * @property {string} popupCloseButton - Селектор кнопки закрытия попапа
 */

/** @type {PopupSelectors} */
const POPUP_SELECTORS = {
  container: '.js-m-popup',
  popupLink: '.js-m-popup-link',
  popupCloseButton: '.js-m-popup-close'
};

/**
 * Состояния попапа
 * @typedef {Object} PopupState
 * @property {string} show - Класс для отображения попапа
 * @property {string} disableScroll - Класс для блокировки прокрутки страницы
 */

/** @type {PopupState} */
const STATE = {
  show: 'is-show',
  disableScroll: 'is-disable-scroll'
};

/**
 * Закрытие попапа
 * @param {NodeList} closeButtons - Кнопки закрытия
 * @param {HTMLElement} popupContainer - Контейнер попапа
 */
const closePopup = (closeButtons, popupContainer) => {
  /**
   * Обработчик события клика для кнопки закрытия
   * @param {Event} event - Событие клика
   */
  const closeHandler = (event) => {
    popupContainer.classList.remove(STATE.show);
    document.body.classList.remove(STATE.disableScroll);
  };

  closeButtons.forEach((btn) => {
    btn.addEventListener('click', closeHandler);
  });
};

/**
 * Инициализация попапа
 */
const initPopup = () => {
  /** @type {NodeListOf<HTMLElement>} */
  const openLinks = document.querySelectorAll(POPUP_SELECTORS.popupLink);

  if (openLinks.length < 1) return;

  openLinks.forEach((link) => {
    /**
     * Обработчик события клика для ссылки открытия попапа
     * @param {Event} e - Событие клика
     */
    const clickHandler = (e) => {
      e.preventDefault();

      const id = link.getAttribute('data-href');
      const popupContainer = document.querySelector(id);

      if (popupContainer) {
        popupContainer.classList.add(STATE.show);
        document.body.classList.add(STATE.disableScroll);

        const closeBtn = popupContainer.querySelectorAll(POPUP_SELECTORS.popupCloseButton);
        closePopup(closeBtn, popupContainer);
      } else {
        console.log('Разметка с модальным окном не найдена.');
      }
    };

    link.addEventListener('click', clickHandler);
  });
};

/** @type {HTMLElement} */
const alertPhonePopup = document.querySelector(".j-alert-phone-popup");
if (alertPhonePopup) {
  setTimeout(() => {
    alertPhonePopup.classList.add(STATE.show)
    const closeBtn = alertPhonePopup.querySelectorAll(POPUP_SELECTORS.popupCloseButton);
    closePopup(closeBtn, alertPhonePopup);
  }, 3000);
}

export default initPopup;
