import AlertHandler from '../../../components/alert/alert';
import axios from 'axios';

const alertHandler = new AlertHandler();
const ERRORS = {
    orderParamsError : 'Не удалось установить параметры для повторного заказа',
    orderHandlerError: 'Не удалось получить данные повторного заказа'
};

/**
 * Запись данных заказа в локальное хранилище
 * @param {Object} data - данные последнего заказа
 */
const setOrderParams = (data) => {
    const keys = Object.keys(data);
    const isOrderDataExist = () => keys.reduce((acc, key) => acc && localStorage.getItem(key) !== null, true);
    const setStorageItems = () => Object.entries(data).forEach((item) => localStorage.setItem(item[0], item[1]));

    if (isOrderDataExist()) {
        keys.forEach((key) => {
            localStorage.removeItem(key);
        });
        setStorageItems();
    } else {
        setStorageItems();
    }

    return new Promise((resolve, reject) => {
        setTimeout(() => {
            isOrderDataExist()
                ? resolve({
                    isSucceed: isOrderDataExist(),
                    data: keys.reduce((acc, key) => ({...acc, [key]: '' || localStorage.getItem(key)}), {})
                })
                : reject({message: ERRORS.orderParamsError});
        }, 500);
    });
};

/**
 * Обработка данных последнего заказа
 * @param {Object} urlData - данные для редиректа
 * @param {Object} orderData - данные последнего заказа
 */
const handleOrderParams = async({urlData, orderData}) => {
    const {btn, url} = urlData;
    const {user_comment: userComment, properties} = orderData;

    btn.disabled = true;

    try {
        const data = await setOrderParams({
            address: properties.ADDRESS,
            addressCode: properties.CUSTOM_ADDRESS || '',
            name: properties.CONTACT_PERSON,
            phone: properties.PHONE,
            userComment
        });
        console.log(data);

        if (data.isSucceed) {
            window.location.href = url;
        } else {
            console.error(data);
        }
    } catch (error) {
        console.error(error.message);
    } finally {
        btn.disabled = false;
    }
};

/**
 * Получение данных последнего заказа
 * @param {HTMLElement} urlData.btn - элемент кнопки
 * @param {string} urlData.url - ссылка для редиректа при копировании заказа 
 */
const fetchOrder = async(urlData) => {
    try {
        const {data} = await axios.get('/ajax/lastOrder.php');

        handleOrderParams({
            urlData,
            orderData: data.order
        });
    } catch (error) {
        console.error(error);
        alertHandler.showAlert(ERRORS.orderHandlerError);
    }
};

/**
 * Повторение последнего заказа по клику на кнопку в модальном окне
 */
const copyOrder = () => {
    const btnCopyOrder = document.querySelector('.js-copy-order');

    if (!btnCopyOrder) {
        return;
    }

    btnCopyOrder.addEventListener('click', (event) => {
        event.preventDefault();

        const btn = event.target;
        const {href: url} = btn.dataset;

        fetchOrder({btn, url});
    });
};

export {
    copyOrder
};
