import axios from 'axios';
import { updateBasketCount } from '../../components/catalog';
import { updateFavoriteCount } from '../../common/scripts/modules/add-to-favorite';
import Observer from '../../common/scripts/observer';
import AlertHandler from '../../components/alert/alert';
import Utils from 'common/scripts/utils';
import { getOrderParams } from '../../common/scripts/modules/auth-handler';

const commonObserver = new Observer();
const alertHandler = new AlertHandler();
const ERROR_MESS = 'Произошла ошибка. Повторите попытку позже.';
const CART_FETCH_ERROR_MESS = 'Не удалось получить данные корзины';
const FAV_FETCH_ERROR_MESS = 'Не удалось получить список избранного';
const CART_UPDATE_ERROR_MESS = 'Не удалось обновить список товаров корзины';
const FAV_UPDATE_ERROR_MESS = 'Не удалось обновить список избранного';
const debounceTimers = {}; // Объект для хранения таймеров

export const cart = {
    namespaced: true,
    state() {
        return {
            promotions: [],
            activePromo: null,
            products: [],
            favourites: [],
            inventory: {},
            inventoryData: {
                desc: '',
                caption: '',
                hint: '',
            },
            totalCartData: {
                count: 0,
                finalSumm: 0,
                summ: 0,
                discount: 0,
                minPrice: 0,
                minPriceDiff: 0,
            },
            itemLoading: false,
        };
    },
    getters: {
        promotions: state => state.promotions,
        activePromo: state => state.activePromo,
        products: (state) => {
            return state.products;
        },
        inventory: (state) => {
            return state.inventory;
        },
        inventoryData: (state) => {
            return state.inventoryData;
        },
        favourites: (state) => {
            return state.favourites;
        },
        totalCartQuantity: (state) => {
            return state.products.reduce((acc, product) => acc + product.quantity, 0);
        },
        totalCartData: (state) => {
            return state.totalCartData;
        },
        itemLoading: (state) => {
            return state.itemLoading;
        },
    },
    actions: {
        SET_ITEM_LOADING({commit}) {
            commit('setItemLoading', true);
        },

        setCartData({ commit }) {
            const { basketText, basketData, favoriteData } = window;

            if(basketText) {
                const { isInventory, withInventory } = basketText;
                commit('setInventoryData', {
                    desc: isInventory.hint,
                    caption: withInventory.text,
                    hint: withInventory.hint
                });
            }

            if (basketData) {
                const parsedBasketData = JSON.parse(basketData);
                commit('setProducts', parsedBasketData);

                if (parsedBasketData.promotions) commit('setPromotions', parsedBasketData.promotions);

                // обработка active_promo
                if (parsedBasketData.active_promo) commit('setActivePromo', parsedBasketData.active_promo);
            } else {
                alertHandler.showAlert(CART_FETCH_ERROR_MESS);
                console.error(`basketData: ${basketData}`);
            }

            if (favoriteData) {
                const { state, items } = JSON.parse(favoriteData);
                commit('setFavourites', state === 1 ? items.map(({ id }) => id) : []);
            } else {
                alertHandler.showAlert(FAV_FETCH_ERROR_MESS);
                console.error(`favoriteData: ${favoriteData}`);
            }
        },

        async setActions({ commit }, { actionsData }) {
            const isCancel = actionsData.action === 'promocode-cancel';
            const actionName = actionsData.actionName;
            
            setTimeout(async () => {
                try {
                    let formData = new FormData();
                    for (let key in actionsData) {
                        formData.append(key, actionsData[key]);
                    }

                    await axios.post('/ajax/basketActionApi.php', formData).then(
                        (response) => {
                            const { data, status, message = ERROR_MESS } = response.data;
                            if (status === 'success') {
                                commit('setProducts', data);
                                commit('setActivePromo', data.active_promo);
                                const alertMessage = `Вы ${isCancel ? 'отменили' : 'применили'} акцию ${actionName}`;
                                alertHandler.showAlert(alertMessage);
                            } else {
                                console.error(message);
                                alertHandler.showAlert(message);
                            }
                        }
                    );
                } catch (error) {
                    alertHandler.showAlert(CART_FETCH_ERROR_MESS);
                    console.error(error);
                    return null;
                }
            }, 100);
        },

        async setProducts({ commit }) {
            setTimeout(async () => {
                try {

                    await axios.get('/ajax/basketActionApi.php').then(
                        (response) => {
                            const { data, status, message = ERROR_MESS } = response.data;
                            if(status === 'success') {
                                commit('setProducts', data);
                            } else {
                                console.error(message);
                                alertHandler.showAlert(message);
                            }
                        }
                    );
                } catch (error) {
                    alertHandler.showAlert(CART_FETCH_ERROR_MESS);
                    console.error(error);
                    return null;
                }
            }, 100);
        },

        async clearBasket({ commit }) {
            setTimeout(async () => {
                try {

                    await axios.post('/ajax/basketActionApi.php', {
                        action: 'clear'
                    }).then(
                        (response) => {
                            const { data, status, message = ERROR_MESS } = response.data;
                            if(status === 'success') {
                                getOrderParams(true);
                                commit('setProducts', data);
                                // Обновление мини корзины, т.к. там не vue
                                commonObserver.publish('updateCart', response.data.data);
                                updateBasketCount(response.data);
                            } else {
                                console.error(message);
                                alertHandler.showAlert(message);
                            }
                        }
                    );
                } catch (error) {
                    alertHandler.showAlert(CART_FETCH_ERROR_MESS);
                    console.error(error);
                    return null;
                }
            }, 100);
        },

        async updateProducts({ commit, state }, {
            id,
            quantity,
            inventoryRelatedProd,
            invQuantity
        }) {

            let debounceTimer;

            const action = quantity !== 0 ? 'quantity' : 'delete';
            const cartData = inventoryRelatedProd !== undefined && invQuantity !== undefined ? {
                basket: id,
                quantity,
                action
            } : {
                basket: id,
                action: 'delete'
            };

            // Функция, которая будет вызвана после задержки
            const debouncedAxiosPost = async (cartData) => {
                commit('setItemLoading', true);

                try {
                    const response = await axios.post('/ajax/basketActionApi.php', cartData);
                    const { data, status, message = ERROR_MESS } = response.data;

                    if (status === 'success') {
                        commit('setProducts', data);
                        commonObserver.publish('updateCart', response.data.data);
                        updateBasketCount(response.data);
                    } else {
                        console.error(message);
                        alertHandler.showAlert(message);
                    }
                } catch (error) {
                    alertHandler.showAlert(CART_UPDATE_ERROR_MESS);
                    console.error(error);
                } finally {
                    commit('setItemLoading', false);
                }
            };

            if(inventoryRelatedProd) {
                cartData.inventoryData = {[inventoryRelatedProd]: invQuantity};
            }

            if(!id && quantity > 0) {
                const { id, quantity } = state.products.find(({ product }) => Number(product.id) === Number(inventoryRelatedProd));
                if(quantity <= invQuantity) {
                    cartData.quantity = 0;
                } else {
                    cartData.basket = id;
                    cartData.quantity = quantity;
                    cartData.action = 'quantity';
                };
            }

            // Очищаем таймер перед каждым новым вызовом
            clearTimeout(debounceTimers[id]);

            // Запускаем новый таймер
            debounceTimers[id] = setTimeout(() => {
                debouncedAxiosPost(cartData);
            }, 500);

            // try {
            //     await axios.post('/ajax/basketActionApi.php', cartData).then(
            //         (response) => {
            //             const { data, status, message = ERROR_MESS } = response.data;
            //
            //             if (status === 'success') {
            //                 commit('setProducts', data);
            //                 // Обновление мини корзины, т.к. там не vue
            //                 commonObserver.publish('updateCart', response.data.data);
            //                 updateBasketCount(response.data);
            //             } else {
            //                 console.error(message);
            //                 alertHandler.showAlert(message);
            //             }
            //         }
            //     );
            // } catch (error) {
            //     alertHandler.showAlert(CART_UPDATE_ERROR_MESS);
            //     console.error(error);
            //     return null;
            // }
        },

        async setFavourites({ commit }) {
            try {
                await axios.get('/crm/sale/favorites/list/').then(
                    (response) => {
                        const { data, code, message = ERROR_MESS } = response.data;
                        if (code === 0) {
                            commit('setFavourites', data.items);
                        } else {
                            /* TODO: проверить наименование переменной для message */
                            console.error(message);
                            alertHandler.showAlert(message);
                        }
                    }
                );
            } catch (error) {
                alertHandler.showAlert(FAV_FETCH_ERROR_MESS);
                console.error(error);
                return null;
            }
        },

        async updateFavourites({ commit }, { product, isFavourite }) {
            try {
                await axios.post('/ajax/favoritesAction.php', {
                    product,
                    action: isFavourite ? 'delete' : 'add'
                })
                .then((response) => {
                    const { items, state, message = ERROR_MESS } = response.data;

                    if (state === 1) {
                        const favIds = items ? items.map((item) => item.id) : [];

                        commit('setFavourites', favIds);

                        // Обновление мини корзины, т.к. там не vue
                        commonObserver.publish('updateFavorite', response.data);
                        updateFavoriteCount(items?.length);
                    } else {
                        /* TODO: проверить наименование переменной для message */
                        console.error(message);
                        alertHandler.showAlert(message);
                    }
                });
            } catch (error) {
                alertHandler.showAlert(FAV_UPDATE_ERROR_MESS);
                console.error(error);
                return null;
            }
        }
    },
    mutations: {
        setItemLoading (state, arg) {
            state.itemLoading = arg
        },
        setActivePromo(state, activePromo) {
            state.activePromo = state.promotions.find(item => item.id === activePromo?.promotion_id);
        },
        setPromotions(state, promotions) {
            state.promotions = promotions;
        },
        setProducts(state, { products, total }) {
            const {
                restrict,
                discount,
                total: cartTotal,
                products: productsTotal,
                inventory,
                pledge,
            } = total;
            const inventoryItem = products.find(item => item.isInventory);
            const prodWithInventory = products.find(product => product.withInventory);
            const setProdData = (item) => {
                const {
                    quantity,
                    ratio,
                    price,
                    isInventory,
                    withInventory
                } = item;
                const totalPrice =  quantity * ratio * price;

                return {
                    ...item,
                    totalPrice,
                    inventoryQuantity: pledge.count,
                    inventoryRelatedProd: prodWithInventory && (isInventory || withInventory) ? prodWithInventory.product.id : ''
                };
            };

            state.totalCartData = {
                count: cartTotal.count,
                finalSumm: cartTotal.sum,
                summ: productsTotal.sum + Number(inventory.sum),
                discount,
                minPrice: Boolean(restrict) ? restrict.minPrice : 0,
                minPriceDiff: Boolean(restrict) ? restrict.minPriceDiff : 0,
            };
            state.products = products.map(product => setProdData(product));
            state.inventory = inventoryItem ? setProdData({...inventoryItem}) : setProdData({
                id: '',
                quantity: 0,
                ratio: 1,
                price: 0,
                oldPrice: 0,
                isInventory: true,
                withInventory: false,
                measureText: 'шт'
            });
        },
        setInventoryData(state, { desc, caption, hint }) {
            state.inventoryData = { desc, caption, hint };
        },
        setFavourites(state, favourites) {
            state.favourites = favourites;
        },
    }
};
