/**
* Имитация API для тестирования подгрузки элементов в список заказов
* @returns {Object} - объект данных списка заказов
*/
function handleData() {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve({
              "success": true,
              "data": {
                "paginatedList": {
                  "page": 1,
                  "perPage": 20,
                  "totalCount": 34,
                  "totalPages": 1,
                  "list": [
                    {
                      "order": {
                        "id": 987654321,
                        "date": "16.01.2024 12:28:17",
                        "status": {
                          "ID": "C",
                          "NAME": "Отменен"
                        },
                        "price": 6000,
                        "currency": "RUB",
                        "discount_value": 0,
                        "user_comment": "тест проверка подставления последнего адреса в повтор заказа",
                        "price_formated": "6 000 руб.",
                        "payed": false,
                        "date_payed": null,
                        "canceled": false,
                        "links": [
                          {
                            "code": "pay",
                            "name": "Оплатить on-line",
                            "url": "/personal/order/payment/?ORDER_ID=125191&PAYMENT_ID=125191/1"
                          },
                          {
                            "code": "copy",
                            "name": "Повторить",
                            "url": "/personal/order/?ID=125191&COPY_ORDER=Y"
                          }
                        ],
                        "properties": {
                          "CUSTOM_ADDRESS": "29758",
                          "USER_PHONE": "79992327000",
                          "DO_NOT_CALL_BACK": "Y",
                          "ORDER_EXPORTED_1C": "N",
                          "APP_VER": "WEB",
                          "USER_BIRTHDAY": "10.01.1990",
                          "IS_MOBILE_ORDER": "N",
                          "FAST_ORDER": "N",
                          "ADDRESS_JSON": "address=Россия, Санкт-Петербург, Большая Монетная улица, 16к1В&apartment=123&entrance=4&floor=5&domofon=123&elevator=Да",
                          "ADDRESS": "Россия, Санкт-Петербург, Большая Монетная улица, 16к1В, под. 4, эт. 5, кв. 123, код дом. 123, лифт. есть",
                          "ZONE": "центр;1701",
                          "DELIVERY_TIME": "12:00–16:00",
                          "DELIVERY_DATE": "20.01.2024",
                          "PHONE": "79992327000",
                          "EMAIL": "gm382014@gmail.com",
                          "CONTACT_PERSON": "Тест Тест"
                        },
                        "basket": [
                          {
                            "id": "333994",
                            "product": {
                              "id": "10459",
                              "url": "/catalog/kofe/komplekt-ricci-gusto-sangiorgio-po-1-kg/"
                            },
                            "picture": "/upload/iblock/201/20152a0b0b972a115e280c7503cb67ab.png",
                            "title": "Комплект RICCI + GUSTO + SANGIORGIO по 1 кг",
                            "withInventory": false,
                            "isInventory": false,
                            "content": "Комплект RICCI + GUSTO + SANGIORGIO по 1 кг",
                            "quantityMax": 27,
                            "quantity": 1,
                            "ratio": 1,
                            "isPack": false,
                            "price": 6000,
                            "oldPrice": 7500,
                            "itemPrice": 6000,
                            "itemOldPrice": 7500,
                            "discount": 20,
                            "discountActions": [],
                            "measureText": "наб",
                            "measureTextOrig": "наб",
                            "measureQuantity": 1,
                            "measurePrice": 6000,
                            "measureOldPrice": 7500,
                            "isComplect": true,
                            "sum": 6000
                          }
                        ]
                      },
                      "shipment": {
                        "id": 16,
                        "name": "Доставка ШЛ",
                        "sum": 0,
                        "currency": "RUB",
                        "sum_formated": null
                      },
                      "payment": {
                        "id": 10,
                        "name": "Картой курьеру",
                        "paid": false,
                        "sum": 6000,
                        "currency": "RUB",
                        "sum_formated": "6 000 руб."
                      }
                    },
                    {
                      "order": {
                        "id": 125190,
                        "date": "15.01.2024 11:09:17",
                        "status": {
                          "ID": "C",
                          "NAME": "Отменен"
                        },
                        "price": 3222,
                        "currency": "RUB",
                        "discount_value": 0,
                        "user_comment": "тест тест тест",
                        "price_formated": "3 222 руб.",
                        "payed": false,
                        "date_payed": null,
                        "canceled": false,
                        "links": [
                          {
                            "code": "copy",
                            "name": "Повторить",
                            "url": "/personal/order/?ID=125190&COPY_ORDER=Y"
                          }
                        ],
                        "properties": {
                          "CUSTOM_ADDRESS": "27707",
                          "USER_PHONE": "79992327000",
                          "DO_NOT_CALL_BACK": "N",
                          "ORDER_EXPORTED_1C": "N",
                          "APP_VER": "WEB",
                          "USER_BIRTHDAY": "08.01.1990",
                          "IS_MOBILE_ORDER": "N",
                          "FAST_ORDER": "N",
                          "ADDRESS_JSON": "address= 12,  Россия улица Композиторов, Санкт-Петербург, &apartment=536&entrance=5&floor=15&domofon=536&elevator=Да",
                          "ADDRESS": "12,  Россия улица Композиторов, Санкт-Петербург, под. 5, эт. 15, кв. 536, код дом. 536, лифт. есть",
                          "ZONE": "калининский;1703",
                          "DELIVERY_TIME": "16:00–20:00",
                          "DELIVERY_DATE": "20.01.2024",
                          "PHONE": "79992327000",
                          "EMAIL": "gm382014@gmail.com",
                          "CONTACT_PERSON": "Тест Тест"
                        },
                        "basket": [
                          {
                            "id": "333876",
                            "product": {
                              "id": "9380",
                              "url": "/catalog/dlya-sporta/0-4l-sport-12sht-up-/"
                            },
                            "picture": "/upload/iblock/cc5/cc526573261c351ba31e5f95dad0e0a3.png",
                            "title": "0,4л Sport (12шт./уп.)",
                            "withInventory": false,
                            "isInventory": false,
                            "content": "Вода питьевая \"Шишкин лес\" 0,4л Спорт",
                            "quantityMax": 370902,
                            "quantity": 24,
                            "ratio": 12,
                            "isPack": true,
                            "price": 504,
                            "oldPrice": 504,
                            "itemPrice": 42,
                            "itemOldPrice": 42,
                            "discount": 0,
                            "discountActions": [
                              {
                                "quantity": 4,
                                "discount": 2
                              },
                              {
                                "quantity": 10,
                                "discount": 5
                              },
                              {
                                "quantity": 17,
                                "discount": 10
                              },
                              {
                                "quantity": 23,
                                "discount": 14
                              }
                            ],
                            "measureText": "уп",
                            "measureTextOrig": null,
                            "measureQuantity": 2,
                            "measurePrice": 504,
                            "measureOldPrice": 504,
                            "isComplect": false,
                            "sum": 1008
                          },
                          {
                            "id": "333875",
                            "product": {
                              "id": "10766",
                              "url": "/catalog/dlya-sporta/0-65l-sport-9sht-up-/"
                            },
                            "picture": "/upload/iblock/cd3/cd37cc59197f09eba6c5fd060a80e44b.png",
                            "title": "0,65л Sport (9шт./уп.)",
                            "withInventory": false,
                            "isInventory": false,
                            "content": "Вода питьевая \"Шишкин лес\" 0,65л Спорт",
                            "quantityMax": 274025,
                            "quantity": 18,
                            "ratio": 9,
                            "isPack": true,
                            "price": 423,
                            "oldPrice": 423,
                            "itemPrice": 47,
                            "itemOldPrice": 47,
                            "discount": 0,
                            "discountActions": [
                              {
                                "quantity": 5,
                                "discount": 4
                              },
                              {
                                "quantity": 13,
                                "discount": 9
                              },
                              {
                                "quantity": 22,
                                "discount": 11
                              },
                              {
                                "quantity": 30,
                                "discount": 15
                              }
                            ],
                            "measureText": "уп",
                            "measureTextOrig": null,
                            "measureQuantity": 2,
                            "measurePrice": 423,
                            "measureOldPrice": 423,
                            "isComplect": false,
                            "sum": 846
                          },
                          {
                            "id": "333874",
                            "product": {
                              "id": "9395",
                              "url": "/catalog/dlya-sporta/1l-sport-12sht-up-/"
                            },
                            "picture": "/upload/iblock/697/697497cd7e1bf90323941de1f09af220.png",
                            "title": "1л Sport (12шт./уп.)",
                            "withInventory": false,
                            "isInventory": false,
                            "content": "Вода питьевая \"Шишкин лес\" 1л Спорт",
                            "quantityMax": 310105,
                            "quantity": 24,
                            "ratio": 12,
                            "isPack": true,
                            "price": 684,
                            "oldPrice": 684,
                            "itemPrice": 57,
                            "itemOldPrice": 57,
                            "discount": 0,
                            "discountActions": [
                              {
                                "quantity": 4,
                                "discount": 2
                              },
                              {
                                "quantity": 10,
                                "discount": 5
                              },
                              {
                                "quantity": 17,
                                "discount": 9
                              },
                              {
                                "quantity": 23,
                                "discount": 14
                              }
                            ],
                            "measureText": "уп",
                            "measureTextOrig": null,
                            "measureQuantity": 2,
                            "measurePrice": 684,
                            "measureOldPrice": 684,
                            "isComplect": false,
                            "sum": 1368
                          }
                        ]
                      },
                      "shipment": {
                        "id": 16,
                        "name": "Доставка ШЛ",
                        "sum": 0,
                        "currency": "RUB",
                        "sum_formated": null
                      },
                      "payment": {
                        "id": 1,
                        "name": "Наличными курьеру",
                        "paid": false,
                        "sum": 3222,
                        "currency": "RUB",
                        "sum_formated": "3 222 руб."
                      }
                    },
                    {
                      "order": {
                        "id": 125189,
                        "date": "15.01.2024 09:21:23",
                        "status": {
                          "ID": "C",
                          "NAME": "Отменен"
                        },
                        "price": 7974,
                        "currency": "RUB",
                        "discount_value": 0,
                        "user_comment": "тест тест тест",
                        "price_formated": "7 974 руб.",
                        "payed": false,
                        "date_payed": null,
                        "canceled": false,
                        "links": [
                          {
                            "code": "pay",
                            "name": "Оплатить on-line",
                            "url": "/personal/order/payment/?ORDER_ID=125189&PAYMENT_ID=125189/1"
                          },
                          {
                            "code": "copy",
                            "name": "Повторить",
                            "url": "/personal/order/?ID=125189&COPY_ORDER=Y"
                          }
                        ],
                        "properties": {
                          "CUSTOM_ADDRESS": "27707",
                          "USER_PHONE": "79992327000",
                          "DO_NOT_CALL_BACK": "Y",
                          "ORDER_EXPORTED_1C": "N",
                          "APP_VER": "WEB",
                          "USER_BIRTHDAY": "08.01.1990",
                          "IS_MOBILE_ORDER": "N",
                          "FAST_ORDER": "N",
                          "ADDRESS_JSON": "address= 12,  Россия улица Композиторов, Санкт-Петербург, &apartment=536&entrance=5&floor=15&domofon=536&elevator=Да",
                          "ADDRESS": "12,  Россия улица Композиторов, Санкт-Петербург, под. 5, эт. 15, кв. 536, код дом. 536, лифт. есть",
                          "ZONE": "калининский;1703",
                          "DELIVERY_TIME": "12:00–16:00",
                          "DELIVERY_DATE": "19.01.2024",
                          "PHONE": "79110232222",
                          "EMAIL": "gm382014@gmail.com",
                          "CONTACT_PERSON": "Тестов Тест"
                        },
                        "basket": [
                          {
                            "id": "333873",
                            "product": {
                              "id": "9892",
                              "url": "/catalog/gazirovannaya/0-4l-gaz-12sht-up-/"
                            },
                            "picture": "/upload/iblock/f72/f72805afa77461156bd23452797682d0.png",
                            "title": "0,4л газ (12шт./уп.)",
                            "withInventory": false,
                            "isInventory": false,
                            "content": "Вода питьевая газированная \"Шишкин лес\" 0,4л",
                            "quantityMax": 38290,
                            "quantity": 24,
                            "ratio": 12,
                            "isPack": true,
                            "price": 396,
                            "oldPrice": 396,
                            "itemPrice": 33,
                            "itemOldPrice": 33,
                            "discount": 0,
                            "discountActions": [
                              {
                                "quantity": 4,
                                "discount": 6
                              },
                              {
                                "quantity": 10,
                                "discount": 12
                              },
                              {
                                "quantity": 17,
                                "discount": 15
                              },
                              {
                                "quantity": 23,
                                "discount": 21
                              }
                            ],
                            "measureText": "уп",
                            "measureTextOrig": null,
                            "measureQuantity": 2,
                            "measurePrice": 396,
                            "measureOldPrice": 396,
                            "isComplect": false,
                            "sum": 792
                          },
                          {
                            "id": "333872",
                            "product": {
                              "id": "9388",
                              "url": "/catalog/gazirovannaya/1l-gaz-6sht-up-/"
                            },
                            "picture": "/upload/iblock/c88/c888580d1f9f82405773eadb4651502e.png",
                            "title": "1л газ (6шт./уп.)",
                            "withInventory": false,
                            "isInventory": false,
                            "content": "Вода питьевая газированная \"Шишкин лес\" 1л",
                            "quantityMax": 111124,
                            "quantity": 12,
                            "ratio": 6,
                            "isPack": true,
                            "price": 276,
                            "oldPrice": 276,
                            "itemPrice": 46,
                            "itemOldPrice": 46,
                            "discount": 0,
                            "discountActions": [
                              {
                                "quantity": 8,
                                "discount": 4
                              },
                              {
                                "quantity": 20,
                                "discount": 9
                              },
                              {
                                "quantity": 34,
                                "discount": 13
                              },
                              {
                                "quantity": 46,
                                "discount": 15
                              }
                            ],
                            "measureText": "уп",
                            "measureTextOrig": null,
                            "measureQuantity": 2,
                            "measurePrice": 276,
                            "measureOldPrice": 276,
                            "isComplect": false,
                            "sum": 552
                          },
                          {
                            "id": "333871",
                            "product": {
                              "id": "9380",
                              "url": "/catalog/dlya-sporta/0-4l-sport-12sht-up-/"
                            },
                            "picture": "/upload/iblock/cc5/cc526573261c351ba31e5f95dad0e0a3.png",
                            "title": "0,4л Sport (12шт./уп.)",
                            "withInventory": false,
                            "isInventory": false,
                            "content": "Вода питьевая \"Шишкин лес\" 0,4л Спорт",
                            "quantityMax": 370902,
                            "quantity": 24,
                            "ratio": 12,
                            "isPack": true,
                            "price": 504,
                            "oldPrice": 504,
                            "itemPrice": 42,
                            "itemOldPrice": 42,
                            "discount": 0,
                            "discountActions": [
                              {
                                "quantity": 4,
                                "discount": 2
                              },
                              {
                                "quantity": 10,
                                "discount": 5
                              },
                              {
                                "quantity": 17,
                                "discount": 10
                              },
                              {
                                "quantity": 23,
                                "discount": 14
                              }
                            ],
                            "measureText": "уп",
                            "measureTextOrig": null,
                            "measureQuantity": 2,
                            "measurePrice": 504,
                            "measureOldPrice": 504,
                            "isComplect": false,
                            "sum": 1008
                          },
                          {
                            "id": "333870",
                            "product": {
                              "id": "10766",
                              "url": "/catalog/dlya-sporta/0-65l-sport-9sht-up-/"
                            },
                            "picture": "/upload/iblock/cd3/cd37cc59197f09eba6c5fd060a80e44b.png",
                            "title": "0,65л Sport (9шт./уп.)",
                            "withInventory": false,
                            "isInventory": false,
                            "content": "Вода питьевая \"Шишкин лес\" 0,65л Спорт",
                            "quantityMax": 274025,
                            "quantity": 18,
                            "ratio": 9,
                            "isPack": true,
                            "price": 423,
                            "oldPrice": 423,
                            "itemPrice": 47,
                            "itemOldPrice": 47,
                            "discount": 0,
                            "discountActions": [
                              {
                                "quantity": 5,
                                "discount": 4
                              },
                              {
                                "quantity": 13,
                                "discount": 9
                              },
                              {
                                "quantity": 22,
                                "discount": 11
                              },
                              {
                                "quantity": 30,
                                "discount": 15
                              }
                            ],
                            "measureText": "уп",
                            "measureTextOrig": null,
                            "measureQuantity": 2,
                            "measurePrice": 423,
                            "measureOldPrice": 423,
                            "isComplect": false,
                            "sum": 846
                          },
                          {
                            "id": "333869",
                            "product": {
                              "id": "9395",
                              "url": "/catalog/dlya-sporta/1l-sport-12sht-up-/"
                            },
                            "picture": "/upload/iblock/697/697497cd7e1bf90323941de1f09af220.png",
                            "title": "1л Sport (12шт./уп.)",
                            "withInventory": false,
                            "isInventory": false,
                            "content": "Вода питьевая \"Шишкин лес\" 1л Спорт",
                            "quantityMax": 310105,
                            "quantity": 24,
                            "ratio": 12,
                            "isPack": true,
                            "price": 684,
                            "oldPrice": 684,
                            "itemPrice": 57,
                            "itemOldPrice": 57,
                            "discount": 0,
                            "discountActions": [
                              {
                                "quantity": 4,
                                "discount": 2
                              },
                              {
                                "quantity": 10,
                                "discount": 5
                              },
                              {
                                "quantity": 17,
                                "discount": 9
                              },
                              {
                                "quantity": 23,
                                "discount": 14
                              }
                            ],
                            "measureText": "уп",
                            "measureTextOrig": null,
                            "measureQuantity": 2,
                            "measurePrice": 684,
                            "measureOldPrice": 684,
                            "isComplect": false,
                            "sum": 1368
                          },
                          {
                            "id": "333868",
                            "product": {
                              "id": "9390",
                              "url": "/catalog/dlya-semi/0-4l-12sht-up-/"
                            },
                            "picture": "/upload/iblock/933/9331a22ef4c54e269e48d7f3807e3564.png",
                            "title": "0,4л (12шт./уп.)",
                            "withInventory": false,
                            "isInventory": false,
                            "content": "Вода питьевая \"Шишкин лес\" 0,4л",
                            "quantityMax": 309021,
                            "quantity": 24,
                            "ratio": 12,
                            "isPack": true,
                            "price": 396,
                            "oldPrice": 396,
                            "itemPrice": 33,
                            "itemOldPrice": 33,
                            "discount": 0,
                            "discountActions": [
                              {
                                "quantity": 4,
                                "discount": 6
                              },
                              {
                                "quantity": 10,
                                "discount": 12
                              },
                              {
                                "quantity": 17,
                                "discount": 15
                              },
                              {
                                "quantity": 23,
                                "discount": 21
                              }
                            ],
                            "measureText": "уп",
                            "measureTextOrig": null,
                            "measureQuantity": 2,
                            "measurePrice": 396,
                            "measureOldPrice": 396,
                            "isComplect": false,
                            "sum": 792
                          },
                          {
                            "id": "333867",
                            "product": {
                              "id": "10765",
                              "url": "/catalog/dlya-semi/0-65l-9sht-up-/"
                            },
                            "picture": "/upload/iblock/c80/c801affc56eb68c7faa6f95fa08d7920.png",
                            "title": "0,65л (9шт./уп.)",
                            "withInventory": false,
                            "isInventory": false,
                            "content": "Вода питьевая \"Шишкин лес\" 0,65л",
                            "quantityMax": 253734,
                            "quantity": 18,
                            "ratio": 9,
                            "isPack": true,
                            "price": 324,
                            "oldPrice": 324,
                            "itemPrice": 36,
                            "itemOldPrice": 36,
                            "discount": 0,
                            "discountActions": [
                              {
                                "quantity": 5,
                                "discount": 6
                              },
                              {
                                "quantity": 13,
                                "discount": 11
                              },
                              {
                                "quantity": 22,
                                "discount": 17
                              },
                              {
                                "quantity": 30,
                                "discount": 19
                              }
                            ],
                            "measureText": "уп",
                            "measureTextOrig": null,
                            "measureQuantity": 2,
                            "measurePrice": 324,
                            "measureOldPrice": 324,
                            "isComplect": false,
                            "sum": 648
                          },
                          {
                            "id": "333866",
                            "product": {
                              "id": "9389",
                              "url": "/catalog/dlya-semi/1l-12sht-up-/"
                            },
                            "picture": "/upload/iblock/1a8/1a855948c3896d2ad2117e48f4672874.png",
                            "title": "1л (12шт./уп.)",
                            "withInventory": false,
                            "isInventory": false,
                            "content": "Вода питьевая \"Шишкин лес\" 1л",
                            "quantityMax": 289665,
                            "quantity": 24,
                            "ratio": 12,
                            "isPack": true,
                            "price": 552,
                            "oldPrice": 552,
                            "itemPrice": 46,
                            "itemOldPrice": 46,
                            "discount": 0,
                            "discountActions": [
                              {
                                "quantity": 4,
                                "discount": 4
                              },
                              {
                                "quantity": 10,
                                "discount": 9
                              },
                              {
                                "quantity": 17,
                                "discount": 13
                              },
                              {
                                "quantity": 23,
                                "discount": 15
                              }
                            ],
                            "measureText": "уп",
                            "measureTextOrig": null,
                            "measureQuantity": 2,
                            "measurePrice": 552,
                            "measureOldPrice": 552,
                            "isComplect": false,
                            "sum": 1104
                          },
                          {
                            "id": "333865",
                            "product": {
                              "id": "14978",
                              "url": "/catalog/dlya-semi/1-75l-8sht-up-/"
                            },
                            "picture": "/upload/iblock/aa2/aa22c59682d579b57e70c9f4c626a902.png",
                            "title": "1,75л  (8шт./уп.)",
                            "withInventory": false,
                            "isInventory": false,
                            "content": "Вода питьева \"Шишкин лес\" 1,75л",
                            "quantityMax": 384376,
                            "quantity": 16,
                            "ratio": 8,
                            "isPack": true,
                            "price": 432,
                            "oldPrice": 432,
                            "itemPrice": 54,
                            "itemOldPrice": 54,
                            "discount": 0,
                            "discountActions": [
                              {
                                "quantity": 6,
                                "discount": 7
                              },
                              {
                                "quantity": 15,
                                "discount": 11
                              },
                              {
                                "quantity": 25,
                                "discount": 15
                              },
                              {
                                "quantity": 34,
                                "discount": 19
                              }
                            ],
                            "measureText": "уп",
                            "measureTextOrig": null,
                            "measureQuantity": 2,
                            "measurePrice": 432,
                            "measureOldPrice": 432,
                            "isComplect": false,
                            "sum": 864
                          }
                        ]
                      },
                      "shipment": {
                        "id": 16,
                        "name": "Доставка ШЛ",
                        "sum": 0,
                        "currency": "RUB",
                        "sum_formated": null
                      },
                      "payment": {
                        "id": 10,
                        "name": "Картой курьеру",
                        "paid": false,
                        "sum": 7974,
                        "currency": "RUB",
                        "sum_formated": "7 974 руб."
                      }
                    },
                    {
                      "order": {
                        "id": 125182,
                        "date": "12.01.2024 00:22:18",
                        "status": {
                          "ID": "C",
                          "NAME": "Отменен"
                        },
                        "price": 6000,
                        "currency": "RUB",
                        "discount_value": 0,
                        "user_comment": "тестовый заказ, удалить!",
                        "price_formated": "6 000 руб.",
                        "payed": false,
                        "date_payed": null,
                        "canceled": true,
                        "links": [
                          {
                            "code": "copy",
                            "name": "Повторить",
                            "url": "/personal/order/?ID=125182&COPY_ORDER=Y"
                          }
                        ],
                        "properties": {
                          "CUSTOM_ADDRESS": "30533",
                          "USER_PHONE": "79992327000",
                          "DO_NOT_CALL_BACK": "N",
                          "ORDER_EXPORTED_1C": "N",
                          "APP_VER": "WEB",
                          "USER_BIRTHDAY": "05.01.1990",
                          "IS_MOBILE_ORDER": "N",
                          "FAST_ORDER": "N",
                          "ADDRESS_JSON": "address=Санкт-Петербург,  12,  Россия улица Композиторов, Санкт-Петербург, , кв/оф. 536, под. 5, эт. 15, код дом. 536, Лифт есть&apartment=&entrance=&floor=&domofon=&elevator=",
                          "ADDRESS": "Санкт-Петербург,  12,  Россия улица Композиторов, Санкт-Петербург, кв/оф. 536, под. 5, эт. 15, код дом. 536, Лифт есть, лифт. нет",
                          "ZONE": "калининский;1703",
                          "DELIVERY_TIME": "12:00–16:00",
                          "DELIVERY_DATE": "15.01.2024",
                          "PHONE": "79110232222",
                          "EMAIL": "gm382014@gmail.com",
                          "CONTACT_PERSON": "Тестов Тест"
                        },
                        "basket": [
                          {
                            "id": "333789",
                            "product": {
                              "id": "10459",
                              "url": "/catalog/kofe/komplekt-ricci-gusto-sangiorgio-po-1-kg/"
                            },
                            "picture": "/upload/iblock/201/20152a0b0b972a115e280c7503cb67ab.png",
                            "title": "Комплект RICCI + GUSTO + SANGIORGIO по 1 кг",
                            "withInventory": false,
                            "isInventory": false,
                            "content": "Комплект RICCI + GUSTO + SANGIORGIO по 1 кг",
                            "quantityMax": 27,
                            "quantity": 1,
                            "ratio": 1,
                            "isPack": false,
                            "price": 6000,
                            "oldPrice": 7500,
                            "itemPrice": 6000,
                            "itemOldPrice": 7500,
                            "discount": 20,
                            "discountActions": [],
                            "measureText": "наб",
                            "measureTextOrig": "наб",
                            "measureQuantity": 1,
                            "measurePrice": 6000,
                            "measureOldPrice": 7500,
                            "isComplect": true,
                            "sum": 6000
                          }
                        ]
                      },
                      "shipment": {
                        "id": 16,
                        "name": "Доставка ШЛ",
                        "sum": 0,
                        "currency": "RUB",
                        "sum_formated": null
                      },
                      "payment": {
                        "id": 1,
                        "name": "Наличными курьеру",
                        "paid": false,
                        "sum": 6000,
                        "currency": "RUB",
                        "sum_formated": "6 000 руб."
                      }
                    },
                    {
                      "order": {
                        "id": 125181,
                        "date": "11.01.2024 01:21:51",
                        "status": {
                          "ID": "C",
                          "NAME": "Отменен"
                        },
                        "price": 7974,
                        "currency": "RUB",
                        "discount_value": 0,
                        "user_comment": "тест тест тест",
                        "price_formated": "7 974 руб.",
                        "payed": false,
                        "date_payed": null,
                        "canceled": true,
                        "links": [
                          {
                            "code": "copy",
                            "name": "Повторить",
                            "url": "/personal/order/?ID=125181&COPY_ORDER=Y"
                          }
                        ],
                        "properties": {
                          "CUSTOM_ADDRESS": "29629",
                          "USER_PHONE": "79992327000",
                          "DO_NOT_CALL_BACK": "Y",
                          "ORDER_EXPORTED_1C": "N",
                          "APP_VER": "WEB",
                          "USER_BIRTHDAY": "27.09.1976",
                          "IS_MOBILE_ORDER": "N",
                          "FAST_ORDER": "N",
                          "ADDRESS_JSON": "address=под. 1, эт. 1, кв. 1, код дом. 1, лифт. нет, Россия, Санкт-Петербург, Невский проспект, 106&apartment=&entrance=&floor=&domofon=&elevator=",
                          "ADDRESS": "под. 1, эт. 1, кв. 1, код дом. 1, лифт. нет, Россия, Санкт-Петербург, Невский проспект, 106, лифт. нет",
                          "ZONE": "центр;1701",
                          "DELIVERY_TIME": "06:00–12:00",
                          "DELIVERY_DATE": "15.01.2024",
                          "PHONE": "79110232222",
                          "EMAIL": "gm382014@gmail.com",
                          "CONTACT_PERSON": "Тестов Тест"
                        },
                        "basket": [
                          {
                            "id": "333782",
                            "product": {
                              "id": "9892",
                              "url": "/catalog/gazirovannaya/0-4l-gaz-12sht-up-/"
                            },
                            "picture": "/upload/iblock/f72/f72805afa77461156bd23452797682d0.png",
                            "title": "0,4л газ (12шт./уп.)",
                            "withInventory": false,
                            "isInventory": false,
                            "content": "Вода питьевая газированная \"Шишкин лес\" 0,4л",
                            "quantityMax": 38290,
                            "quantity": 24,
                            "ratio": 12,
                            "isPack": true,
                            "price": 396,
                            "oldPrice": 396,
                            "itemPrice": 33,
                            "itemOldPrice": 33,
                            "discount": 0,
                            "discountActions": [
                              {
                                "quantity": 4,
                                "discount": 6
                              },
                              {
                                "quantity": 10,
                                "discount": 12
                              },
                              {
                                "quantity": 17,
                                "discount": 15
                              },
                              {
                                "quantity": 23,
                                "discount": 21
                              }
                            ],
                            "measureText": "уп",
                            "measureTextOrig": null,
                            "measureQuantity": 2,
                            "measurePrice": 396,
                            "measureOldPrice": 396,
                            "isComplect": false,
                            "sum": 792
                          },
                          {
                            "id": "333781",
                            "product": {
                              "id": "9388",
                              "url": "/catalog/gazirovannaya/1l-gaz-6sht-up-/"
                            },
                            "picture": "/upload/iblock/c88/c888580d1f9f82405773eadb4651502e.png",
                            "title": "1л газ (6шт./уп.)",
                            "withInventory": false,
                            "isInventory": false,
                            "content": "Вода питьевая газированная \"Шишкин лес\" 1л",
                            "quantityMax": 111124,
                            "quantity": 12,
                            "ratio": 6,
                            "isPack": true,
                            "price": 276,
                            "oldPrice": 276,
                            "itemPrice": 46,
                            "itemOldPrice": 46,
                            "discount": 0,
                            "discountActions": [
                              {
                                "quantity": 8,
                                "discount": 4
                              },
                              {
                                "quantity": 20,
                                "discount": 9
                              },
                              {
                                "quantity": 34,
                                "discount": 13
                              },
                              {
                                "quantity": 46,
                                "discount": 15
                              }
                            ],
                            "measureText": "уп",
                            "measureTextOrig": null,
                            "measureQuantity": 2,
                            "measurePrice": 276,
                            "measureOldPrice": 276,
                            "isComplect": false,
                            "sum": 552
                          },
                          {
                            "id": "333780",
                            "product": {
                              "id": "9380",
                              "url": "/catalog/dlya-sporta/0-4l-sport-12sht-up-/"
                            },
                            "picture": "/upload/iblock/cc5/cc526573261c351ba31e5f95dad0e0a3.png",
                            "title": "0,4л Sport (12шт./уп.)",
                            "withInventory": false,
                            "isInventory": false,
                            "content": "Вода питьевая \"Шишкин лес\" 0,4л Спорт",
                            "quantityMax": 370902,
                            "quantity": 24,
                            "ratio": 12,
                            "isPack": true,
                            "price": 504,
                            "oldPrice": 504,
                            "itemPrice": 42,
                            "itemOldPrice": 42,
                            "discount": 0,
                            "discountActions": [
                              {
                                "quantity": 4,
                                "discount": 2
                              },
                              {
                                "quantity": 10,
                                "discount": 5
                              },
                              {
                                "quantity": 17,
                                "discount": 10
                              },
                              {
                                "quantity": 23,
                                "discount": 14
                              }
                            ],
                            "measureText": "уп",
                            "measureTextOrig": null,
                            "measureQuantity": 2,
                            "measurePrice": 504,
                            "measureOldPrice": 504,
                            "isComplect": false,
                            "sum": 1008
                          },
                          {
                            "id": "333779",
                            "product": {
                              "id": "10766",
                              "url": "/catalog/dlya-sporta/0-65l-sport-9sht-up-/"
                            },
                            "picture": "/upload/iblock/cd3/cd37cc59197f09eba6c5fd060a80e44b.png",
                            "title": "0,65л Sport (9шт./уп.)",
                            "withInventory": false,
                            "isInventory": false,
                            "content": "Вода питьевая \"Шишкин лес\" 0,65л Спорт",
                            "quantityMax": 274025,
                            "quantity": 18,
                            "ratio": 9,
                            "isPack": true,
                            "price": 423,
                            "oldPrice": 423,
                            "itemPrice": 47,
                            "itemOldPrice": 47,
                            "discount": 0,
                            "discountActions": [
                              {
                                "quantity": 5,
                                "discount": 4
                              },
                              {
                                "quantity": 13,
                                "discount": 9
                              },
                              {
                                "quantity": 22,
                                "discount": 11
                              },
                              {
                                "quantity": 30,
                                "discount": 15
                              }
                            ],
                            "measureText": "уп",
                            "measureTextOrig": null,
                            "measureQuantity": 2,
                            "measurePrice": 423,
                            "measureOldPrice": 423,
                            "isComplect": false,
                            "sum": 846
                          },
                          {
                            "id": "333778",
                            "product": {
                              "id": "9395",
                              "url": "/catalog/dlya-sporta/1l-sport-12sht-up-/"
                            },
                            "picture": "/upload/iblock/697/697497cd7e1bf90323941de1f09af220.png",
                            "title": "1л Sport (12шт./уп.)",
                            "withInventory": false,
                            "isInventory": false,
                            "content": "Вода питьевая \"Шишкин лес\" 1л Спорт",
                            "quantityMax": 310105,
                            "quantity": 24,
                            "ratio": 12,
                            "isPack": true,
                            "price": 684,
                            "oldPrice": 684,
                            "itemPrice": 57,
                            "itemOldPrice": 57,
                            "discount": 0,
                            "discountActions": [
                              {
                                "quantity": 4,
                                "discount": 2
                              },
                              {
                                "quantity": 10,
                                "discount": 5
                              },
                              {
                                "quantity": 17,
                                "discount": 9
                              },
                              {
                                "quantity": 23,
                                "discount": 14
                              }
                            ],
                            "measureText": "уп",
                            "measureTextOrig": null,
                            "measureQuantity": 2,
                            "measurePrice": 684,
                            "measureOldPrice": 684,
                            "isComplect": false,
                            "sum": 1368
                          },
                          {
                            "id": "333777",
                            "product": {
                              "id": "9390",
                              "url": "/catalog/dlya-semi/0-4l-12sht-up-/"
                            },
                            "picture": "/upload/iblock/933/9331a22ef4c54e269e48d7f3807e3564.png",
                            "title": "0,4л (12шт./уп.)",
                            "withInventory": false,
                            "isInventory": false,
                            "content": "Вода питьевая \"Шишкин лес\" 0,4л",
                            "quantityMax": 309021,
                            "quantity": 24,
                            "ratio": 12,
                            "isPack": true,
                            "price": 396,
                            "oldPrice": 396,
                            "itemPrice": 33,
                            "itemOldPrice": 33,
                            "discount": 0,
                            "discountActions": [
                              {
                                "quantity": 4,
                                "discount": 6
                              },
                              {
                                "quantity": 10,
                                "discount": 12
                              },
                              {
                                "quantity": 17,
                                "discount": 15
                              },
                              {
                                "quantity": 23,
                                "discount": 21
                              }
                            ],
                            "measureText": "уп",
                            "measureTextOrig": null,
                            "measureQuantity": 2,
                            "measurePrice": 396,
                            "measureOldPrice": 396,
                            "isComplect": false,
                            "sum": 792
                          },
                          {
                            "id": "333776",
                            "product": {
                              "id": "10765",
                              "url": "/catalog/dlya-semi/0-65l-9sht-up-/"
                            },
                            "picture": "/upload/iblock/c80/c801affc56eb68c7faa6f95fa08d7920.png",
                            "title": "0,65л (9шт./уп.)",
                            "withInventory": false,
                            "isInventory": false,
                            "content": "Вода питьевая \"Шишкин лес\" 0,65л",
                            "quantityMax": 253734,
                            "quantity": 18,
                            "ratio": 9,
                            "isPack": true,
                            "price": 324,
                            "oldPrice": 324,
                            "itemPrice": 36,
                            "itemOldPrice": 36,
                            "discount": 0,
                            "discountActions": [
                              {
                                "quantity": 5,
                                "discount": 6
                              },
                              {
                                "quantity": 13,
                                "discount": 11
                              },
                              {
                                "quantity": 22,
                                "discount": 17
                              },
                              {
                                "quantity": 30,
                                "discount": 19
                              }
                            ],
                            "measureText": "уп",
                            "measureTextOrig": null,
                            "measureQuantity": 2,
                            "measurePrice": 324,
                            "measureOldPrice": 324,
                            "isComplect": false,
                            "sum": 648
                          },
                          {
                            "id": "333775",
                            "product": {
                              "id": "9389",
                              "url": "/catalog/dlya-semi/1l-12sht-up-/"
                            },
                            "picture": "/upload/iblock/1a8/1a855948c3896d2ad2117e48f4672874.png",
                            "title": "1л (12шт./уп.)",
                            "withInventory": false,
                            "isInventory": false,
                            "content": "Вода питьевая \"Шишкин лес\" 1л",
                            "quantityMax": 289665,
                            "quantity": 24,
                            "ratio": 12,
                            "isPack": true,
                            "price": 552,
                            "oldPrice": 552,
                            "itemPrice": 46,
                            "itemOldPrice": 46,
                            "discount": 0,
                            "discountActions": [
                              {
                                "quantity": 4,
                                "discount": 4
                              },
                              {
                                "quantity": 10,
                                "discount": 9
                              },
                              {
                                "quantity": 17,
                                "discount": 13
                              },
                              {
                                "quantity": 23,
                                "discount": 15
                              }
                            ],
                            "measureText": "уп",
                            "measureTextOrig": null,
                            "measureQuantity": 2,
                            "measurePrice": 552,
                            "measureOldPrice": 552,
                            "isComplect": false,
                            "sum": 1104
                          },
                          {
                            "id": "333774",
                            "product": {
                              "id": "14978",
                              "url": "/catalog/dlya-semi/1-75l-8sht-up-/"
                            },
                            "picture": "/upload/iblock/aa2/aa22c59682d579b57e70c9f4c626a902.png",
                            "title": "1,75л  (8шт./уп.)",
                            "withInventory": false,
                            "isInventory": false,
                            "content": "Вода питьева \"Шишкин лес\" 1,75л",
                            "quantityMax": 384376,
                            "quantity": 16,
                            "ratio": 8,
                            "isPack": true,
                            "price": 432,
                            "oldPrice": 432,
                            "itemPrice": 54,
                            "itemOldPrice": 54,
                            "discount": 0,
                            "discountActions": [
                              {
                                "quantity": 6,
                                "discount": 7
                              },
                              {
                                "quantity": 15,
                                "discount": 11
                              },
                              {
                                "quantity": 25,
                                "discount": 15
                              },
                              {
                                "quantity": 34,
                                "discount": 19
                              }
                            ],
                            "measureText": "уп",
                            "measureTextOrig": null,
                            "measureQuantity": 2,
                            "measurePrice": 432,
                            "measureOldPrice": 432,
                            "isComplect": false,
                            "sum": 864
                          }
                        ]
                      },
                      "shipment": {
                        "id": 16,
                        "name": "Доставка ШЛ",
                        "sum": 0,
                        "currency": "RUB",
                        "sum_formated": null
                      },
                      "payment": {
                        "id": 1,
                        "name": "Наличными курьеру",
                        "paid": false,
                        "sum": 7974,
                        "currency": "RUB",
                        "sum_formated": "7 974 руб."
                      }
                    },
                    {
                      "order": {
                        "id": 125180,
                        "date": "11.01.2024 00:59:47",
                        "status": {
                          "ID": "C",
                          "NAME": "Отменен"
                        },
                        "price": 6000,
                        "currency": "RUB",
                        "discount_value": 0,
                        "user_comment": "тест тест тест",
                        "price_formated": "6 000 руб.",
                        "payed": false,
                        "date_payed": null,
                        "canceled": true,
                        "links": [
                          {
                            "code": "copy",
                            "name": "Повторить",
                            "url": "/personal/order/?ID=125180&COPY_ORDER=Y"
                          }
                        ],
                        "properties": {
                          "CUSTOM_ADDRESS": "29758",
                          "USER_PHONE": "79992327000",
                          "DO_NOT_CALL_BACK": "N",
                          "ORDER_EXPORTED_1C": "N",
                          "APP_VER": "WEB",
                          "USER_BIRTHDAY": "27.09.1976",
                          "IS_MOBILE_ORDER": "N",
                          "FAST_ORDER": "N",
                          "ADDRESS_JSON": "address=Россия, Санкт-Петербург, Большая Монетная улица, 16к1В&apartment=123&entrance=4&floor=5&domofon=123&elevator=Да",
                          "ADDRESS": "Россия, Санкт-Петербург, Большая Монетная улица, 16к1В, под. 4, эт. 5, кв. 123, код дом. 123, лифт. есть",
                          "ZONE": "центр;1701",
                          "DELIVERY_TIME": "12:00–16:00",
                          "DELIVERY_DATE": "15.01.2024",
                          "PHONE": "79992327000",
                          "EMAIL": "gm382014@gmail.com",
                          "CONTACT_PERSON": "Тест Тест"
                        },
                        "basket": [
                          {
                            "id": "333771",
                            "product": {
                              "id": "10459",
                              "url": "/catalog/kofe/komplekt-ricci-gusto-sangiorgio-po-1-kg/"
                            },
                            "picture": "/upload/iblock/201/20152a0b0b972a115e280c7503cb67ab.png",
                            "title": "Комплект RICCI + GUSTO + SANGIORGIO по 1 кг",
                            "withInventory": false,
                            "isInventory": false,
                            "content": "Комплект RICCI + GUSTO + SANGIORGIO по 1 кг",
                            "quantityMax": 27,
                            "quantity": 1,
                            "ratio": 1,
                            "isPack": false,
                            "price": 6000,
                            "oldPrice": 7500,
                            "itemPrice": 6000,
                            "itemOldPrice": 7500,
                            "discount": 20,
                            "discountActions": [],
                            "measureText": "наб",
                            "measureTextOrig": "наб",
                            "measureQuantity": 1,
                            "measurePrice": 6000,
                            "measureOldPrice": 7500,
                            "isComplect": true,
                            "sum": 6000
                          }
                        ]
                      },
                      "shipment": {
                        "id": 16,
                        "name": "Доставка ШЛ",
                        "sum": 0,
                        "currency": "RUB",
                        "sum_formated": null
                      },
                      "payment": {
                        "id": 1,
                        "name": "Наличными курьеру",
                        "paid": false,
                        "sum": 6000,
                        "currency": "RUB",
                        "sum_formated": "6 000 руб."
                      }
                    },
                    {
                      "order": {
                        "id": 125179,
                        "date": "11.01.2024 00:58:13",
                        "status": {
                          "ID": "C",
                          "NAME": "Отменен"
                        },
                        "price": 2862,
                        "currency": "RUB",
                        "discount_value": 0,
                        "user_comment": "тест тест тест",
                        "price_formated": "2 862 руб.",
                        "payed": false,
                        "date_payed": null,
                        "canceled": true,
                        "links": [
                          {
                            "code": "copy",
                            "name": "Повторить",
                            "url": "/personal/order/?ID=125179&COPY_ORDER=Y"
                          }
                        ],
                        "properties": {
                          "CUSTOM_ADDRESS": "27707",
                          "USER_PHONE": "79992327000",
                          "DO_NOT_CALL_BACK": "Y",
                          "ORDER_EXPORTED_1C": "N",
                          "APP_VER": "WEB",
                          "USER_BIRTHDAY": "27.09.1976",
                          "IS_MOBILE_ORDER": "N",
                          "FAST_ORDER": "N",
                          "ADDRESS_JSON": "address= 12,  Россия улица Композиторов, Санкт-Петербург, &apartment=536&entrance=5&floor=15&domofon=536&elevator=Да",
                          "ADDRESS": "12,  Россия улица Композиторов, Санкт-Петербург, под. 5, эт. 15, кв. 536, код дом. 536, лифт. есть",
                          "ZONE": "калининский;1703",
                          "DELIVERY_TIME": "16:00–20:00",
                          "DELIVERY_DATE": "16.01.2024",
                          "PHONE": "79110232222",
                          "EMAIL": "gm382014@gmail.com",
                          "CONTACT_PERSON": "Тестов Тест"
                        },
                        "basket": [
                          {
                            "id": "333770",
                            "product": {
                              "id": "22063",
                              "url": "/catalog/voda-19l/komplekt-miks/"
                            },
                            "picture": "/upload/iblock/624/4g9bj2ktfba63bhoeazz8vxl54k950mi.png",
                            "title": "Комплект \"Микс\"",
                            "withInventory": false,
                            "isInventory": false,
                            "content": "Комплект \"Микс\"",
                            "quantityMax": 2494,
                            "quantity": 1,
                            "ratio": 1,
                            "isPack": false,
                            "price": 2862,
                            "oldPrice": 2862,
                            "itemPrice": 2862,
                            "itemOldPrice": 2862,
                            "discount": 0,
                            "discountActions": [],
                            "measureText": "наб",
                            "measureTextOrig": "наб",
                            "measureQuantity": 1,
                            "measurePrice": 2862,
                            "measureOldPrice": 2862,
                            "isComplect": true,
                            "sum": 2862
                          }
                        ]
                      },
                      "shipment": {
                        "id": 16,
                        "name": "Доставка ШЛ",
                        "sum": 0,
                        "currency": "RUB",
                        "sum_formated": null
                      },
                      "payment": {
                        "id": 10,
                        "name": "Картой курьеру",
                        "paid": false,
                        "sum": 2862,
                        "currency": "RUB",
                        "sum_formated": "2 862 руб."
                      }
                    },
                    {
                      "order": {
                        "id": 124687,
                        "date": "27.12.2023 12:50:46",
                        "status": {
                          "ID": "C",
                          "NAME": "Отменен"
                        },
                        "price": 2500,
                        "currency": "RUB",
                        "discount_value": 0,
                        "user_comment": "тест тест тест НЕ ОБРАБАТЫВАТЬ ЗАКАЗ!",
                        "price_formated": "2 500 руб.",
                        "payed": false,
                        "date_payed": null,
                        "canceled": true,
                        "links": [
                          {
                            "code": "copy",
                            "name": "Повторить",
                            "url": "/personal/order/?ID=124687&COPY_ORDER=Y"
                          }
                        ],
                        "properties": {
                          "CUSTOM_ADDRESS": "30533",
                          "USER_PHONE": "79992327000",
                          "DO_NOT_CALL_BACK": "Y",
                          "ORDER_EXPORTED_1C": "Y",
                          "APP_VER": "WEB",
                          "USER_BIRTHDAY": "27.09.1976",
                          "IS_MOBILE_ORDER": "N",
                          "FAST_ORDER": "N",
                          "ADDRESS_JSON": "apartment=&entrance=&floor=&domofon=&address=Санкт-Петербург,  12,  Россия улица Композиторов, Санкт-Петербург, , кв/оф. 536, под. 5, эт. 15, код дом. 536, Лифт есть",
                          "ADDRESS": "Санкт-Петербург,  12,  Россия улица Композиторов, Санкт-Петербург, кв/оф. 536, под. 5, эт. 15, код дом. 536, Лифт есть, лифт. нет",
                          "ZONE": "калининский;1703",
                          "CAR_COORDS": ",",
                          "DELIVERY_TIME": "12:00;20:00",
                          "DELIVERY_DATE": "29.12.2023 00:00:00",
                          "PHONE": "79992327000",
                          "EMAIL": "gm382014@gmail.com",
                          "CONTACT_PERSON": "Тест Тест"
                        },
                        "basket": [
                          {
                            "id": "332556",
                            "product": {
                              "id": "9732",
                              "url": "/catalog/kofe/ricci-1kg/"
                            },
                            "picture": "/upload/iblock/6dd/6ddd7af0e647dbb7e9a9172b885de1f4.png",
                            "title": "RICCI 1кг",
                            "withInventory": false,
                            "isInventory": false,
                            "content": "Кофе в зернах \"RICCI\" (1кг)",
                            "quantityMax": 41,
                            "quantity": 1,
                            "ratio": 1,
                            "isPack": false,
                            "price": 2500,
                            "oldPrice": 2500,
                            "itemPrice": 2500,
                            "itemOldPrice": 2500,
                            "discount": 0,
                            "discountActions": [],
                            "measureText": null,
                            "measureTextOrig": null,
                            "measureQuantity": 1,
                            "measurePrice": 2500,
                            "measureOldPrice": 2500,
                            "isComplect": false,
                            "sum": 2500
                          }
                        ]
                      },
                      "shipment": {
                        "id": 16,
                        "name": "Доставка ШЛ",
                        "sum": 0,
                        "currency": "RUB",
                        "sum_formated": null
                      },
                      "payment": {
                        "id": 25,
                        "name": "Онлайн оплата по карте",
                        "paid": false,
                        "sum": 2500,
                        "currency": "RUB",
                        "sum_formated": "2 500 руб."
                      }
                    },
                    {
                      "order": {
                        "id": 124640,
                        "date": "26.12.2023 21:48:32",
                        "status": {
                          "ID": "C",
                          "NAME": "Отменен"
                        },
                        "price": 2290,
                        "currency": "RUB",
                        "discount_value": 0,
                        "user_comment": "тест тест тест 112233",
                        "price_formated": "2 290 руб.",
                        "payed": false,
                        "date_payed": null,
                        "canceled": true,
                        "links": [
                          {
                            "code": "copy",
                            "name": "Повторить",
                            "url": "/personal/order/?ID=124640&COPY_ORDER=Y"
                          }
                        ],
                        "properties": {
                          "CUSTOM_ADDRESS": "27707",
                          "USER_PHONE": "79992327000",
                          "DO_NOT_CALL_BACK": "Y",
                          "ORDER_EXPORTED_1C": "Y",
                          "APP_VER": "Android",
                          "USER_BIRTHDAY": "27.09.1976",
                          "IS_MOBILE_ORDER": "Y",
                          "FAST_ORDER": "N",
                          "ADDRESS_JSON": "address= 12,  Россия улица Композиторов, Санкт-Петербург, &apartment=536&entrance=5&floor=15&domofon=536&elevator=Да",
                          "ADDRESS": "12,  Россия улица Композиторов, Санкт-Петербург, под. 5, эт. 15, кв. 536, код дом. 536, лифт. есть",
                          "CAR_COORDS": ",",
                          "DELIVERY_TIME": "09:00;23:00",
                          "DELIVERY_DATE": "03.01.2024 00:00:00",
                          "PHONE": "79992327000",
                          "EMAIL": "gm382014@gmail.com",
                          "CONTACT_PERSON": "Тест Тест"
                        },
                        "basket": [
                          {
                            "id": "332461",
                            "product": {
                              "id": "22063",
                              "url": "/catalog/voda-19l/komplekt-miks/"
                            },
                            "picture": "/upload/iblock/624/4g9bj2ktfba63bhoeazz8vxl54k950mi.png",
                            "title": "Комплект \"Микс\"",
                            "withInventory": false,
                            "isInventory": false,
                            "content": "Комплект \"Микс\"",
                            "quantityMax": 2494,
                            "quantity": 1,
                            "ratio": 1,
                            "isPack": false,
                            "price": 2290,
                            "oldPrice": 2862,
                            "itemPrice": 2290,
                            "itemOldPrice": 2862,
                            "discount": 20,
                            "discountActions": [],
                            "measureText": "наб",
                            "measureTextOrig": "наб",
                            "measureQuantity": 1,
                            "measurePrice": 2290,
                            "measureOldPrice": 2862,
                            "isComplect": true,
                            "sum": 2290
                          }
                        ]
                      },
                      "shipment": {
                        "id": 16,
                        "name": "Доставка ШЛ",
                        "sum": 0,
                        "currency": "RUB",
                        "sum_formated": null
                      },
                      "payment": {
                        "id": 25,
                        "name": "Онлайн оплата по карте",
                        "paid": false,
                        "sum": 2290,
                        "currency": "RUB",
                        "sum_formated": "2 290 руб."
                      }
                    },
                    {
                      "order": {
                        "id": 123456789,
                        "date": "22.12.2023 19:59:16",
                        "status": {
                          "ID": "C",
                          "NAME": "Отменен"
                        },
                        "price": 2290,
                        "currency": "RUB",
                        "discount_value": 0,
                        "user_comment": "тест тест тест 112233",
                        "price_formated": "2 290 руб.",
                        "payed": false,
                        "date_payed": null,
                        "canceled": true,
                        "links": [
                          {
                            "code": "copy",
                            "name": "Повторить",
                            "url": "/personal/order/?ID=124279&COPY_ORDER=Y"
                          }
                        ],
                        "properties": {
                          "CUSTOM_ADDRESS": "27707",
                          "USER_PHONE": "79992327000",
                          "DO_NOT_CALL_BACK": "Y",
                          "ORDER_EXPORTED_1C": "Y",
                          "APP_VER": "Android",
                          "USER_BIRTHDAY": "27.09.1976",
                          "IS_MOBILE_ORDER": "Y",
                          "FAST_ORDER": "N",
                          "ADDRESS_JSON": "address= 12,  Россия улица Композиторов, Санкт-Петербург, &apartment=536&entrance=5&floor=15&domofon=536&elevator=Да",
                          "ADDRESS": "12,  Россия улица Композиторов, Санкт-Петербург, под. 5, эт. 15, кв. 536, код дом. 536, лифт. есть",
                          "CAR_COORDS": ",",
                          "DELIVERY_TIME": "09:00;22:00",
                          "DELIVERY_DATE": "29.12.2023 00:00:00",
                          "PHONE": "79992327000",
                          "EMAIL": "gm382014@gmail.com",
                          "CONTACT_PERSON": "Тест Тест"
                        },
                        "basket": [
                          {
                            "id": "330277",
                            "product": {
                              "id": "22063",
                              "url": "/catalog/voda-19l/komplekt-miks/"
                            },
                            "picture": "/upload/iblock/624/4g9bj2ktfba63bhoeazz8vxl54k950mi.png",
                            "title": "Комплект \"Микс\"",
                            "withInventory": false,
                            "isInventory": false,
                            "content": "Комплект \"Микс\"",
                            "quantityMax": 2494,
                            "quantity": 1,
                            "ratio": 1,
                            "isPack": false,
                            "price": 2290,
                            "oldPrice": 2862,
                            "itemPrice": 2290,
                            "itemOldPrice": 2862,
                            "discount": 20,
                            "discountActions": [],
                            "measureText": "наб",
                            "measureTextOrig": "наб",
                            "measureQuantity": 1,
                            "measurePrice": 2290,
                            "measureOldPrice": 2862,
                            "isComplect": true,
                            "sum": 2290
                          }
                        ]
                      },
                      "shipment": {
                        "id": 16,
                        "name": "Доставка ШЛ",
                        "sum": 0,
                        "currency": "RUB",
                        "sum_formated": null
                      },
                      "payment": {
                        "id": 25,
                        "name": "Онлайн оплата по карте",
                        "paid": false,
                        "sum": 2290,
                        "currency": "RUB",
                        "sum_formated": "2 290 руб."
                      }
                    }
                  ]
                }
              }
            });
            //reject('Произошла ошибка');
        }, 1000);
    });
}

export default handleData;
