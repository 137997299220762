import {isWaiting, updateBasketCount, waitFinish, waitStart} from './index';

import axios from 'axios';
import Observer from 'common/scripts/observer';
import Utils from '../../common/scripts/utils';

const commonObserver = new Observer();
const PLURAL_WORDS = {
    package: ['упаковку', 'упаковки', 'упаковок'],
    products: ['товар', 'товара', 'товаров']
};

/**
 * Элементы управления карточки товара
 * @param {HTMLElement} card - html-элемент карточки товара
 */
class CatalogItem {
    constructor(card) {
        this.card = null;
        this.init(card);
        this.debouncedUpdateCart = Utils.debounce(this.doUpdateCart, 500);
    }

    /**
     * Инициализация компонента
     * @param {HTMLElement} card - html-элемент карточки товара
     */
    init(card) {
        this.card = card;
        this.actionAdd = card.querySelector('.j-add-item');
        this.actionStatic = card.querySelector('.j-item-static');
        this.actionActive = card.querySelector('.j-item-active');
        this.quantityMinus = card.querySelector('.j-quantity-minus');  // Кнопка уменьшения количества товара
        this.quantityPlus = card.querySelector('.j-quantity-plus');    // Кнопка увеличения количества товара
        this.quantityElem = card.querySelector('.j-catalog-card-quantity'); // DOM элемент для отображения количества товара
        this.priceTotalElem = card.querySelector('.j-catalog-card-price-total'); //DOM элемент для отображения итоговой суммы товара
        this.productPriceElem = card.querySelector('.j-product-price'); //DOM элемент с ценой товара в дата-атрибутах
        this.addToCartBtn = card.querySelector('.j-add-to-cart'); //Кнопка добавления товара в корзину
        this.cartElement = document.querySelector('.j-header-cart-icon'); //DOM элемент корзины в шапке
        this.discountItem = document.querySelector('.js-catalog-discount'); //DOM с информацией о скидке
        this.discountQuantity = 0;

        this.productId = this.addToCartBtn.dataset.itemId; //ID товара

        this.commonObserver = new Observer();
        this.bindEvents();
        this.updateProdDetails();
    }

    /**
     * Устанавливает привязку событий
     */
    bindEvents() {
        this.initQuantityEvents();
        this.initAddToCartEvent();

        if (this.discountItem) {
            this.discountItem.addEventListener('click', this.getDiscount.bind(this));
        }
    }

    getDiscount(event) {
        event.preventDefault();

        const currQuantity = parseInt(this.quantityElem.textContent, 10);

        this.updateQuantity(this.discountQuantity + currQuantity);
    }

    /**
     * Обновляет данные о количестве товара в зависимости от информации о мини-корзине
     */
    updateProdDetails() {
        const {basketByProd, basketData} = window;
        const inBasket = basketByProd ? basketByProd[this.productId] : null;
        const cartData = inBasket && basketData ? JSON.parse(window.basketData) : null;
        const currProd = cartData ? cartData.products.find(({id}) => id === inBasket.basket_id) : null;

        if (inBasket && (inBasket.quantity > 0)) {
            this.quantityElem.textContent = inBasket.quantity;
            this.handleStyleDisplay(['none', 'flex']);
            this.setBtnsDisable(false);
            this.renderDiscountText(currProd ? currProd.discountActions.map((data) => ({...data, ratio: currProd.ratio})) : [], inBasket.quantity);
        }
    }

    /**
     * Изменяет знаяение display для блока
     * @param {Array} arr - массив значений свойства display
     */
    handleStyleDisplay(arr) {
        const items = [this.actionStatic, this.actionActive];

        if (!items.every((item) => Boolean(item))) {
            return;
        }

        arr.forEach((value, index) => items[index].style.display = value);
    }

    /**
     * Выводит текст с информацией о скидке
     * @param {string} str - информация о скидке
     */
    setDiscountText(str = '') {
        if (!this.discountItem) {
            return;
        }

        this.discountItem.textContent = str;
    }

    /**
     * Формирует текст о скидке в зависимости от количествва товара
     * @param {Array} data - массив с данными из дата-атрибута товара
     * @param {number} prodQuantity - количество товара
     */
    renderDiscountText(data, prodQuantity) {
        let currentAction = null;

        for (const action of data) {
            if (prodQuantity < action.quantity) {
                currentAction = action;
                break;
            }
        }

        if (!currentAction || !this.discountItem) {
            this.setDiscountText();
            this.discountQuantity = 0;

            return;
        }

        const remainingQuantity = currentAction.quantity - prodQuantity;

        this.discountQuantity = remainingQuantity;
        this.setDiscountText(`Добавьте еще ${remainingQuantity} ${currentAction.ratio > 1 ? Utils.pluralWord(remainingQuantity, PLURAL_WORDS.package) : Utils.pluralWord(remainingQuantity, PLURAL_WORDS.products)} и получите скидку ${currentAction.discount}%`);
    }

    /**
     * Функция для инициализации событий изменения количества товара
     */
    initQuantityEvents() {
        this.quantityMinus.addEventListener('click', () => {
            const currQuantity = parseInt(this.quantityElem.textContent, 10);
            const newQuantity = currQuantity - 1;

            this.updateQuantity(newQuantity);
        });

        this.quantityPlus.addEventListener('click', () => {
            const currQuantity = parseInt(this.quantityElem.textContent, 10);
            const newQuantity = currQuantity + 1;

            this.updateQuantity(newQuantity);
        });
    }

    /**
     * Функция для инициализации события добавления товара в корзину
     */
    initAddToCartEvent() {
        this.addToCartBtn.addEventListener('click', () => {
            document.location.href = '/personal/cart/';
        });

        if (this.actionAdd) {
            this.actionAdd.addEventListener('click', () => {
                const newQuantity = parseInt(this.quantityElem.textContent, 10);

                this.updateQuantity(newQuantity);
            });
        }
    }

    /**
     * Функция для обновления количества товара и отображения актуальной суммы
     * @param {number} newQuantity - Новое количество товара
     */
    updateQuantity(newQuantity) {
        if (this.productPriceElem) {
            const price = parseInt(this.productPriceElem.dataset.price.replace(/\s+/g, ''), 10);
            const totalPrice = newQuantity * price;
            const formattedTotalPrice = totalPrice.toLocaleString('ru-RU');

            this.priceTotalElem.textContent = this.priceTotalElem.classList.contains('j-product-price')
                ? `${formattedTotalPrice} руб.`
                : `${formattedTotalPrice} ₽`;
        }

        this.quantityElem.textContent = newQuantity <= 1 ? '1' : newQuantity.toString();
        this.quantityMinus.disabled = newQuantity <= 1;
        this.debouncedUpdateCart(newQuantity);
    }

    /**
     * Изменение видимости элементов управления товара
     * @param {Array} arr - массив с данными товаров
     * @param {string} productId - id товара
     */
    handleProductsList(arr, productId) {
        const styleValues = ['flex', 'none'];
        const item = arr.find(({product}) => product.id === productId);
        const [ratio, discountActions, quantity] = arr.indexOf(item) === -1
            ? [...Array(2), 0]
            : [item.ratio, item.discountActions, item.quantity];

        this.handleStyleDisplay(quantity < 1 ? styleValues : styleValues.reverse());
        this.renderDiscountText(quantity < 1 ? [] : discountActions.map((data) => ({...data, ratio})), quantity);
    }

    /**
     * Обработка данных корзины
     * @param {number} quantity - новое количество товара
     * @returns {Promise<void>}
     */
    async doUpdateCart(quantity){
        // анимации добавления на мобилке не будет
        if (!Utils.isBreakpoint(0, 767)) {
            if (this.addToCartBtn.classList.contains('button-with-icon')) {
                this.animateButtonToCart(this.addToCartBtn, this.cartElement);
            }
        }

        this.setBtnsDisable();

        try {
            let action = quantity > 0 ? 'set' : 'del';
            await axios.post('/ajax/basketActionApi.php', {
                product: this.productId,
                quantity,
                action: action
            }).then(({data}) => {
                if (data.status !== 'error') {
                    const { products } = data.data;
                    this.setBtnsDisable(false);
                    this.updateBasketCount(data);
                    this.handleProductsList(products, this.productId);

                    // Публикация события обновления корзины
                    this.commonObserver.publish('updateCart', data.data);
                    console.log('updateCartpublish');
                } else {
                    const message = data.message || 'Произошла ошибка, попробуйте чуть позже'
                    this.commonObserver.publish('showAlert', message);
                }
            })
        } catch (error) {
            // Обработка ошибки, если запрос не выполнен успешно
            console.error('Произошла ошибка при добавлении товара:', error);
            this.commonObserver.publish('showAlert', 'Произошла ошибка, попробуйте чуть позже');
        } finally {
            // Включить кнопку после завершения запроса (независимо от успешности)
            this.setBtnsDisable(false);
        }

    }

    /**
     * Меняет состояние disabled для кнопок
     * @param {boolean} isDisabled - состояние кнопки
     */
    setBtnsDisable(isDisabled = true){
        [
            this.actionAdd,
            this.addToCartBtn,
            this.quantityPlus,
            this.quantityMinus
        ].forEach(item => item.disabled = isDisabled);
    }

    /**
     * Анимирует кнопку, создавая копию и перемещая её к корзине с уменьшением размеров
     * @param {Element} button - кнопка, которую необходимо анимировать
     * @param {HTMLElement} targetElement - элемент-цель, к которому перемещается анимированная кнопка (например, корзина в хедере)
     */
    animateButtonToCart(button, targetElement) {
        // Клонирование кнопки и определение координат исходной кнопки и целевого элемента
        const buttonClone = button.cloneNode(true);
        const buttonRect = button.getBoundingClientRect();
        const targetRect = targetElement.getBoundingClientRect();

        // Установка стилей для клонированной кнопки
        Object.assign(buttonClone.style, {
            position: 'fixed',
            top: `${buttonRect.top}px`,
            left: `${buttonRect.left}px`,
            width: `${buttonRect.width}px`,
            height: `${buttonRect.height}px`,
            zIndex: '9999',
            transition: 'all 1.2s ease-in-out',
        });

        document.body.appendChild(buttonClone);

        // Запуск анимации перемещения
        requestAnimationFrame(() => {
            Object.assign(buttonClone.style, {
                top: `${targetRect.top}px`,
                left: `${targetRect.left}px`,
                width: '0',
                height: '0',
                opacity: '0',
            });

            // Удаление клонированной кнопки после завершения анимации
            setTimeout(() => {
                document.body.removeChild(buttonClone);
            }, 1200);
        });
    }

    /**
     * Удаляет товар из мини-корзины (НИГДЕ не используется)
     * @param {Event} event - Объект события
     * @param {HTMLElement} deleteFromBasketElement - Элемент, который удаляем из корзины
     */
    deleteFromMiniBasket(event, deleteFromBasketElement) {
        event.preventDefault();
        const btn = deleteFromBasketElement;

        if (isWaiting(btn)) {
            return;
        }
        waitStart(btn);

        const productID = btn.getAttribute('data-product');
        const payload = {
            basket: productID,
            action: 'delete'
        };

        try {
            axios.post('/ajax/basketActionApi.php', payload)
                .then(({data}) => {
                    updateBasketCount(data);
                    commonObserver.publish('updateCart', data.data);
                })
                .catch((error) => {
                    console.error('Ошибка при удалении товара:', error);
                    commonObserver.publish('showAlert', 'Произошла ошибка, попробуйте чуть позже');
                })
                .finally(() => {
                    waitFinish(btn);
                });
        } catch (error) {
            console.error('Ошибка при удалении товара:', error);
        }
    }

    /**
     * Обновляет число товаров в мини-корзине (НЕ используется для vue-корзины, только в каталоге)
     * @param {Object} data - данные о корзине
     */
    updateBasketCount(data) {
        const cartElementCounter = document.querySelectorAll('.j-cart-count');
        const {products} = data.data;
        const productsCount = products.reduce((acc, product) => acc + product.quantity, 0);

        cartElementCounter.forEach((el) => {
            el.textContent = productsCount.toString();

            if (productsCount > 0) {
                if (!el.classList.contains('is-active')) {
                    el.classList.add('is-active');
                }
            } else {
                el.classList.remove('is-active');
            }
        })
    }
}

export default CatalogItem;
