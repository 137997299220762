function getAvailableDates() {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        status: 200,
        data: {
          state: 1,
          zone: {
            ID: "1643",
            NAME: "1мещанский",
            DESCRIPTION: "",
            CODE: "339",
            SCHEDULE: {
              "17-03-2024": ["06:00-18:00", "6:00-12:00", "12:00-16:00"],
              "19-03-2024": ["06:00-19:00", "6:00-12:00", "12:00-16:00"],
              "20-03-2024": ["06:00-19:00", "6:00-12:00", "12:00-16:00"],
              "21-03-2024": ["06:00-19:00", "6:00-12:00", "12:00-16:00"],
              "22-03-2024": ["06:00-19:00", "6:00-12:00", "12:00-16:00"],
              "24-03-2024": ["06:00-18:00", "6:00-12:00", "12:00-16:00"],
              "26-03-2024": ["06:00-19:00", "6:00-12:00", "12:00-16:00"],
              "27-03-2024": ["06:00-19:00", "6:00-12:00", "12:00-16:00"],
              "28-03-2024": ["06:00-19:00", "6:00-12:00", "12:00-16:00"],
              "29-03-2024": ["06:00-19:00", "6:00-12:00", "12:00-16:00"],
              "31-03-2024": ["06:00-18:00", "6:00-12:00", "12:00-16:00"],
              "02-04-2024": ["06:00-19:00", "6:00-12:00", "12:00-16:00"],
              "03-04-2024": ["06:00-19:00", "6:00-12:00", "12:00-16:00"],
            },
            XML_ID: "372183ea-b7bb-11e5-a8b9-6805ca3a569d",
            STORE: "73ad9ee6-8848-11e5-b9fe-005056bb4852",
            "SCHEDULE-VERSION": "V1",
          },
          store: {
            ID: "246",
            TITLE: "Новый склад",
            ADDRESS: "адрес",
            DESCRIPTION: "",
            PRODUCT_AMOUNT: "8",
            ELEMENT_ID: "29984",
          },
        },
      });
    }, 350);
  });
}

export default getAvailableDates;
