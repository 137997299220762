import axios from 'axios';
import Utils from "common/scripts/utils";

/**
 * Обновляет результаты поиска на странице с заданными данными
 * @param data - данные от сервера
 */
async function updateSearchResults(data) {
    const dataWrapperArr = Array.from(document.querySelectorAll('.global-search__result-wrapper'));
    dataWrapperArr.forEach((wrapper)=>{
        wrapper.innerHTML = ''
        wrapper.innerHTML = data
    })
}

/**
 * Отображает результаты поиска
 * @param {HTMLElement} resultWrapper - элемент, содержащий результаты поиска
 */
function showResults(resultWrapper) {
    resultWrapper.classList.add('is-active');
}

/**
 * Скрывает результаты поиска
 * @param {HTMLElement} resultWrapper - элемент, содержащий результаты поиска
 */
function hideResults(resultWrapper) {
    resultWrapper.classList.remove('is-active');
}

/**
 * Фейковый запрос к бэкенду для получения результатов поиска
 * @param {string} query - поисковый запрос
 * @returns {Promise<Object>} результаты поиска
 */
async function fetchSearchResults(query) {
    const formData = new FormData();
    formData.append('s', query);
    try {
        const { data } = await axios({
            method: 'post',
            url: '/ajax/catalogSearch.php',
            data: formData,
        });
        return data;
    } catch (error) {
        console.error("Не удалось получить результаты поиска:", error);
        return null; // Возвращаем null, если произошла ошибка
    }
}


/**
 * Обработчик изменения значения поля поиска
 * @param {HTMLInputElement} input - поле ввода поискового запроса
 * @param {HTMLElement} resultWrapper - элемент, содержащий результаты поиска
 */
async function handleInputChange(input, resultWrapper) {
    const query = input.value.trim();
    if (query.length === 0) {
        hideResults(resultWrapper);
    } else {
        const results = await fetchSearchResults(query);
        if (results) {
            // console.log(results);
            await updateSearchResults(results);
            showResults(resultWrapper);
        } else {
            console.error("Не удалось получить результаты поиска");
        }
    }
}



/**
 * Инициализация функции глобального поиска
 * @param {HTMLFormElement} searchForm - форма поиска
 */
export const initGlobalSearch = (searchForm) => {
    const input = searchForm.querySelector('.j-global-search-input');
    const resetBtn = searchForm.querySelector('.j-reset-input');
    const resultWrapper = searchForm.querySelector('.global-search__result-wrapper');

    input.addEventListener('input', Utils.debounce(() => handleInputChange(input, resultWrapper), 300));

    searchForm.addEventListener('keypress', function(e) {
        if (e.keyCode === 13) {
            e.preventDefault();
        }
    });

    resetBtn.addEventListener('click', () => {
        input.value = '';
        hideResults(resultWrapper);
    });
};
