const ORDER_PARAMS_KEYS = ['address', 'addressCode', 'name', 'phone', 'userComment'];

const handleAuth = (secId) => {
    const section = document.querySelector(`#${secId}`);
    const dataset = section && section.dataset ? section.dataset : null;

    return new Promise((resolve, reject) => {
        dataset
            ? resolve({
                isLoggedIn: Boolean(dataset.isLoggedIn),
                isPhoneExist: Boolean(dataset.isPhoneExist)
            })
            : reject({message: `Не удалось найти секцию с идентификатором '${secId}'`});
    });
};

const getOrderParams = (isResetHandler = false) => {
    if (isResetHandler) {
        ORDER_PARAMS_KEYS.forEach((key) => {
            if (localStorage.getItem(key) !== null) {
                localStorage.removeItem(key);
            }
        });

        return;
    }

    const isOrderDataExist = ORDER_PARAMS_KEYS
        .reduce((acc, key) => acc && localStorage.getItem(key) !== null, true);
    const data = isOrderDataExist
        ? ORDER_PARAMS_KEYS
            .reduce((acc, key) => ({...acc, [key]: '' || localStorage.getItem(key)}), {})
        : null;

    console.log(ORDER_PARAMS_KEYS.reduce((acc, key) => ({...acc, [key]: localStorage.getItem(key)}), {}));
    console.log({isOrderDataExist});

    return new Promise((resolve) => {
        setTimeout(() => {
            resolve({
                isSucceed: Boolean(data),
                data
            });
        }, 200);
    });
};

export {
    ORDER_PARAMS_KEYS,
    handleAuth,
    getOrderParams
};
