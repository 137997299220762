<template>
  <form
    class="v-auth-form"
    @submit.prevent="submitHandler"
    novalidate
  >
    <div class="v-auth-form__head">
      <h3 class="v-auth-form__title">{{ formTitleText }}</h3>
      <p class="v-auth-form__subtitle" v-html="formSubtitleText"></p>
    </div>

    <div
      v-if="isPhoneFormType"
      class="v-auth-form__content"
    >
      <v-input
        v-model="phone"
        placeholder="Номер телефона"
        :is-error="errors?.phone?.length > 0"
        :hint="errors.phone"
        input-type="tel"
        type="tel"
        autocomplete="tel"
      />

      <div class="v-captcha__wrapper">
        <img :src="captchaImg" @click="reloadCaptchaImg()"
             alt="captcha"
             :class="{'disabled': stateOfCaptcha}"
             class="v-captcha__image">
        <v-input
            v-model="captchaInput"
            :error="errors.captcha"
            @input="validateCaptcha(captchaInput)"
            :is-error="errors?.captcha?.length > 0"
            :hint="errors?.captcha?.length > 0 ? errors.captcha : 'Введите символы с картинки'"
            placeholder="Код"
            type="text"
        />
      </div>

      <v-button
        view="filled"
        color="secondary"
        :is-full-width="true"
        :disabled="!(captchaInput.length && phone.length && !errors.phone)"
        type="submit"
      >
        Получить SMS-код
      </v-button>
    </div>
    <div
      v-else-if="isSmsFormType"
      class="v-auth-form__content"
    >
      <v-input
        v-model="sms"
        :placeholder="'SMS-код'"
        :is-error="errors?.sms?.length > 0"
        :hint="errors.sms"
        input-type="sms"
      />

      <p
        v-if="showTimer"
        class="v-auth-form-timer"
      >Получить код повторно можно будет через <span>{{ formattedTimeLeft }}</span></p>
      <v-button
        v-else
        view="filled"
        color="secondary"
        :is-full-width="true"
        type="submit"
        @click="startTimer"
      >
        Получить SMS-код повторно
      </v-button>
    </div>
    <div
      v-else-if="isEmailFormType"
      class="v-auth-form__content"
    >
      <v-input
        v-model="email"
        placeholder="Email"
        :is-error="errors?.email?.length > 0"
        :hint="errors.email"
        input-type="email"
        type="email"
        autocomplete="email"
      />
      <v-input
        v-model="password"
        placeholder="Пароль"
        :is-error="errors?.password?.length > 0"
        :type="'password'"
        :hint="errors.password"
        input-type="password"
        autocomplete="password"
      />

      <v-button
        view="filled"
        color="secondary"
        :is-full-width="true"
        :disabled="!isDisabled"
        type="submit"
      >
        Войти
      </v-button>

      <v-button
        view="ghost"
        color="secondary"
        :is-full-width="true"
        @click="openResetFormType"
      >
        Забыли пароль?
      </v-button>
    </div>
    <div
      v-else-if="isResetFormType"
      class="v-auth-form__content"
    >
      <template v-if="!newPasswordMessage">
        <v-input
            v-model="email"
            :placeholder="'Email'"
            :is-error="errors?.email?.length > 0"
            :hint="errors.email"
            input-type="email"
            type="email"
            autocomplete="email"
        />
        <v-button
            view="filled"
            color="secondary"
            :is-full-width="true"
            :disabled="disableResetPswBtn"
            type="submit"
        >
          Сбросить пароль по почте
        </v-button>
      </template>
      <template v-else>
        <p class="v-auth-form__subtitle">
          Вам на почту отправлено письмо для сброса пароля.
        </p>
      </template>
      <v-button
        view="ghost"
        color="secondary"
        :is-full-width="true"
        @click="openTryAgainFormType"
      >
        У меня нет доступа к почте
      </v-button>
    </div>
    <div
      v-else-if="isTryAgainFormType"
      class="v-auth-form__content"
    >
      <v-button
        view="filled"
        color="secondary"
        :is-full-width="true"
        @click="setOriginalFormState"
      >
        Попробовать еще раз
      </v-button>
    </div>

    <div class="v-auth-form__row">
      <a class="v-auth-form-link" href="/registration/">Нет аккаунта? Регистрируйтесь</a>
      <v-form-separator />
    </div>

    <div class="v-auth-form__footer">
      <v-button
        view="filled"
        color="plain"
        :is-full-width="true"
        :is-with-icon="true"
        type="button"
        @click="toggleFormType"
      >
        <icon-phone v-if="isEmailFormType || isResetFormType || isTryAgainFormType" />
        <icon-email v-else />

        {{ formButtonText }}
      </v-button>

      <v-button
        view="filled"
        color="blue"
        :is-full-width="true"
        :is-with-icon="true"
        type="button"
        @click="openLink(this.socialLinks?.VKontakte)"
      >
        <icon-vk />
        Войти через VK
      </v-button>

      <v-button
        view="filled"
        color="black"
        :is-full-width="true"
        :is-with-icon="true"
        type="button"
        @click="openLink(this.socialLinks?.apple)"
      >
        <icon-apple />
        Войти через Apple
      </v-button>
    </div>
  </form>
</template>

<script>
import VInput from 'components/input/input.vue';
import VButton from 'components/button/button.vue';
import VFormSeparator from 'components/form-separator/form-separator.vue';
import IconPhone from '../../assets/icons/icon-phone.vue';
import IconEmail from '../../assets/icons/icon-email.vue';
import IconVk from '../../assets/icons/icon-vk.vue';
import IconApple from '../../assets/icons/icon-apple.vue';
import Observer from "common/scripts/observer";
const commonObserver = new Observer();

import { mapActions, mapGetters } from 'vuex';

// Типы формы: номер телефона, смс, email/пароль, cброс пароля
const FORM_TYPES = {
  phone: 'phone',
  sms: 'sms',
  email: 'email',
  reset: 'reset',
  tryAgain: 'tryAgain'
};

export default {
  name: 'v-auth-form',
  components: {
    VInput,
    VButton,
    VFormSeparator,
    IconPhone,
    IconEmail,
    IconVk,
    IconApple,
  },
  data() {
    return {
      newPasswordMessage: false,
      disableResetPswBtn: false, // дизейбл кнопки отправки сброса пароля
      socialLinks: null,
      captchaInput: '',
      showTimer: false,
      timeLimit: 60,
      timePassed: 0,
      timerInterval: null,
      errors: [],
      phone: '',
      sms: '',
      email: '',
      password: '',
      currentFormType: FORM_TYPES.phone,
      texts: {
        phoneOrSms: {
          title: 'Вход в аккаунт',
          subtitle: 'Введите номер своего мобильного телефона и&nbsp;мы&nbsp;отправим вам SMS-код для входа'
        },
        email: {
          subtitle: 'Вход по&nbsp;электронной почте доступен только для&nbsp;ранее зарегистрированных пользователей'
        },
        resetPassword: {
          title: 'Сброс пароля',
          subtitle: 'Введите адрес электронной почты, который вы указали при регистрации, и&nbsp;мы отправим на&nbsp;него ссылку для сброса пароля. Если письма нет, проверьте папку «спам» или свяжитесь с&nbsp;нашей <a href="/" target="_blank">службой поддержки</a>'
        },
        tryAgain: {
          subtitle: 'Если у Вас больше нет доступа к&nbsp;электронной почте, то&nbsp;к&nbsp;сожалению мы не сможем сбросить Ваш пароль :( <br /><br />Вы можете попробовать еще раз ввести пароль или войти по&nbsp;номеру телефона, который привязан к&nbsp;Вашему аккаунту.'
        }
      }
    };
  },
  computed: {
    ...mapGetters('auth', ['captchaImg', 'stateOfCaptcha']),

    formTitleText() {
      if (this.currentFormType === FORM_TYPES.reset || this.currentFormType === FORM_TYPES.tryAgain) {
        return this.texts.resetPassword.title;
      }

      return this.texts.phoneOrSms.title;
    },
    formSubtitleText() {
      let subtitleText = null;

      switch (this.currentFormType) {
        case `${FORM_TYPES.phone}`:
          subtitleText = this.texts.phoneOrSms.subtitle;
          break;
        case `${FORM_TYPES.sms}`:
          subtitleText = this.texts.phoneOrSms.subtitle;
          break;
        case `${FORM_TYPES.email}`:
          subtitleText = this.texts.email.subtitle;
          break;
        case `${FORM_TYPES.reset}`:
          subtitleText = this.texts.resetPassword.subtitle;
          break;
        case `${FORM_TYPES.tryAgain}`:
          subtitleText = this.texts.tryAgain.subtitle;
          break;
      }

      return subtitleText;
    },
    formButtonText() {
      return this.isEmailFormType || this.isResetFormType || this.isTryAgainFormType ? 'Войти по номеру телефона' : 'Войти по Email';
    },
    isPhoneFormType() {
      return this.currentFormType === FORM_TYPES.phone;
    },
    isSmsFormType() {
      return this.currentFormType === FORM_TYPES.sms;
    },
    isEmailFormType() {
      return this.currentFormType === FORM_TYPES.email;
    },
    isResetFormType() {
      return this.currentFormType === FORM_TYPES.reset;
    },
    isTryAgainFormType() {
      return this.currentFormType === FORM_TYPES.tryAgain;
    },
    isDisabled() {
      return this.email.length > 0 && this.password.length > 0;
    },
    formattedTimeLeft() {
      const timeLeft = this.timeLeft;
      const minutes = Math.floor(timeLeft / 60);
      let seconds = timeLeft % 60;

      if (seconds < 10) {
        seconds = `0${seconds}`;
      }

      return `0${minutes}:${seconds}`;
    },
    timeLeft() {
      return this.timeLimit - this.timePassed;
    }
  },
  watch: {
    phone(newVal) {
      this.validatePhone(newVal);
    },
    sms(newVal) {
      this.validateSms(newVal);

      if (newVal.length === 4) {
        this.authBySendedSms();
        console.log(newVal.length);
      }
    },
    email(newVal) {
      this.validateEmail(newVal);
    },
    password(newVal) {
      this.validatePassword(newVal);
    },
    timeLeft(newVal) {
      if (newVal === 0) {
        this.onTimesUp();
      }
    },
  },
  methods: {
    ...mapActions('auth', ['sendSms', 'authBySms', 'authByEmail', 'submitCaptcha', 'updateCaptchaImg', 'reloadCaptchaImg', 'resetPasswordMail']),

    openLink(link) {
      window.location.href = link;
    },

    validatePhone(value) {
      const phoneTypeSymbol = value.split('')[4];

      if (value.length === 0) {
        this.errors['phone'] = 'Поле обязательно для заполнения';
      } else if (value.length < 18 || (phoneTypeSymbol != 9 && phoneTypeSymbol != 8 && phoneTypeSymbol != 4 && phoneTypeSymbol != 3)) {
        this.errors['phone'] = 'Неверно введен телефон';
      } else {
        this.errors['phone'] = '';
      }
    },
    validateSms(value) {
      if (value.length < 4) {
        this.errors['sms'] = 'Код должен содержать не менее 4 символов';
      } else {
        this.errors['sms'] = '';
      }
    },
    validateEmail(value) {
      const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

      if (value.length === 0) {
        this.errors['email'] = 'Поле обязательно для заполнения';
      } else if (re.test(value)) {
        this.errors['email'] = '';
      } else {
        this.errors['email'] = 'Неверно введен e-mail';
      }
    },
    validatePassword(value) {
      if (value.length === 0) {
        this.errors['password'] = 'Поле обязательно для заполнения';
      } else if (value.length < 6) {
        this.errors['password'] = 'Пароль должен содержать не менее 6 символов';
      } else {
        this.errors['password'] = '';
      }
    },
    validateCaptcha(value) {
      if (value.length === 0) {
        this.errors['captcha'] = 'Поле обязательно для заполнения';
      } else {
        this.errors['captcha'] = '';
      }
    },
    onTimesUp() {
      clearInterval(this.timerInterval);
      this.showTimer = !this.showTimer;
    },
    async startTimer() {
      this.showTimer = true;
      this.timeLimit = 60;
      this.timePassed = 0;
      this.timerInterval = setInterval(() => (this.timePassed += 1), 1000);
    },
    toggleFormType() {
      if (this.currentFormType === FORM_TYPES.phone || this.currentFormType === FORM_TYPES.sms) {
        this.currentFormType = FORM_TYPES.email;
      } else {
        this.currentFormType = FORM_TYPES.phone;
      }
    },
    openTryAgainFormType() {
      this.currentFormType = FORM_TYPES.tryAgain;
    },
    openResetFormType() {
      this.currentFormType = FORM_TYPES.reset;
    },
    setOriginalFormState() {
      this.currentFormType = FORM_TYPES.email;
      this.phone = '';
      this.sms = '';
      this.email = '';
      this.password = '';

    },

    async submitHandler(id, newComp) {
      switch (this.currentFormType) {
        case `${FORM_TYPES.phone}`:
          this.validatePhone(this.phone);
          this.validateCaptcha(this.captchaInput);

          if (this.errors.phone.length === 0) {
            if (this.timePassed === 0) { this.startTimer(); }

            try {
              const captchaResponse = await this.submitCaptcha({captchaInput: this.captchaInput});
              if (captchaResponse?.data?.status === 'ok') {
                const smsResponse = await this.sendSms({ phone: this.phone });
                console.log(smsResponse);

                if (smsResponse?.data?.data?.status === 'success') {
                  this.currentFormType = FORM_TYPES.sms;
                } else {
                  if (smsResponse?.data?.data?.error?.length) {
                    this.errors.phone = smsResponse?.data?.data?.error
                  } else {
                    this.errors.phone = 'Ошибка, попробуйте позже'
                  }
                }
              } else {
                // В случае ошибки обновляем капчу и записываем ошибку
                this.errors.captcha = 'Символы не совпадают';
                this.captchaImg = this.updateCaptchaImg({err: true, response: captchaResponse});
              }
            } catch (error) {
              commonObserver.publish('showAlert', 'Произошла ошибка, попробуйте чуть позже');
            }
          }

          break;
        case `${FORM_TYPES.sms}`:
          try {
            await this.authBySms({ phone: this.phone, sms_code: this.sms }).then(
              (response) => {
                if (response?.data?.data?.error?.length) {
                  this.errors.sms = response?.data?.data?.error || 'Ошибка, попробуйте позже'
                } else {
                  // При успехе - перезагружаем страницу
                  location.reload();
                }
              }
            );
          } catch (error) {
            commonObserver.publish('showAlert', 'Произошла ошибка, попробуйте чуть позже');
          }

          break;
        case `${FORM_TYPES.email}`:
          this.validateEmail(this.email);
          this.validatePassword(this.password);

          if (this.errors.email.length === 0 && this.errors.password.length === 0) {
            try {
              await this.authByEmail({email: this.email, password: this.password}).then(
                  (response)=>{
                    if (response?.data?.state === 0) {
                      const { MESSAGE } = response?.data?.data;
                      const errorMess = MESSAGE ? MESSAGE.replace(/<br>/g, '') : 'Ошибка, попробуйте позже';
                      commonObserver.publish('showAlert', errorMess);

                      this.errors.email = errorMess;
                    } else {
                      location.reload();
                    }
                  })
            } catch (error) {
              commonObserver.publish('showAlert', 'Произошла ошибка, попробуйте чуть позже');
            }
          }

          break;
        case `${FORM_TYPES.reset}`:
          this.validateEmail(this.email);

          if (this.errors.email.length) {
            return;
          }

          this.disableResetPswBtn = true;

          try {
            const response = await this.resetPasswordMail({ email: this.email });

            this.disableResetPswBtn = false;

            if (response?.data?.status === 'OK') {
              this.newPasswordMessage = true; // Все хорошо, показываем, что отослали на почту
            } else {
              const message = response?.data?.message || 'Произошла ошибка, попробуйте чуть позже';
              commonObserver.publish('showAlert', message);
            }
          } catch (err) {
            this.disableResetPswBtn = false;
            console.error('Ошибка при попытке сброса пароля:', err);
            commonObserver.publish('showAlert', 'Произошла ошибка, попробуйте чуть позже');
          }

          break;
      }
    },
    async authBySendedSms() {
      try {
        await this.authBySms({
          phone: this.phone,
          sms_code: this.sms
        }).then((response) => {
            if (response.data.data.status !== 'error') {
              // При успехе - перезагружаем страницу
              location.reload();
            } else {
              if (response.data?.data?.status === 'error') {
                commonObserver.publish('showAlert', response.data.data.error);
              } else {
                commonObserver.publish('showAlert', 'Произошла ошибка, попробуйте чуть позже');
              }
            }
          }
        )
      } catch (error) {
        commonObserver.publish('showAlert', 'Произошла ошибка, попробуйте чуть позже');
        console.error('Уведомление об ошибке:', error);
      }
    },
    async resendSmsCode() {
      try {
        await this.authBySms({ phone: this.phone, sms_code: this.sms }).then(
          (data) => {
            console.log(data);
          }
        );
      } catch (error) {
        console.error('Уведомляем об ошибке');
      }
    }
  },
  mounted() {
    this.updateCaptchaImg({err: false, response: {}});

    if (window.linkSocialServices.length) {
      let socialLinksRaw = JSON.parse(window.linkSocialServices);
      this.socialLinks = {};

      for (let key in socialLinksRaw) {
        let match = socialLinksRaw[key].match(/'(.*?)'/);
        if (match) {
          this.socialLinks[key] = match[1];
        }
      }
    }
  },
};

</script>
