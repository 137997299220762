/**
 * Зависимости
 */
import Swiper, { Navigation, Pagination, EffectFade, Autoplay } from "swiper";
import 'swiper/css/bundle';

/**
 *
 * Подключение слайдера на детальной странице новостей
 */
export const initNewsDetailSlider = (container) => {
    const swiper = new Swiper(container, {
        modules: [Navigation, Pagination, EffectFade],
        effect: "fade",
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        pagination: {
            el: ".swiper-pagination",
            type: "bullets",
        },
    });
};

/**
 *
 * Подключение слайдера на главной в баннере
 */
export const initHeroSlider = (container) => {
    const swiper = new Swiper(container, {
        modules: [Navigation, EffectFade],
        effect: "fade",
        loop: true,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
    });
};

/**
 *
 * Подключение слайдера на главной в разделе с акциями
 */
export const initActionsSlider = (container) => {
    const swiper = new Swiper(container, {
        modules: [Navigation, Pagination, Autoplay],
        loop: true,
        autoplay: {
            delay: 10000
        },
        /*
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        pagination: {
            el: ".swiper-pagination",
            type: "bullets",
        }
        */
    });
};

export const initPlainSlider = (
    container,
    navParams = {
        navNextSel: '.swiper-nav-btn_dir_next',
        navPrevSel: '.swiper-nav-btn_dir_prev'
    }
) => {
    const swiperNav = {
        nextEl: container.parentNode.querySelector(navParams.navNextSel),
        prevEl: container.parentNode.querySelector(navParams.navPrevSel)
    };
    const swiper = new Swiper(container, {
        modules: [Navigation],
        slidesPerView: 1,
        loop: true,
        navigation: {
            nextEl: swiperNav.nextEl,
            prevEl: swiperNav.prevEl
        }
    });
};

/**
 *
 * Подключение слайдера для табов
 */
export const initTabsSlider = (container) => {
    const swiper = new Swiper(container, {
        slidesPerView: 'auto',
        loop: false
    });
};

/**
 *
 * Подключение слайдера на главной в акциях
 */
export const initBestDealsSlider = (container) => {
    const swiper = new Swiper(container, {
        modules: [Navigation, Pagination],
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        pagination: {
            el: ".swiper-pagination",
            type: "bullets",
        },
        slidesPerView: 1, // По умолчанию отображается 1 слайд
        breakpoints: {
            768: {
                slidesPerView: 2, // Отображаем сразу 2 слайда
            },
        },
    });
};

/**
 *
 * Подключение слайдера на стр. контроля качества
 */
export const initTechSlider = (container) => {
    const swiper = new Swiper(container, {
        modules: [Navigation, Pagination],
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        slidesPerView: 1,
        breakpoints: {
            768: {
                slidesPerView: 3,
            },
            1024: {
                slidesPerView: 4,
            },
        },
    });
};

/**
 *
 * Подключение слайдера на стр. о воде в разделе производства
 */
export const initProdSlider = (container) => {
    const swiper = new Swiper(container, {
        modules: [Navigation, Pagination],
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        pagination: {
            el: ".j-prod-pagination",
            type: "bullets",
        },
        slidesPerView: 1,
        breakpoints: {
            768: {
                slidesPerView: 2,
            },
            1024: {
                slidesPerView: 3,
            },
        },
    });
};

/**
 * 
 * Подключение слайдера на стр. о компании
 */
export const initControlSlider = (container) => {
    const swiper = new Swiper(container, {
        modules: [Navigation, Pagination],
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        pagination: {
            el: ".j-control-pagination",
            type: "bullets",
        },
        slidesPerView: 1,
        spaceBetween: 16,
        loop: true
    });
}

/**
 * 
 * Подключение карусели для кнопок фильтра
 */
export const initFilterCarousel = (container) => {
    const swiper = new Swiper(container, {
        slidesPerView: 'auto',
        spaceBetween: 16,
        loop: false
    });
}