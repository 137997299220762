import axios from 'axios';
import faqItemTpl from '../../../components/faq-item/faq-item.twig';
import Observer from '../observer';
import Utils from '../utils';

const commonObserver = new Observer();

/**
 * Загружаем доп. вопросы и ответы по клику на кнопку
 * @param {Object} options - объект настроек
 * @param {Array} options.element - html-элменет, содержащий вопросы и ответы
 * @param {string} options.tpl - путь к twig-шаблону
 * @param {Function} options.handler - дополнительный обработчик
 */
class FaqItemsHandler {
    constructor(options) {
        this.activeClass = 'is-active';
        this.hiddenClass = 'is-hidden';

        this.formSel = '.js-faq-search-form';
        this.inputSel = '.js-faq-search-input';
        this.wrapperSel = '.js-faq';
        this.btnFilterSel = '.js-faq-filter-btn';
        this.itemsHolderSel = '.js-faq-holder';
        this.btnHolderSel = '.js-btn-holder';
        this.btnLoaderSel = '.js-load-questions';

        this.init(options);
    }

    /**
     * Инициализация компонента
     * @param {Object} options - объект настроек
    */
    init(options = {}) {
        const {element, tpl, handler} = options;

        this.wrapper = element || document.querySelector(this.wrapperSel);
        if (!this.wrapper) {
            return;
        }

        this.tpl = tpl || faqItemTpl;
        this.handler = handler;
        this.form = document.querySelector(this.formSel);
        this.input = document.querySelector(this.inputSel);
        this.btnFilterList = Array.from(this.wrapper.querySelectorAll(this.btnFilterSel));
        this.itemsHolder = this.wrapper.querySelector(this.itemsHolderSel);
        this.btnHolder = this.wrapper.querySelector(this.btnHolderSel);
        this.btnLoader = this.wrapper.querySelector(this.btnLoaderSel);

        this.bindEvents();
    }

    /**
     * Установить id текущей категории
     * @return {string} - id текущей категории
     */
    setCurrCategory() {
        const currentBtn = this.btnFilterList.find(({classList}) => classList.contains(this.activeClass));
        const {category} = currentBtn.dataset;

        return {
            currentBtn,
            category
        };
    }

    /**
     * Переключение состояния кнопок
     * @param {HTMLElement} btn - кнопка-переключатель
     */
    toggleBtn(btn) {
        this.btnFilterList.forEach((item) => {
            const {classList} = item;

            item === btn ? classList.add(this.activeClass) : classList.remove(this.activeClass);
        });
    }

    /**
     * Переключение состояния кнопок и поля ввода при выполнении обработки
     * @param {Array} arr - массив html-элементов
     * @param {boolean} isDisabled - значение disabled
     */
    handleDisabled(arr, isDisabled = true) {
        arr.forEach((item) => {
            item.disabled = isDisabled;
        });
    }

    /**
     * Рендеринг массива вопросов
     * @param {Array} items - массив объектов с данными вопросов и ответов
     * @param {HTMLElement} container - html-элемент контейнера с вопросами и ответами
     */
    renderData(items, container) {
        Utils.clearHtml(container);
        items.forEach((item) => {
            Utils.insetContent(container, this.tpl(item));
        });
    }

    /**
     * Обработка данных списка вопросов
     * @param {number} page - номер страницы пагинации
     */
    async fetchData(page = 1) {
        const {classList: holderClassList} = this.btnHolder;
        const {category} = this.setCurrCategory();

        this.handleDisabled([...this.btnFilterList, this.btnLoader, this.input]);

        try {
            const {data} = await axios.get('/crm/faq/', {
                params: {
                    s: this.input.value,
                    c: category,
                    page
                }
            });
            const {paginatedList} = data.data;
            const isTotalPages = paginatedList.page === paginatedList.totalPages;

            if (!paginatedList.list.length) {
                commonObserver.publish('showAlert', 'По вашему запросу ничего не найдено');
            }

            this.renderData(paginatedList.list, this.itemsHolder);
            this.handler(this.itemsHolder);

            this.btnLoader.disabled = isTotalPages;
            isTotalPages ? holderClassList.add(this.hiddenClass) : holderClassList.remove(this.hiddenClass);
        } catch (error) {
            commonObserver.publish('showAlert', 'Произошла ошибка, попробуйте еще раз');
            console.error('Ошибка при получении вопросов и ответов:', error);
        } finally {
            this.handleDisabled([...this.btnFilterList, this.input], false);
        }
    }

    /**
     * Передача данных для вызова обработчика
     * @param {Event} event - событие
     */
    handleFilterBtn(event) {
        event.preventDefault();

        const {target} = event;

        this.toggleBtn(target);
        this.fetchData();
    }

    /**
     * Передача данных для загрузчика
     * @param {Event} event - событие
     */
    handleBtn(event) {
        event.preventDefault();

        const {currentTarget} = event;
        const page = parseInt(currentTarget.dataset.page, 10) + 1;

        currentTarget.dataset.page = page;
        this.fetchData(page);
    }

    /**
     * Отправка данных формы
     * @param {Event} event - событие
     */
    submitForm(event) {
        event.preventDefault();

        this.fetchData();
    }

    /**
     * Отменить отправку формы по нажатию на Enter
     * @param {Event} event - событие
     */
    disableKeyPress(event) {
        if (event.keyCode === 13) {
            event.preventDefault();
        }
    }

    /**
     * Отобразить перечень элементов при загрузке страницы
     */
    loadItems() {
        const eventClick = new Event('click');
        const {currentBtn} = this.setCurrCategory();

        currentBtn.dispatchEvent(eventClick);
    }

    /**
     * Добавление слушателей событий
    */
    bindEvents() {
        this.btnFilterList.forEach((btn) => {
            btn.addEventListener('click', this.handleFilterBtn.bind(this));
        });

        this.input.addEventListener('input', Utils.debounce(() => {
            this.fetchData();
        }, 1000));

        this.form.addEventListener('submit', this.submitForm.bind(this));
        this.form.addEventListener('keypress', this.disableKeyPress.bind(this));
        this.btnLoader.addEventListener('click', this.handleBtn.bind(this));
        document.addEventListener('DOMContentLoaded', this.loadItems.bind(this));
    }
}

export default FaqItemsHandler;
