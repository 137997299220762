import Utils from "../utils";

/**
 Функция скролла к якорю
 */
const scrollToAnchor = () => {
    const anchorItems = Array.from(document.querySelectorAll(".js-anchor"));
    anchorItems.forEach((item) => {
        item.addEventListener("click", (e) => {
            e.preventDefault();
            const target = item.dataset.href.slice(item.dataset.href.indexOf("#"));
            Utils.scrollIt(document.querySelector(target), 500, "easeOutQuad");
        });
    });
};

export default scrollToAnchor;
