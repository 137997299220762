<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <g clip-path="url(#clip0_1570_106251)">
      <path d="M-3.05957 11.2716C-3.05957 4.48761 -3.05957 1.09564 -0.952056 -1.01187C1.15546 -3.11938 4.54743 -3.11938 11.3314 -3.11938H12.5307C19.3147 -3.11938 22.7066 -3.11938 24.8142 -1.01187C26.9217 1.09564 26.9217 4.48761 26.9217 11.2716V12.4709C26.9217 19.2549 26.9217 22.6468 24.8142 24.7544C22.7066 26.8619 19.3147 26.8619 12.5307 26.8619H11.3314C4.54743 26.8619 1.15546 26.8619 -0.952056 24.7544C-3.05957 22.6468 -3.05957 19.2549 -3.05957 12.4709V11.2716Z" fill="none"/>
      <path d="M12.8931 18.4797C6.0599 18.4797 2.1624 13.7951 2 6H5.42286C5.53529 11.7214 8.05865 14.1449 10.0574 14.6446V6H13.2805V10.9344C15.2543 10.722 17.3277 8.47345 18.0273 6H21.2503C20.7132 9.0481 18.4646 11.2967 16.8656 12.2211C18.4646 12.9706 21.0256 14.9319 22 18.4797H18.4521C17.6901 16.1062 15.7914 14.2698 13.2805 14.02V18.4797H12.8931Z" fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0_1570_106251">
        <rect width="24" height="24" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'icon-vk'
};
</script>
