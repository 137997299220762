import { fetchNews, displayNews } from './news.js';
import Utils from "common/scripts/utils";

const newsContainer = document.querySelector('.js-news-grid');

/**
 * @description Инициализация обработчиков событий для фильтров новостей
 */
function initNewsFilters() {
    // Получаем все кнопки фильтров, опции сортировки и элементы формы поиска
    const filterButtonsContainer = document.querySelector('.j-news-filter')
    const filterButtons = filterButtonsContainer.querySelectorAll('.filter-btn');
    const sortOptions = document.querySelectorAll('.js-select-item');
    const searchForm = document.querySelector('.j-news-form');
    const searchInput = searchForm.querySelector('.j-news-form-input');
    const loadMoreButton = document.querySelector('.js-btn-loadmore');

    loadMoreButton.addEventListener('click', async () => {
        // Получаем текущую страницу и увеличиваем ее на 1
        const page = parseInt(loadMoreButton.dataset.page, 10) + 1;

        console.log(page, 'page')

        const filter = document.querySelector('.filter-btn.is-active')?.dataset.tab || 'all';
        const sort = document.querySelector('.js-select-item.is-active')?.dataset.value || 'date';
        const query = searchInput.value;


        loadMoreButton.dataset.page = page;
        const deleteContent = false; // не очищаем контейнер

        await eventHandler({filter, sort, query, page}, fetchNews, filterButtons, sortOptions, searchInput, false, deleteContent);
    });

    // Блокируем enter, так как он чистит поле, а поиск происходит после ввода автоматически
    searchForm.addEventListener('keypress', function(e) {
        if (e.keyCode === 13) {
            e.preventDefault();
        }
    });

    // Инициализация обработчиков событий для фильтров новостей
    filterButtons.forEach(button => {
        button.addEventListener('click', (event) => {
            filterButtons.forEach((item) => {
                item.classList.remove('is-active')
            })
            event.target.classList.add('is-active')
            eventHandler(event, fetchNews, filterButtons, sortOptions, searchInput);
        });
    });

    sortOptions.forEach(option => {
        option.addEventListener('click', (event) => {
            eventHandler(event, fetchNews, filterButtons, sortOptions, searchInput);
        });
    });

    // Создаем "задебоунсенную" версию функции eventHandler для события ввода
    const debouncedInputHandler = Utils.debounce(eventHandler, 500);

    searchInput.addEventListener('input', (event) => {
        event.preventDefault();
        debouncedInputHandler(event, fetchNews, filterButtons, sortOptions, searchInput, false);
    });
}

/**
 * @description Обработчик событий для фильтров новостей
 * @param {Event} event - объект события
 * @param {Function} fetchFunction - функция для получения новостей
 * @param {NodeListOf<HTMLElement>} filterButtons - кнопки фильтров
 * @param {NodeListOf<HTMLElement>} sortOptions - опции сортировки
 * @param {HTMLElement} searchInput - поле ввода для поиска
 * @param {boolean} disableInput - флаг, указывающий, нужно ли отключить поле ввода
 * @param deleteContent {boolean} - нужно ли очищать контейнер (при подгрузке новой страницы - нет)
 */
async function eventHandler(event, fetchFunction, filterButtons, sortOptions, searchInput, disableInput = true, deleteContent = true) {
    const filter = document.querySelector('.filter-btn.is-active')?.dataset.tab || 'all';
    const sort = document.querySelector('.js-select-item.is-active')?.dataset.value || 'date';
    const query = searchInput.value;
    const loadMoreButton = document.querySelector('.js-btn-loadmore');


    // Блокируем все кнопки и поле ввода
    filterButtons.forEach(button => button.disabled = true);
    sortOptions.forEach(option => option.disabled = true);
    loadMoreButton.disabled = true;
    if (disableInput) {
        searchInput.disabled = true;
    }

    // Вызываем функцию получения новостей
    const news = await fetchFunction(filter, sort, query, event.page);

    displayNews(news.paginatedList.list, newsContainer, deleteContent);

    if (news.paginatedList.page === news.paginatedList.totalPages) {
        loadMoreButton.style.display = 'none';
    } else {
        loadMoreButton.style.display = 'block';
    }

    // Разблокируем все кнопки и поле ввода
    filterButtons.forEach(button => button.disabled = false);
    sortOptions.forEach(option => option.disabled = false);
    loadMoreButton.disabled = false;

    if (disableInput) {
        searchInput.disabled = false;
    }
}

export { initNewsFilters };
