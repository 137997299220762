import Utils from './../utils.js';
import Observer from "common/scripts/observer";
import favoriteItem from '../../../components/mini-basket/mini-basket-item-favorite.twig'
import basketItem from '../../../components/mini-basket/mini-basket-item-basket.twig'

const commonObserver = new Observer();

// Селекторы DOM-элементов
const container = document.querySelector('.j-mini-basket-content-favorite');
const basketContainer = document.querySelector('.j-mini-basket-content-basket');
const basketTotalText = document.querySelector('.j-mini-basket-total-text');
const basketTotalPrice = document.querySelector('.j-mini-basket-total-price');

/**
 * Обработка наведения курсора на иконку и корзину.
 *
 * @param {string} iconSelector - Селектор иконки для наведения курсора.
 * @param {string} basketType - Тип корзины ("favorite" или "basket").
 */
function handleMiniBasketHover(iconSelector, basketType) {
    // Элементы DOM
    const icon = document.querySelector(iconSelector);
    const basket = document.querySelector(`.j-mini-basket[data-basket="${basketType}"]`);

    // Создаем debounced функцию, которая удаляет класс 'is-active'
    const debouncedRemoveActiveClass = Utils.debounce(() => {
        if (!icon.matches(':hover') && !basket.matches(':hover')) { // Удаляем класс 'is-active' только если курсор больше не находится на иконке или на корзине
            basket.classList.remove('is-active');
        }
    }, 300);

    if (icon && basket) {
        // События наведения и убирания курсора для иконки и корзины
        const handleHover = (el) => {
            el.addEventListener('mouseover', function() {
                if (basket.classList.contains('not-empty')) {
                    basket.classList.add('is-active');
                }
            });

            el.addEventListener('mouseout', function() {
                // Используем debounced функцию для удаления класса 'is-active'
                debouncedRemoveActiveClass();
            });
        }

        handleHover(icon);
        handleHover(basket);
    }
}

/**
 * Вставка содержимого избранного
 *
 * @param {object} data - Данные для вставки.
 */
function insertFavoriteContent(data) {
    // Очищаем контейнер
    Utils.clearHtml(container);

    if (data?.items) {
        data?.items.forEach((item) => {
            Utils.insetContent(container, favoriteItem(item));
        });
    }
}


/**
 * Вставка содержимого корзины
 *
 * @param {object} data - Данные для вставки.
 */
function insertBasketContent(data) {
    // Вставляем текст и цену
    const word = Utils.pluralWord(data.total.total.count, ['товар', 'товара', 'товаров'])
    basketTotalText.innerText = `Всего ${data.total.total.count} ${word}`
    const sum = data.total.total.sum.toLocaleString('ru-RU')
    basketTotalPrice.innerText = `Итого: ${sum} ₽`

    // Вставляем содержимое корзины
    productContent(data)
}

/**
 * Вставка содержимого продуктов в корзину.
 *
 * @param {object} data - Данные продуктов.
 */
function productContent(data) {
    // Очищаем контейнер
    Utils.clearHtml(basketContainer);

    // Вставляем новые данные
    if (data.products.length) {
        data.products.forEach((item) => {
            Utils.insetContent(basketContainer, basketItem(item));
        });
    }
}

/**
 * Устанавливаем начальное число товаров в шапку
 *
 * @param {Object} data - данные о корзине.
 */
function insertBasketCount(data) {
    const cartElementCounter = document.querySelectorAll('.j-cart-count');
    const { products } = data;
    const productsCount = products.reduce((acc, product) => acc + product.quantity, 0);

    cartElementCounter.forEach((el) => {
        el.textContent = productsCount.toString();
        if (productsCount > 0) {
            if (!el.classList.contains('is-active')) {
                el.classList.add('is-active');
            }
        } else {
            el.classList.remove('is-active');
        }
    });
}

// Подписки на обновления корзины и избранного
commonObserver.subscribe('updateCart', (data, del) => {
    if (del) return;
    Utils.clearHtml(basketContainer);
    data.products.forEach((item) => {
        Utils.insetContent(basketContainer, basketItem(item));
    });
    insertBasketContent(data);
});

commonObserver.subscribe('updateFavorite', (data, del) => {
    if (del) return;
    Utils.clearHtml(container);
    if (data?.items) {
        data?.items.forEach((item) => {
            Utils.insetContent(container, favoriteItem(item));
        });
    }
});

/**
 * Инициализация мини-корзины.
 */
export async function initMiniBasket() {
    handleMiniBasketHover('.j-header-favorite-icon', 'favorite');
    handleMiniBasketHover('.j-header-cart-icon', 'basket');

    /*
        данные мини-корзины с товарами из window переменной
        для первой загрузки страницы
    */
    const { basketData: rawBasketData, favoriteData: rawFavoriteData } = window;

    if (rawBasketData) {
        try {
            const basketData = JSON.parse(rawBasketData);
            insertBasketContent(basketData);
            insertBasketCount(basketData);
        } catch (error) {
            console.error('Ошибка при преобразовании данных корзины:', error);
        }
    }

    if (rawFavoriteData) {
        try {
            const basketData = JSON.parse(rawFavoriteData);
            insertFavoriteContent(basketData);
        } catch (error) {
            console.error('Ошибка при преобразовании данных корзины:', error);
        }
    }
}
