/**
 * Вкладки
 * @param {Object} options - объект настроек
 * @param {string} options.element - селектор элемента-обертки
 * @param {string} [options.tabsItemClass] - класс элементов вкладок
 * @param {string} [options.tabsContentItemClass] - класс элементов содержимого вкладок
 */
class Tabs {
    constructor(options) {
        this.activeClass = 'is-active';
        this.hideClass = 'is-hide';
        this.disabledClass = 'is-disabled';

        this.init(options);
    }

    /**
     * Инициализация компонента вкладок
     * @param {Object} options - объект настроек
     */
    init(options) {
        const { element, tabsItemClass, tabsContentItemClass } = options;

        if (!element) {
            console.error('Необходим целевой элемент для инициализации табов');
            return;
        }

        this.tabsParent = element;
        this.tabs = Array.from(this.tabsParent.querySelectorAll(tabsItemClass || '.b-tabs__item'));
        this.contentsParent = this.tabsParent.querySelector(`.${this.tabsParent.dataset.content}`);
        this.contents = Array.from(this.contentsParent.querySelectorAll(tabsContentItemClass || '.b-tabs-content__item'));

        this.bindEvents();
    }

    /**
     * Добавление слушателей событий для вкладок
     */
    bindEvents() {
        this.tabs.forEach(tab => {
            tab.addEventListener('click', this.toggleTab.bind(this));
        });
    }

    /**
     * Переключение вкладок
     * @param {Event} event - событие
     */
    toggleTab(event) {
        event.preventDefault();
        const tab = event.currentTarget;

        if (tab.classList.contains(this.disabledClass)) {
            return;
        }

        this.setData(tab);
        this.changeState();
    }

    /**
     * Получение значений атрибутов data для активной вкладки
     * @param {HTMLElement} activeTab - элемент активной вкладки
     */
    setData(activeTab) {
        this.dataTab = {
            tab: activeTab,
            id: activeTab.dataset.tab,
            url: activeTab.getAttribute('href'),
            ajax: activeTab.dataset.ajax
        };
    }

    /**
     * Проверка наличия класса is-disabled у активной вкладки
     * @returns {boolean} true, если вкладка неактивна; false, если активна
     */
    isDisabledTab() {
        return this.dataTab.tab.classList.contains(this.disabledClass);
    }

    /**
     * Изменение состояния вкладок
     */
    changeState() {
        if (this.isDisabledTab()) {
            return;
        }

        this.tabs.forEach(element => {
            element.classList.toggle(this.activeClass, element.dataset.tab === this.dataTab.id);
        });

        this.contents.forEach(element => {
            element.classList.toggle(this.activeClass, element.dataset.tabContent === this.dataTab.id);
        });
    }
}
export default Tabs;
