const ACCORDION_SELECTORS = {
  root: ".js-accordion",
  toggler: ".js-accordion-toggler",
  contentWrapper: ".js-accordion-content",
  details: ".js-accordion-details",
};

const ACCORDION_STATE = {
  active: "is-active",
  show: "is-show",
};

/**
 * Функция инициализации аккордионов
 */
const initAccordion = (root = document) => {
  const accordions = Array.from(root.querySelectorAll(ACCORDION_SELECTORS.root));

  if (!accordions.length) return;

  accordions.forEach((accordion) => {
    const opener = accordion.querySelector(ACCORDION_SELECTORS.toggler);
    const content = accordion.querySelector(ACCORDION_SELECTORS.contentWrapper);

    const toggleAccordion = () => {
      opener.classList.toggle(ACCORDION_STATE.active);
      content.classList.toggle(ACCORDION_STATE.show);
      const details = content.querySelector(ACCORDION_SELECTORS.details);
      content.style.height = content.classList.contains(ACCORDION_STATE.show)
          ? `${details.offsetHeight}px`
          : "0";
    };

    // Если аккордион должен быть открыт изначально
    if (opener.classList.contains(ACCORDION_STATE.active)) {
      toggleAccordion();
    }

    //opener.removeEventListener('click', toggleAccordion); //это не работает  8-[ ...
    if (opener.classList.contains('event-isset')){ //извиняйте но проверить наличие события клика по другому не удаётся, разбираться некогда.
      return;
    }
    opener.classList.add('event-isset');
    opener.addEventListener("click", toggleAccordion);
  });
};

window.initAccordion = initAccordion;

export default initAccordion;
