/**
 * Зависимости
 */
import Utils from "common/scripts/utils";
import lozad from 'lozad';

import {
  initMobileHeader,
  initMobileMenu,
  initInfoBlock,
  initResetBtns,
  initSearch, initCustomTabs
} from './common';
import initCustomSelect from './modules/customSelect';
import initAccordion from './modules/accordion';
import scrollToAnchor from './modules/scrollToAnchor';
import sendForm from './modules/sendForm';
import initUploadFile from './modules/uploadFile';
import {initGlobalSearch} from "common/scripts/modules/headerSearch";
import {initFavorites} from "common/scripts/modules/add-to-favorite";
import {initNewsFilters} from "common/scripts/news/filter";
import {initMiniBasket} from "common/scripts/modules/mini-basket";
import {copyOrder} from "common/scripts/modules/copy-order-handler";

import AlertHandler from '../../components/alert/alert';
import {
  initNewsDetailSlider,
  initHeroSlider,
  initActionsSlider,
  initPlainSlider,
  initBestDealsSlider,
  initTechSlider,
  initProdSlider,
  initControlSlider,
  initFilterCarousel } from "./modules/slider";
import {
  initProductCards,
  initDeleteFromMiniBasket,
  initCatalogVolumeFilter,
  initCatalogDiscountFilter,
  initCatalogSortFilter } from '../../components/catalog/index';
import Tabs from './modules/tabs';
import citySelecter from './modules/citySelecter';
import cardPanel from './modules/cardPanel';
import initToggleMoreText from './modules/more-text';
import initVuePopup from './modules/vue-popup';
import FaqItemsHandler from './modules/faq-items-handler';
import initPopup from "./modules/popup";
import Observer from "common/scripts/observer";

/**
 * Тестовые данные для поиска по вакансиям
 */
import initVacanciesSearch from './vacancy/vacancy';

/**
 * лезилоад
 */
const observer = lozad('.lozad', {
  loaded: function(el) {
    el.classList.add('loaded');
  }
});

observer.observe();

// Вызов модулей
initCustomSelect();
initAccordion();
initUploadFile();
initToggleMoreText();
initVuePopup();
initPopup();
scrollToAnchor();
sendForm();
// Поиск по вакансиям
initVacanciesSearch();
// Инициализация избранного
initFavorites();
// мини-корзины
initMiniBasket();
// инициализация табов (со старой страницы)
initCustomTabs();
// повтор заказа по кнопке из модального окна
copyOrder();

// загрузка вопросов и ответов по клику на кнопку
const faqItemsHandler = new FaqItemsHandler({
  handler: (item) => {
    initAccordion(item);
  }
});

// Инициализация удаления товара из мини-корзины
const orderWrapper = document.querySelector('#order');

if (!orderWrapper) {
  initDeleteFromMiniBasket();
}

// Инициализация поведения на странице новостей
const newsSearchForm = document.querySelector('.j-news-form');

if (newsSearchForm) {
  initNewsFilters();
}

// Алерт
const alertHandler = new AlertHandler();

const commonObserver = new Observer();
/**
 * Теперь можно вызывать алерт из любой части кода:
 */
// commonObserver.publish('showAlert', 'Произошла ошибка, попробуйте еще раз');

// Для слежения за изменением устройства
window.addEventListener('resize', () => {
  setTimeout(() => {
    initToggleMoreText();
  }, 200);
});

// Поведение хедера на мобилке
const headerSelector = document.querySelector(".j-header");

if (headerSelector) {
  initMobileHeader(headerSelector);
}

// Раскрытие и поведение мобильного меню
const mobileMenuBtn = document.querySelector(".j-header-mobile-menu-btn");

if (mobileMenuBtn) {
  initMobileMenu(mobileMenuBtn);
}

// Инициализация карточек товара
const productCards = Array.from(document.querySelectorAll('.j-catalog-card-action-wrapper'));

if (productCards.length) {
  initProductCards(productCards);
}

// Инициализация каталога
const catalogContainer = document.querySelector('.js-catalog');

if (catalogContainer) {
  // Фильтры каталога
  initCatalogVolumeFilter();
  initCatalogDiscountFilter();
  initCatalogSortFilter();
}

// Активация слайдера на детальной странице новостей
const newsSlider = document.querySelector('.js-plain-slides');
if (newsSlider) {
  initNewsDetailSlider('.js-plain-slides');
}

/**
 * Слайдер на главной
 */
const heroSliderSelector = '.j-hero-content-slider';
const heroSlider = document.querySelector(heroSliderSelector);

/**
 * Проверяет, инициализирован ли слайдер
 * @param {HTMLElement} element - Элемент слайдера
 * @returns {boolean} - Возвращает true, если слайдер не инициализирован
 */
function isSwiperInitialized(element) {
  return element && !element.classList.contains('swiper-initialized');
}

/**
 * Инициализирует слайдер при корректном брейкпоинте
 */
function initHeroSliderOnValidBreakpoint() {
  if (!Utils.isBreakpoint(0, 1023) && isSwiperInitialized(heroSlider)) {
    initHeroSlider(heroSliderSelector);
  }
}

if (heroSlider) {
  // Инициализирует слайдер и добавляет обработчик события 'resize', если на странице есть элемент с селектором j-hero-content-slider
  initHeroSliderOnValidBreakpoint();
  window.addEventListener('resize', initHeroSliderOnValidBreakpoint);
}

/**
 * слайдер с акциями
 */
const promoSlider = document.querySelector('.js-main-promo-slides');
if(promoSlider) {
  initActionsSlider(promoSlider);
}

const promoProductSlides = Array.from(document.querySelectorAll('.js-promo-products'));
if(promoProductSlides.length) {
  promoProductSlides.forEach(item => initPlainSlider(item));
}

const mainActionsSlider = document.querySelector('.j-main-actions-slider');
if(mainActionsSlider) {
  initPlainSlider(
    mainActionsSlider,
    {
      navPrevSel: '.swiper-button-prev',
      navNextSel: '.swiper-button-next',
    }
  );
}

/**
 * cлайдер с лучшими товарами на главной
 */
const mainPageBestDealsSlider = document.querySelector('.j-best-deals-slider');

if (mainPageBestDealsSlider) {
  initBestDealsSlider(mainPageBestDealsSlider)
}

/**
 * cлайдер на стр. контроля качества
 */
const techSlider = document.querySelector('.j-tech-slider');

if (techSlider) {
  initTechSlider(techSlider);
}

/**
 * cлайдер на стр. о воде в разделе производства
 */
const prodSlider = document.querySelector('.j-prod-slider');

if (prodSlider) {
  initProdSlider(prodSlider);
}

/**
 * слайдер на стр. о компании
 */
const controlSlider = document.querySelector('.j-control-slider');

if (controlSlider) {
  initControlSlider(controlSlider);
}

/**
 * слайдер с акциями
 */
const filterCarousel = document.querySelector('.js-filter-carousel');
if(filterCarousel) {
  initFilterCarousel(filterCarousel);
}

/**
 * Табы на страницах (стандартные).
 * @type {Array}
 */
const tabsClassArr = Array.from(document.querySelectorAll('.js-tabs'));

if (tabsClassArr.length) {
  tabsClassArr.forEach((item)=>{
    const tabs = new Tabs({
      element: item,
      tabsItemClass: '.js-tab-link',
      tabsContentItemClass: '.js-tab-pane'
    });
  });
}

/**
 * Выбор геолокации
 * @type {HTMLElement}
 */
const citySelecterEl = document.querySelector('.j-city-select');

if (citySelecterEl) {
  const citySelecters = new citySelecter({
    element: citySelecterEl,
    cityOptionClass: '.j-city-option',
    cityPlsClass: '.j-city-placeholder',
    param: 'CURRENT_LOCATION'
  });
}

/**
 * Подробная информация карточек товаров в разделе производства
 * @type {Array}
 */
const prodCardSectionsArr = Array.from(document.querySelectorAll('.j-prod-card-section'));

if (prodCardSectionsArr.length) {
  prodCardSectionsArr.forEach((item)=>{
    const prodCardPanel = new cardPanel({
      section: item,
      prodCardItemSel: '.j-prod-card',
      prodCardTogglerSel: '.j-prod-card-toggler'
    });
  })
}

// получаем элемент на главной с анимаией
const mainInfo = document.querySelector('.j-main-info');

if (mainInfo) {
  initInfoBlock(mainInfo);
}

// кнопка вызова поиска
const searcBtnArr = Array.from(document.querySelectorAll('.j-header-search'))

if (searcBtnArr.length) {
  initSearch(searcBtnArr);
}

// кнопка сброса в инпуте
const resetBtnArr = Array.from(document.querySelectorAll('.j-reset-input'));

if (resetBtnArr.length) {
  initResetBtns(resetBtnArr);
}

// форма глобального поиска
const searchForm = document.querySelector('.j-global-search');

if (searchForm) {
  initGlobalSearch(searchForm);
}

