<template>
  <div
    v-if="isShow"
    class="v-auth-modal"
  >
    <div 
      class="v-auth-modal__overlay"
      @click="close"
    ></div>
    <div
      class="v-auth-modal__container"
    >
      <div class="v-auth-modal-close">
        <icon-close 
          class="v-auth-modal-close__icon"
          @click="close"
        />
      </div>

      <v-auth-form />

      <div class="v-auth-modal-bg-img">
        <img class="lozad" src="/www/img/trees-bg.svg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import IconClose from '../../assets/icons/close.vue';
import VAuthForm from 'components/auth-form/auth-form.vue';

export default {
  name: 'v-auth-modal',
  components: {
    IconClose,
    VAuthForm,
  },
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      isShow: true,
    }; 
  },
  watch: {
    isShow: {
      handler(newVal) {
        if (newVal) {
          setTimeout(() => {
            this.disableDocumentScroll();
          }, 0);
        } else {
          this.enableDocumentScroll();
        }
      },
      immediate: true
    }
  },
  beforeDestroy() {
    this.enableDocumentScroll();
  }, 
  methods: {
    close() {
      this.$emit('close');
      this.$el.parentNode.remove();
      this.enableDocumentScroll();
    },
    disableDocumentScroll() {
      setTimeout(() => {
        document.body.classList.add('body-scroll-hidden');
      }, 100);
    },
    enableDocumentScroll() {
      setTimeout(() => {
        document.body.classList.remove('body-scroll-hidden');
      }, 100);
    }
  }
}
</script>
