<template>
  <div class="v-form-separator">
    <span>{{ label }}</span>
  </div>
</template>

<script>
export default {
  name: 'v-form-separator',
  props: {
    label: {
      type: String,
      default: 'или'
    }
  }
}
</script>
